import moment from 'moment'
import * as Store from 'types/store'

export const EVENT_MIN_WIDTH = 120,
  EVENT_HEIGHT = 62,
  DAY = 24 * 60

export const getDailyDuration = (start: moment.Moment, end: moment.Moment) => {
  return end.diff(start, 'minutes') % DAY
}

export const getCellWidth = (sortedEvents: Store.PlanningEvent[]): { width: number; shortest: number } => {
  const [minutes, shortest] = sortedEvents.reduce(
    ([total, shortest], event) => {
      const duration = getDailyDuration(event.startDate, event.endDate)

      const shortestCurrent = duration < shortest ? duration : shortest
      return [total + duration, shortestCurrent]
    },
    [0, Infinity],
  )
  if (minutes == 0) {
    return { width: EVENT_MIN_WIDTH, shortest }
  }
  return { width: (minutes / shortest) * EVENT_MIN_WIDTH, shortest }
}

export const getColspanValue = (
  startDay: moment.Moment,
  startDate: moment.Moment,
  endDate: moment.Moment,
  date: moment.Moment,
  extraValue = 0,
) => {
  if (moment(startDate).isSame(startDay, 'week') && startDate.isSame(date, 'day')) {
    const colSpans = endDate.diff(startDate, 'days') + extraValue
    return colSpans > 1 ? { colSpan: colSpans, className: 'extendingRow' } : {}
  }
  if (!moment(startDate).isSame(startDay, 'week') && startDay.isSame(date, 'day')) {
    const colSpans = endDate.diff(startDay, 'days') + extraValue

    return colSpans > 1 ? { colSpan: colSpans, className: 'extendingRow' } : {}
  }
  return { colSpan: 0 }
}

export const getColSpan = (events: Store.PlanningEvent[], date: moment.Moment, startDay: moment.Moment) => {
  const todayEvents = events?.filter(({ startDate, endDate }) => {
    return startDate.isSameOrBefore(date, 'day') && endDate.isSameOrAfter(date, 'day')
  })

  if (todayEvents.length > 1) {
    return {}
  }

  const event = todayEvents?.[0]

  if (event) {
    const { startDate, endDate } = event

    const crossingEvents = events?.filter((event) => {
      return (
        event.startDate.isBetween(startDate, endDate, 'day', '[]') ||
        event.endDate.isBetween(startDate, endDate, 'day', '[]')
      )
    })

    if (crossingEvents.length > 1) {
      const nextEvent = crossingEvents?.[1]

      if (nextEvent.startDate.isBefore(date)) {
        return {}
      }

      return getColspanValue(startDay, startDate, nextEvent.startDate, date)
    }

    return getColspanValue(startDay, startDate, endDate, date, 1)
  }

  return {}
}

export const sortEvents = (events: Store.PlanningEvent[]) => {
  return events.sort((a, b) => a.startDate.unix() - b.startDate.unix())
}
