import React from 'react'
import { Card, Descriptions, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import PageHeader from 'components/PageHeader'
import routes from 'config/routes'
import { generatePath } from 'react-router-dom'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'

import { User } from 'types/app/admin'

interface Props {
  user: User
  onDelete?: (userId: number) => void
  dataLoading?: boolean
}

const UserDetails: React.FC<Props> = ({ user, onDelete }) => {
  const { t } = useTranslation()

  return (
    <>
      <PageHeader title={`${t('menu.user')} #${user.id}`} backRoute={routes.users}>
        <Button href={generatePath(routes.userDetails.url, { id: user.id.toString() }) + '?editmode=details'}>
          <EditOutlined />
          {t('btn.edit')}
        </Button>
        <Button type="text" onClick={() => onDelete(user.id)}>
          <DeleteOutlined />
          {t('btn.deleteUser')}
        </Button>
      </PageHeader>

      <Card>
        <Descriptions column={1} colon={false} title={t('general.basicInfo')} className="descriptions-half">
          <Descriptions.Item label={t('user.name')}>{user?.name}</Descriptions.Item>
          <Descriptions.Item label={t('user.surname')}>{user?.surname}</Descriptions.Item>
          <Descriptions.Item label={t('user.email')}>{user?.email}</Descriptions.Item>
          <Descriptions.Item label={t('user.role')}>{user?.roleId}</Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  )
}

export default UserDetails
