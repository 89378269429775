import React, { useState } from 'react'
import routes from 'config/routes'
import { Workers } from 'types/store'
import { Worker } from 'types/app/admin'
import { tableSorter } from 'core/helpers'
import { useTranslation } from 'react-i18next'
import { generatePath, Link, useSearchParams } from 'react-router-dom'
import { TABLE_PAGINATION_DEFAULT_PAGE_SIZE, TABLE_PAGINATION_DROPDOWN_VALUES } from 'core'

// import TopPage from 'components/TopPage'
import FilterPanel from 'components/FilterPanel'

import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from 'react-icons/ai'
import {
  Button,
  Card,
  Modal,
  notification,
  Tooltip,
  Typography,
  Tag,
  Space,
  Switch,
  Input,
  Form,
  Table,
} from 'antd'
import style from './styles.module.scss'
import PageHeader from 'components/PageHeader'

interface Props {
  workers?: Workers
  dataLoading: boolean
  handleEdit: (worker: Worker) => void
  handleDelete: (id: number) => void
  setPage: (page: string) => void
  setStatus: (status: string) => void
  onFilter: (filter: string) => void
  filter: string
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean
  dataIndex: string
  title: any
  inputType: 'switch' | 'text'
  record: any
  index: number
  children: React.ReactNode
}

const WorkersTable: React.FC<Props> = ({
  workers,
  dataLoading,
  handleEdit,
  handleDelete,
  setPage,
  setStatus,
  onFilter,
  filter,
}) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  // const crumbs = () => [{ route: routes.home }, { route: routes.workers }]

  const [form] = Form.useForm()
  const [dataSource, setDataSource] = useState(workers.result)
  const [editingKey, setEditingKey] = useState('')
  const [switchValue, setSwitchValue] = useState(null)

  const isEditing = (record) => record.id === editingKey

  const onEdit = (record) => {
    form.setFieldsValue({ ...record })
    setSwitchValue(record.disabled)
    setEditingKey(record.id)
  }

  const onCancel = () => {
    setEditingKey('')
    setSwitchValue(null)
  }

  const onSave = async (workerId: number) => {
    try {
      const row = (await form.validateFields()) as any
      setEditingKey('')
      setSwitchValue(null)

      await handleEdit({
        ...row,
        id: workerId,
        disabled: switchValue,
      })
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <>
            {inputType === 'switch' ? (
              <Switch
                onChange={() => setSwitchValue(!switchValue)}
                checked={!switchValue}
                checkedChildren={t('general.enabled')}
                unCheckedChildren={t('general.disabled')}
              />
            ) : (
              <Form.Item
                name={dataIndex}
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: t('input.required', { input: title }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
          </>
        ) : (
          children
        )}
      </td>
    )
  }

  React.useEffect(() => {
    setDataSource(workers.result)
  }, [workers, filter])

  const onDelete = (WorkerId: number) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      onOk() {
        try {
          handleDelete(WorkerId)
          notification.success({ message: t('messagesCrud.userDeleted') })
        } catch (error) {
          notification.error({ message: t('messagesCrud.deleteError') })
        }
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  const columns = [
    {
      key: 'id',
      title: t('general.id'),
      dataIndex: 'id',
      width: 100,
      fixed: true,
    },
    {
      key: 'name',
      title: t('general.name'),
      dataIndex: 'name',
      editable: true,
      sorter: (a, b) => tableSorter(a.name, b.name),
    },
    {
      key: 'surname',
      title: t('general.surname'),
      dataIndex: 'surname',
      editable: true,
      sorter: (a, b) => tableSorter(a.surname, b.surname),
    },
    {
      key: 'disabled',
      title: t('general.status'),
      dataIndex: 'disabled',
      filters: [
        {
          text: t('general.enabled'),
          value: 'active',
        },
        {
          text: t('general.disabled'),
          value: 'disable',
        },
      ],
      onFilter: (value: string, record) => {
        if (value === 'active') {
          setStatus('active')
        }
        if (value === 'disable') {
          setStatus('disable')
        }
        return record
      },
      filterSearch: false,
      filterMultiple: false,
      editable: true,
      sorter: (a, b) => tableSorter(a.disabled, b.disabled),
      // eslint-disable-next-line react/display-name
      render: (disabled) =>
        disabled ? (
          <Tag color="error">{t('general.disabled')}</Tag>
        ) : (
          <Tag color="success">{t('general.enabled')}</Tag>
        ),
    },
    {
      key: 'actions',
      title: t('general.actions'),
      dataIndex: 'id',
      className: 'cell-actions',
      width: 150,
      // eslint-disable-next-line react/display-name
      render: (id, record) => {
        const editable = isEditing(record)
        return editable ? (
          <Space>
            <Button type="default" size="small" onClick={onCancel}>
              {t('btn.cancel')}
            </Button>
            <Button type="primary" size="small" onClick={() => onSave(record.id)}>
              {t('btn.save')}
            </Button>
          </Space>
        ) : (
          <Space size={0}>
            <Tooltip key={`action-edit-${id}`} title={t('tooltip.edit')}>
              <Button type="link" size="small" onClick={() => onEdit(record)} disabled={editingKey !== ''}>
                <AiOutlineEdit />
              </Button>
            </Tooltip>
            <Tooltip key={`action-delete-${id}`} title={t('tooltip.delete')}>
              <Button type="link" size="small" onClick={() => onDelete(id)} disabled={editingKey !== ''}>
                <AiOutlineDelete />
              </Button>
            </Tooltip>
          </Space>
        )
      },
    },
  ]

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        key: col.key,
        record,
        inputType: col.dataIndex === 'disabled' ? 'switch' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  const getTablePagination = () => {
    if (workers.totalItems >= TABLE_PAGINATION_DEFAULT_PAGE_SIZE) {
      return {
        current: parseInt(searchParams.get('page')) || 1,
        total: workers.totalItems,
        defaultPageSize: TABLE_PAGINATION_DEFAULT_PAGE_SIZE,
        showSizeChanger: false,
        pageSizeOptions: TABLE_PAGINATION_DROPDOWN_VALUES,

        onChange: (page: number) => {
          onCancel()
          setPage(page.toString())
          setStatus(status.toString())
        },
      }
    }
    return false
  }

  return (
    <section className={style.workers}>
      <PageHeader title={t('menu.workers')}>
        <Button type="primary" className="my-10">
          <Link key="action-create" to={generatePath(routes.workerDetails.url, { id: 'new' })}>
            <AiOutlinePlus /> {t('btn.new')}
          </Link>
        </Button>
      </PageHeader>
      <Card bordered>
        <FilterPanel filter={filter} onFilter={onFilter} />
        <Form form={form} component={false}>
          <Table
            rowKey={(record) => record.id}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            loading={dataLoading}
            dataSource={dataSource}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={getTablePagination()}
          />
        </Form>
      </Card>
    </section>
  )
}

export default WorkersTable
