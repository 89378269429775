import React from 'react'
import DashboardContainer from 'containers/dashboard/DashboardContainer'
import { getUserClaims } from 'core/localStore'
import { UserRoles } from 'types/enums'
import WorkReport from 'containers/WorkReport'

const HomePage: React.FC = () => {
  const getUserRole = () => {
    try {
      const userClaims = getUserClaims()
      return userClaims.userRole
    } catch {
      return null
    }
  }

  const role = getUserRole()

  if (!role) {
    return null
  }

  if (role === UserRoles.Worker) {
    return <WorkReport />
  }

  return <DashboardContainer />
}

export default HomePage
