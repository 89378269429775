import React from 'react'
import { useTranslation } from 'react-i18next'

import { Card, Divider, Typography } from 'antd'
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'

import colors from 'config/colors'
import { useLoadPeriodDashboardData } from 'services/query/dashboard'

const WeekProgress: React.FC = () => {
  const { t } = useTranslation()
  const { data } = useLoadPeriodDashboardData()

  return (
    <Card className="widget-item widget-item--week">
      <Typography.Title level={4}>{t('dashboard.weekProgress')}</Typography.Title>
      <Divider className="mt-0" />
      <div className="legend">
        <Typography.Title className="legend-item legend-item--planned" level={5}>
          {t('dashboard.piecesPlanned')}
        </Typography.Title>
        <Typography.Title className="legend-item legend-item--completed" level={5}>
          {t('dashboard.piecesCompleted')}
        </Typography.Title>
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 80,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="date" label={{ position: 'insideBottom', offset: -15 }} />
          <YAxis label={{ angle: -90, position: 'insideLeft' }} />
          <Tooltip />

          <Bar
            name={t('dashboard.piecesCompleted')}
            dataKey="piecesCompleted"
            barSize={25}
            fill={colors.piecesCompletedWeek}
          />
          <Line
            type="monotone"
            name={t('dashboard.piecesPlanned')}
            dataKey="piecesPlanned"
            stroke={colors.piecesPlannedWeek}
            dot={false}
            activeDot={{ stroke: colors.piecesPlannedWeek, strokeWidth: 3, r: 7 }}
            strokeWidth={3}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Card>
  )
}

export default WeekProgress
