import * as Api from 'types/api'
import * as Store from 'types/store'
import Debug from 'debug'
import moment from 'moment'
import { getApplicationError } from 'core'
import { ErrorCode } from 'types/enums'
const debug = Debug('services:map:reporting')

export const mapWorklog = ({
  article,
  cardId,
  createdAt,
  date,
  id,
  machine,
  machineId,
  notes,
  numPieces,
  shift,
  updatedAt,
  worker,
  workerId,
}: Api.WorklogResponse): Store.Worklog => ({
  id,
  cardId,
  machineId,
  numPieces,
  article,
  createdAt: moment(createdAt),
  date: moment(date),
  machine,
  notes,
  shift,
  updatedAt: moment(updatedAt),
  worker,
  workerId,
})

export const mapReportList = ({
  result,
  currentPage,
  totalItems,
  totalPages,
}: Api.WorklogtListResponse): Store.Worklogs => {
  try {
    return {
      result: result.map(mapWorklog),
      currentPage,
      totalItems,
      totalPages,
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}
