import { config } from 'core'
import { AxiosError } from 'axios'
import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'
import * as Api from 'types/api'

const axios = getAxios()

export async function getMachinesReportsData(
  dateStart: string,
  dateEnd: string,
): Promise<Api.MachineReportsData[]> {
  try {
    const response = await axios.get<Api.MachineReportsData[]>(
      config.api.reportsMachinesData(dateStart, dateEnd),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getActivityTypesReportsData(
  dateStart: string,
  dateEnd: string,
): Promise<Api.ActivityTypesReportsData[]> {
  try {
    const response = await axios.get<Api.ActivityTypesReportsData[]>(
      config.api.reportsActivityTypesData(dateStart, dateEnd),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function generatePdfReportsData(dateStart: string, dateEnd: string, type: string) {
  try {
    const header = await getAuthenticationHeader()
    header.responseType = 'blob'
    return await axios.get<Blob>(config.api.generatePdfReportsData(dateStart, dateEnd, type), header)
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function generateCSVReportsData(
  dateStart: string,
  dateEnd: string,
  type: string,
  languageIso: string,
) {
  try {
    const header = await getAuthenticationHeader()
    header.responseType = 'blob'
    return await axios.get<Blob>(
      config.api.generateCSVReportsData(dateStart, dateEnd, type, languageIso),
      header,
    )
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getArticlesReportsData(
  dateStart: string,
  dateEnd: string,
): Promise<Api.ArticlesReportsData[]> {
  try {
    const response = await axios.get<Api.ArticlesReportsData[]>(
      config.api.reportsArticlesData(dateStart, dateEnd),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
