import React, { useState, useEffect } from 'react'
import { Worker } from 'types/app/admin'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import WorkersTable from 'components/workers'
import { useLoadWorkerList, useEditWorker, useDeleteWorker } from 'services/query/workers'

import { notification } from 'antd'

const WorkersTableContainer: React.FC = () => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [filter, setFilter] = useState(searchParams.get('filter') || '')
  const [page, setPage] = useState(searchParams.get('page') || '1')
  const [status, setStatus] = useState(searchParams.get('status') || '')

  const { data: workers, refetch, isFetched, isLoading } = useLoadWorkerList(filter, page, status)
  const dataLoading: boolean = isLoading || !isFetched

  useEffect(() => {
    const query = Object.entries({ filter, page, status }).reduce((object, [key, value]) => {
      if (value) {
        object[key] = value
      }
      return object
    }, {})

    setSearchParams(query)
  }, [filter, page, status])

  const deleteWorker = useDeleteWorker()
  const handleDelete = async (workerId: number) => {
    await deleteWorker(workerId)
    refetch()
  }

  const editWorker = useEditWorker()
  const handleEdit = async (workerModel: Worker) => {
    try {
      await editWorker(workerModel)
      refetch()
      notification.success({ message: t('messagesCrud.workerUpdated') })
    } catch (error) {
      console.error(error)
      notification.error({ message: `Worker edit error: ${error || ''}` })
    }
  }

  return (
    <WorkersTable
      workers={workers}
      dataLoading={dataLoading}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
      setPage={setPage}
      filter={filter}
      onFilter={setFilter}
      setStatus={setStatus}
    />
  )
}

export default WorkersTableContainer
