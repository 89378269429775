import * as K from './constants'
import { AnyAction } from 'redux'
import produce from 'immer'
import * as Store from 'types/store'

// The initial state of the Reducer
export const initialState: Store.AppState = {
  bootstrapLoading: true,
}

const reducer = (state = initialState, action: AnyAction): Store.AppState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case K.APP_BOOTSTRAP_LOADED:
        draft.bootstrapLoading = false
        break
    }
  })
}

export default reducer
