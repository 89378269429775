import React from 'react'
import { Card, Divider, Radio, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'

interface Props {
  data: Store.IotDevice[]
  selectedDevice: number
  setSelectedDevice: React.Dispatch<React.SetStateAction<number>>
}

const DevicesList: React.FC<Props> = ({ data, selectedDevice, setSelectedDevice }) => {
  const { t } = useTranslation()

  return (
    <Card className="devices-box">
      <div className="header-wrapper">
        <Typography.Title level={4}>{t('iot.devices')}</Typography.Title>
        <Divider className="mt-0" />
      </div>

      <div className="body-wrapper">
        <Radio.Group
          value={selectedDevice}
          buttonStyle="solid"
          onChange={(value) => setSelectedDevice(value.target.value)}
        >
          {data.map((device) => (
            <Radio.Button
              className={`status ${device.statusActive ? 'status--online' : 'status--offline'}`}
              key={device.id}
              value={device.id}
            >
              <Typography.Text
                ellipsis={{ tooltip: true }}
              >{`${device.supplier} ${device.model}`}</Typography.Text>
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
    </Card>
  )
}

export default DevicesList
