import * as Api from 'types/api'
import * as Store from 'types/store'
import jwt_decode from 'jwt-decode'
declare let window
const { localStorage } = window

const AUTH_USER = 'AUTH_USER'
const LANGUAGE = 'LANGUAGE'

export const clearUser = async (): Promise<void> => {
  localStorage.removeItem(AUTH_USER)
}

export const getTokens = async (): Promise<Store.Tokens> => {
  const json = localStorage.getItem(AUTH_USER)
  if (!json) {
    return null
  }

  return JSON.parse(json) as Store.Tokens
}

export const getAuthToken = async (): Promise<string> => {
  const user = await getTokens()
  return user?.token
}

export const getRefreshToken = async (): Promise<string> => {
  const user = await getTokens()
  return user?.refreshToken
}

export const saveUser = async (response: Api.LoginResponse): Promise<Store.Tokens> => {
  const { token, refreshToken } = response

  const tokens = {
    token,
    refreshToken,
  }

  //Save in storage
  localStorage.setItem(AUTH_USER, JSON.stringify(tokens))

  return tokens
}

export const getUserClaims = () => {
  const value = localStorage.getItem(AUTH_USER)
  const tokens = JSON.parse(value)
  const decoded = jwt_decode(tokens.token)

  return {
    userRole: decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
    userName: decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
    userId: decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'],
    instanceId: decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata'],
  }
}

// Language
export const setLanguage = (language: string) => {
  localStorage.setItem('LANGUAGE', language)
}

export const getLanguage = () => {
  const language = localStorage.getItem(LANGUAGE)
  return language
}
