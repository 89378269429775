import React, { useEffect, useState } from 'react'
import ConfirmEmailForm from 'components/ForgotPasswordForm'
import { notification } from 'antd'
import { t } from 'i18next'
import { useForgotPassword } from 'services/query/auth'

const ForgotPasswordFormContainer: React.FC = () => {
  const [isDone, setIsDone] = useState(false)
  const { forgotPassword, isLoading, error } = useForgotPassword()

  useEffect(() => {
    if (error) {
      notification.error({ message: t('auth.errorResetPassword') })
    }
  }, [error])

  const onConfirm = async (email: string) => {
    const isConfirmed = await forgotPassword(email)
    if (isConfirmed) {
      setIsDone(true)
    } else {
      notification.error({ message: t('auth.errorForgotPassword') })
    }
  }

  return <ConfirmEmailForm onConfirm={onConfirm} isDone={isDone} isLoading={isLoading} />
}

export default ForgotPasswordFormContainer
