import React from 'react'

import { useTranslation } from 'react-i18next'
import { config } from 'core'
import moment from 'moment'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd'
import { useLoadCardsPicklist } from 'services/query/picklist'
import { PlanningShifts } from 'types/enums'
import * as Api from 'types/api'
import { filterSearchSelect } from 'core/helpers'

const { Title } = Typography

interface Props {
  onFinish: (data: Api.WorklogForm) => void
  loading: boolean
}

const EnterData: React.FC<Props> = ({ onFinish, loading }) => {
  const { t } = useTranslation()
  const { data: productionCards } = useLoadCardsPicklist()

  return (
    <section className="step-container">
      <Title level={3}>{t('Inserisci i dati di lavorazione')}</Title>

      <div className="form-wrapper">
        <Card style={{ width: '50%' }} className="enter-data">
          <Spin spinning={loading}>
            <Form onFinish={onFinish} initialValues={{ date: moment() }}>
              <Row gutter={[50, 0]}>
                <Col span={24}>
                  <Form.Item
                    name="date"
                    label={t('general.date')}
                    rules={[
                      {
                        required: true,
                        message: t('form.dateIsRequired'),
                      },
                    ]}
                  >
                    <DatePicker
                      showTime
                      showNow
                      style={{ width: '100%' }}
                      format={config.getDateTimeFormat()}
                      disabledDate={(current) => current && moment(current).isBefore(moment())}
                    />
                  </Form.Item>
                  <Form.Item
                    name="cardId"
                    label={t('general.article')}
                    rules={[
                      {
                        required: true,
                        message: t('form.articleIsRequired'),
                      },
                    ]}
                  >
                    <Select showSearch filterOption={filterSearchSelect}>
                      {productionCards.map((card) => {
                        return (
                          <Select.Option key={card.id} value={card.id}>
                            {card.article}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item name="numPieces" label={t('general.numPieces')}>
                    <InputNumber />
                  </Form.Item>
                  <Form.Item
                    name="shift"
                    label={t('general.shift')}
                    rules={[
                      {
                        required: true,
                        message: t('form.shiftIsRequired'),
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio.Button value={PlanningShifts.ShiftOne}>{t('planning.shiftOne')}</Radio.Button>
                      <Radio.Button value={PlanningShifts.ShiftTwo}>{t('planning.shiftTwo')}</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item label={t('general.notes')} name="notes">
                    <Input.TextArea />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center">
                <Col>
                  <Button type="primary" htmlType="submit">
                    {t('general.send')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Card>
      </div>
    </section>
  )
}

export default EnterData
