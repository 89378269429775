import { Modal, notification, Spin, Typography } from 'antd'

import ShiftPlanningForm from 'components/ShiftPlanning/ShiftPlanningForm'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDeleteShiftPlanning, useEditShiftPlanning } from 'services/query/shiftPlanning'
import * as Api from 'types/api'
import { WorkerShifts } from 'types/enums'
import { ModalOptions } from '../ShiftPlanning'

interface Props {
  onClose: () => void
  modalOptions: ModalOptions
}

const ShiftPlanningEdit: React.FC<Props> = ({ modalOptions, onClose }) => {
  const { visible, shiftPlanning, workerId } = modalOptions
  const [editShiftPlanning, isLoading] = useEditShiftPlanning(shiftPlanning?.id)
  const [deleteShiftPlanning, isDeleting] = useDeleteShiftPlanning()
  const { t } = useTranslation()

  const onFinish = async (shift: WorkerShifts) => {
    try {
      const formData: Api.ShiftPlanningUpdateForm = {
        shift: shift,
      }
      await editShiftPlanning(formData)
      notification.success({ message: t('messagesCrud.eventUpdated') })
      onClose()
    } catch {
      notification.error({ message: t('messagesCrud.errorUpdated') })
    }
  }

  const onDelete = () => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      async onOk() {
        try {
          await deleteShiftPlanning(shiftPlanning?.id)
          notification.success({ message: t('messagesCrud.eventCreated') })
          onClose()
        } catch {
          notification.error({ message: t('messagesCrud.errorCreate') })
        }
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  return (
    <Modal
      visible={visible && !workerId}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      title={t('shiftPlanning.editTitle')}
    >
      <Spin spinning={isLoading || isDeleting}>
        <ShiftPlanningForm
          modalOptions={modalOptions}
          onFinish={onFinish}
          onDelete={onDelete}
          initialValues={{ shift: shiftPlanning?.shift }}
        />
      </Spin>
    </Modal>
  )
}

export default ShiftPlanningEdit
