import React, { useState } from 'react'
import WorkReport from 'components/WorkReport'
import ChooseYourself from 'components/WorkReport/steps/ChooseYourself'
import { WorkReportStep } from 'types/api/common'
import ChooseActivityType from 'components/WorkReport/steps/ChooseActivityType'
import ChooseMachine from 'components/WorkReport/steps/ChooseMachine'
import EnterData from 'components/WorkReport/steps/EnterData'
import * as Store from 'types/store'
import * as Api from 'types/api'
import { useCreateWorkLogByWorker } from 'services/query/worklog'
import { Modal, notification } from 'antd'
import { t } from 'i18next'
import { MachineActivityType, RequestStatus } from 'types/enums'
import SandblastingImage from 'assets/activity-types/sand-blasting.png'
import GrindingImage from 'assets/activity-types/grinding.png'
import PackagingImage from 'assets/activity-types/packaging.png'
import CuttingImage from 'assets/activity-types/cutting.png'
import SuccessMessage from 'components/common/SuccessMessage'

const WorkReportContainer: React.FC = () => {
  const [current, setCurrent] = useState(0)
  const { mutateAsync: createWorklog, isLoading } = useCreateWorkLogByWorker()
  const [stepSelections, setStepSelections] = useState<Store.WorklogStepSelections>({
    worker: null,
    activityType: null,
    machine: null,
  })
  const [status, setStatus] = useState(RequestStatus.None)

  const [modal, contextHolder] = Modal.useModal()

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const onSelect = (selections: Partial<Store.WorklogStepSelections>) => {
    setStepSelections((state) => ({ ...state, ...selections }))
  }

  const onFinish = async (data: Api.WorklogForm, forced = false) => {
    const postData: Api.WorklogPost = {
      cardId: data.cardId,
      date: data.date.toISOString(),
      machineId: stepSelections.machine,
      notes: data.notes,
      numPieces: data.numPieces,
      shift: data.shift,
      workerId: stepSelections.worker,
    }
    try {
      await createWorklog({ formData: postData, forced })
      setStatus(RequestStatus.Success)
    } catch (e) {
      if ((e as ApiError).status === 409) {
        modal.confirm({
          title: t('reporting.forcePostTitle'),
          content: t('reporting.forcePostBody'),
          okText: t('general.confirm'),
          cancelText: t('general.cancel'),
          onOk: () => onFinish(data, true),
        })
      } else {
        notification.error({ message: t('reporting.createError') })
      }
    }
  }

  const getActivityTypeImage = (activityType: MachineActivityType): string => {
    switch (activityType) {
      case MachineActivityType.Cutting:
        return CuttingImage
      case MachineActivityType.Grinding:
        return GrindingImage
      case MachineActivityType.Packaging:
        return PackagingImage
      case MachineActivityType.SandBlasting:
        return SandblastingImage
    }
  }

  const steps: WorkReportStep[] = [
    {
      title: t('reports.stepOne'),
      content: <ChooseYourself selected={stepSelections.worker} onSelect={onSelect} />,
    },
    {
      title: t('reports.stepTwo'),
      content: (
        <ChooseActivityType
          selected={stepSelections.activityType?.id}
          onSelect={onSelect}
          getActivityTypeImage={getActivityTypeImage}
        />
      ),
    },
    {
      title: t('reports.stepThree'),
      content: (
        <ChooseMachine
          selected={stepSelections.machine}
          onSelect={onSelect}
          placeHolderImage={getActivityTypeImage(stepSelections.activityType?.id)}
          activityType={stepSelections.activityType}
        />
      ),
    },
    {
      title: t('reports.final'),
      content: <EnterData onFinish={onFinish} loading={isLoading} />,
    },
  ]

  const onStatusMessageButtonClick = () => {
    setStatus(RequestStatus.None)
    setCurrent(0)
    setStepSelections({
      worker: null,
      activityType: null,
      machine: null,
    })
  }

  const renderContent = () => {
    switch (status) {
      case RequestStatus.None:
        return (
          <>
            <WorkReport
              steps={steps}
              current={current}
              onNextClick={next}
              onPrevClick={prev}
              stepSelections={stepSelections}
            />
            {contextHolder}
          </>
        )
      case RequestStatus.Success:
        return (
          <SuccessMessage
            message={t('reporting.createSuccess')}
            buttonText={t('reporting.backToReporting')}
            onClick={onStatusMessageButtonClick}
          />
        )
    }
  }

  return renderContent()
}

export default WorkReportContainer
