import React, { useState } from 'react'
import routes from 'config/routes'
import { Worker } from 'types/api/admin'
import { useTranslation } from 'react-i18next'
import { useCreateWorker } from 'services/query/workers'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import { notification } from 'antd'
import WorkerNew from 'components/workers/WorkerNew'
import Workers from 'containers/workers'

const WorkerDetailsContainer: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()
  const { createWorker } = useCreateWorker()

  const [spin, setSpin] = useState(false)

  const handleCreate = async (model: Worker) => {
    try {
      setSpin(true)
      await createWorker(model)
      setSpin(false)
      navigate(generatePath(routes.workers.url), { replace: true })
      notification.success({ message: t('messagesCrud.workerCreated') })
    } catch (error) {
      console.error(error)
      setSpin(false)
      notification.error({ message: t('messagesCrud.existingWorker') })
    }
  }

  if (id.toUpperCase() === 'NEW') {
    return <WorkerNew spin={spin} onCreate={handleCreate} />
  }

  return <Workers />
}

export default WorkerDetailsContainer
