import React, { useState } from 'react'
import { acronymify } from 'core/helpers'
import * as localStore from 'core/localStore'
import { useTranslation } from 'react-i18next'
import LogoFullWhite from 'assets/logo-full-white.svg'
import LogoFullBlack from 'assets/logo-full-black.svg'
import ThemeSwitcher from 'components/ThemeSwitcher'
import UserLogout from './UserLogout'
import LanguageSelectorContainer from 'containers/LanguageSelector'
import { UserRoles } from 'types/enums'

import { AiOutlineExpand, AiOutlineCompress, AiOutlineUser } from 'react-icons/ai'
import { Layout, Card, Row, Col, Space, Typography, Dropdown, Avatar, Button, Divider } from 'antd'
import styles from './styles.module.scss'

import { useTheme } from 'providers/ThemeProvider'

const Header: React.FC = () => {
  const { t } = useTranslation()
  const [isFullScreen, setIsFullScreen] = useState(false)
  const { theme } = useTheme()

  const getName = () => {
    try {
      return localStore.getUserClaims().userName
    } catch {
      return ''
    }
  }
  const name = getName()

  const shouldRenderLogo = () => {
    try {
      const user = localStore.getUserClaims()
      if (user.userRole === UserRoles.Worker) {
        return (
          <div className="logo">
            <img alt="logo" src={theme === 'light' ? LogoFullBlack : LogoFullWhite} />
          </div>
        )
      }
    } catch (error) {
      return false
    }
  }

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen)
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen()
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
    }
  }

  const userDropdown = (
    <Card style={{ width: 200 }} className="p-12">
      <Space align="end">
        <AiOutlineUser size={24} />
        <Typography.Title level={5} className="m-0">
          {t('menu.profile')}
        </Typography.Title>
      </Space>
      <Divider className="my-12" />
      <UserLogout />
    </Card>
  )

  return (
    <Layout.Header className={styles.header}>
      <Row align="middle" justify="space-between">
        <Col className="align-left">{shouldRenderLogo()}</Col>
        <Col className="align-right">
          <Space size="middle" align="center">
            <LanguageSelectorContainer />
            <Button type="link" size="small" className="p-0" onClick={toggleFullScreen}>
              {isFullScreen ? <AiOutlineCompress /> : <AiOutlineExpand />}
            </Button>
            <ThemeSwitcher />
            <Dropdown overlay={userDropdown} trigger={['click']}>
              <Avatar size={30} style={{ cursor: 'pointer' }}>
                {acronymify(name, 2).toUpperCase()}
              </Avatar>
            </Dropdown>
          </Space>
        </Col>
      </Row>
    </Layout.Header>
  )
}

Header.displayName = 'Header'

export default Header
