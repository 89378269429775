import React from 'react'
import { useParams } from 'react-router-dom'
import CustomerCreateContainer from 'containers/customers/CustomerCreateContainer'
import CustomerDetailsContainer from 'containers/customers/CustomerDetailsContainer'

const CustomerDetailsPage: React.FC = () => {
  const { id } = useParams()

  if (id === 'new') {
    return <CustomerCreateContainer />
  }

  return <CustomerDetailsContainer />
}

export default CustomerDetailsPage
