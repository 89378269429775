import React from 'react'
import routes from 'config/routes'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { checkSubmitButtonBeDisabled } from 'core/helpers'
import { AiOutlineLock, AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

import { Button, Col, Divider, Form, Input, Row, Typography } from 'antd'

interface Props {
  onFinish: (values: Json) => void
  loading: boolean
}

const ChangePassword: React.FC<Props> = ({ loading, onFinish }) => {
  const { t } = useTranslation()

  const [form] = Form.useForm()

  const requiredFields = ['password']

  return (
    <Form form={form} onFinish={onFinish}>
      <Typography.Title level={4}>{t('user.changePassword')}</Typography.Title>
      <Divider className="mt-0" />
      <Row>
        <Col span={24}>
          <Form.Item
            label={t('user.newPassword')}
            name="newPassword"
            rules={[
              { min: 3, message: t('validationForm.nameCharacters') },
              { required: true, message: t('validationForm.fieldRequired') },
            ]}
          >
            <Input.Password
              prefix={<AiOutlineLock />}
              size="large"
              type="password"
              placeholder={t('login.passwordPlaceholder')}
              suffix={(visible) => (visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item shouldUpdate className="btn mt-15 align-right">
        {() => (
          <Col xs={24} lg={{ span: 12, offset: 12 }} className="mt-48">
            <Row justify="end" gutter={[12, 12]}>
              <Col xs={12} lg={8}>
                <Button block htmlType="submit">
                  <Link to={routes.users.url}>{t('btn.cancel')}</Link>
                </Button>
              </Col>
              <Col xs={12} lg={8}>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={checkSubmitButtonBeDisabled(form, requiredFields)}
                >
                  {t('btn.save')}
                </Button>
              </Col>
            </Row>
          </Col>
        )}
      </Form.Item>
    </Form>
  )
}

ChangePassword.displayName = 'ChangeAccountDetails'

export default ChangePassword
