import { config } from 'core'
import { AxiosError, AxiosResponse } from 'axios'
import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'
import * as Api from 'types/api/admin'

const axios = getAxios()

//Languages
export async function listLanguages(): Promise<Api.LanguageListData[]> {
  try {
    const response = await axios.get(config.api.listLanguages(), await getAuthenticationHeader())
    return response.data as Api.LanguageListData[]
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getLanguageDetails(languageId: number): Promise<Api.LanguageListData> {
  try {
    const response = await axios.get(config.api.language(languageId), await getAuthenticationHeader())
    return response.data as Api.LanguageListData
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function createLanguage(
  model: Api.LanguageCreateEditRequest,
): Promise<Api.LanguageCreateEditRequest> {
  try {
    const response = await axios.post(config.api.newLanguage(), model, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function editLanguage(
  languageId: number,
  model: Api.LanguageCreateEditRequest,
): Promise<Api.LanguageCreateEditRequest> {
  try {
    const response = await axios.put(config.api.language(languageId), model, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteLanguage(languageId: number): Promise<AxiosResponse> {
  try {
    const response = await axios.delete(config.api.language(languageId), await getAuthenticationHeader())
    return response as AxiosResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

// translations

export async function listTranslations(filter: string, page: number): Promise<Api.TranslationResponse> {
  try {
    const response = await axios.get<Api.TranslationResponse>(
      config.api.listTranslations(filter, page),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function createTranslation(
  model: Api.TranslationEditRequest,
): Promise<Api.TranslationListData[]> {
  try {
    const response = await axios.post(config.api.createTranslation(), model, await getAuthenticationHeader())
    return response.data as Api.TranslationListData[]
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function editTranslation(
  model: Api.TranslationEditRequest,
): Promise<Api.TranslationEditRequest> {
  try {
    const response = await axios.put(config.api.translation(model.id), model, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteTranslation(translationId: number): Promise<AxiosResponse> {
  try {
    const response = await axios.delete(
      config.api.translation(translationId),
      await getAuthenticationHeader(),
    )
    return response as AxiosResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

//activity types
export async function listActivityTypes(): Promise<Api.ActivityTypeData[]> {
  try {
    const response = await axios.get(config.api.listActivityTypes(), await getAuthenticationHeader())
    return response.data as Api.ActivitySubTypeData[]
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
export async function listActivitySubTypes(): Promise<Api.ActivitySubTypeData[]> {
  try {
    const response = await axios.get(config.api.listActivitySubTypes(), await getAuthenticationHeader())
    return response.data as Api.ActivitySubTypeData[]
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
