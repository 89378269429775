import * as Api from 'types/api'
import * as Store from 'types/store'
import Debug from 'debug'
import { getApplicationError } from 'core'
import { ErrorCode, VariableValueType } from 'types/enums'

const debug = Debug('services:map:iotDevices')

export const mapIotDevice = ({
  description,
  deviceId,
  deviceModel,
  deviceSupplier,
  deviceVersion,
  statusActive,
}: Api.IotDevice): Store.IotDevice => ({
  description,
  id: deviceId,
  model: deviceModel,
  supplier: deviceSupplier,
  version: deviceVersion,
  statusActive,
})

export const mapDeviceVariable = ({
  deviceId,
  variableId,
  variableName,
  deviceBlock,
  deviceCategory,
  enabled,
  valueType,
  latestLog,
}: Api.DeviceVariable): Store.DeviceVariable => ({
  deviceId,
  id: variableId,
  name: variableName,
  deviceBlock,
  deviceCategory,
  enabled,
  valueType,
  latestLog: latestLog && mapVariableLog(valueType)(latestLog),
})

export const mapVariableLog = (valueType: VariableValueType) => {
  return ({
    deviceId,
    logTimestamp,
    logTimestampUtc,
    variableId,
    numericValue,
    booleanValue,
    stringValue,
  }: Api.VariableLog): Store.VariableLog => {
    const getValue = () => {
      switch (valueType) {
        case VariableValueType.Numeric:
          return numericValue
        case VariableValueType.Boolean:
          return booleanValue
        case VariableValueType.String:
          return stringValue
        default:
          return null
      }
    }

    try {
      return {
        deviceId,
        logTimestamp,
        logTimestampUtc,
        id: variableId,
        value: getValue(),
      }
    } catch (error) {
      debug(error)
      throw getApplicationError(ErrorCode.Api, error.message)
    }
  }
}

export const mapLogsReponse = ({ device, variable, logs }: Api.LogsResponse): Store.LogsResponse => ({
  device: mapIotDevice(device),
  variable: mapDeviceVariable(variable),
  logs: logs.map(mapVariableLog(variable.valueType)),
})
