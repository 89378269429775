import { useContext } from 'react'
import { ThemeContext } from './ThemeContext'

import 'themes/dark.less'
import 'themes/light.less'

export { default } from './ThemeProvider'

export const useTheme = () => {
  return useContext(ThemeContext)
}
