import React from 'react'
import routes from 'config/routes'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PageHeader from 'components/PageHeader'
import { Card, Row, Col, Button, Input, Form } from 'antd'
import style from './styles.module.scss'
interface Props {
  onCreateLanguage: (newLanguage) => void
}

const LanguageDetailsNew: React.FC<Props> = ({ onCreateLanguage }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [formData, setFormData] = React.useState({
    name: null,
    iso: null,
  })
  // const crumbs = () => [
  //   { route: routes.home },
  //   { route: routes.languages },
  //   { route: routes.languageDetails, routeParam: { key: 'id', value: 'new' } },
  // ]
  return (
    <section className={style['lan-details']}>
      <PageHeader backRoute={routes.languages}></PageHeader>
      {/* <TopPage title={t('languages.languageDetails')} crumbs={crumbs()} /> */}
      <Card className="mt-50">
        <Row gutter={[24, 24]} className="mt-20">
          <Col span={24}>
            <Form.Item
              label={t('languages.name')}
              name="name"
              rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
            >
              <Input
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              />
            </Form.Item>

            <Form.Item label={t('languages.iso')} className="mt-30">
              <Input
                value={formData.iso}
                onChange={(e) => setFormData({ ...formData, iso: e.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className="mt-50">
          <Col xs={24} lg={{ span: 12, offset: 12 }}>
            <Row justify="end" gutter={[12, 12]}>
              <Col xs={12} lg={6}>
                <Button block onClick={() => navigate(routes.languages.url)}>
                  {t('btn.cancel')}
                </Button>
              </Col>
              <Col xs={12} lg={6}>
                <Button block type="primary" onClick={() => onCreateLanguage(formData)}>
                  {t('btn.save')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </section>
  )
}
LanguageDetailsNew.displayName = 'LanguageDetailsNew'

export default LanguageDetailsNew
