import * as queryKeys from 'config/queryKeys'
import { useQuery } from 'react-query'
import {
  getArticlesDashboardData,
  getDailyDashboardData,
  getMachinesDashboardData,
  getPeriodDashboardData,
} from 'api/dashboard'
import { mapDashboardArticle, mapDashboardDaily, mapDashboardMachine } from 'services/map/dashboard'

export function useLoadTodayDashboardData() {
  const queryFunction = async () => {
    const response = await getDailyDashboardData()
    return mapDashboardDaily(response)
  }

  return useQuery(queryKeys.dashboardDaily(), queryFunction, {
    placeholderData: null,
  })
}

export function useLoadPeriodDashboardData(start?: string | undefined, end?: string | undefined) {
  const queryFunction = async () => {
    const response = await getPeriodDashboardData(start, end)
    return response.map(mapDashboardDaily)
  }

  return useQuery(queryKeys.dashboardPeriodData(), queryFunction, {
    placeholderData: [],
  })
}

export function useLoadMachinesDashboardData() {
  const queryFunction = async () => {
    const response = await getMachinesDashboardData()
    return response.map(mapDashboardMachine)
  }

  return useQuery(queryKeys.dashboardMachines(), queryFunction, {
    placeholderData: [],
  })
}

export function useLoadArticleDashboardData() {
  const queryFunction = async () => {
    const response = await getArticlesDashboardData()
    return response.map(mapDashboardArticle)
  }

  return useQuery(queryKeys.dashboardArticles(), queryFunction, {
    placeholderData: [],
  })
}
