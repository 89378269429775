import React from 'react'
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { MachineReportsData } from 'types/store'
import MachineBarchart from './MachineBarchart'
import { tableSorter } from 'core/helpers'
import styles from '../styles.module.scss'
import * as Store from 'types/store'

interface Props {
  data: Store.MachineReportsData[]
  isFetching: boolean
}

const TableByMachines: React.FC<Props> = ({ data, isFetching }) => {
  const { t } = useTranslation()

  const column: any = [
    {
      key: 'machineId',
      title: t('machines.nickname'),
      dataIndex: 'machine',
    },
    {
      key: 'total',
      title: t('general.total'),
      dataIndex: 'total',
      sorter: (a, b) => tableSorter(a.total, b.total),
      align: 'center',
      width: 130,
    },
  ]

  const expandedRowRender = (record: MachineReportsData) => <MachineBarchart data={record} />

  if (isFetching) {
    return null
  }

  return (
    <Table
      dataSource={data}
      columns={column}
      rowKey="machineId"
      pagination={false}
      className={styles['report-machines']}
      expandable={{
        expandedRowRender,
      }}
    />
  )
}

export default TableByMachines
