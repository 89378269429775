import React from 'react'
import routes from 'config/routes'
import * as Store from 'types/store'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import PageHeader from 'components/PageHeader'
import { Card, Descriptions, Button } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

interface Props {
  customer: Store.Customer
  onDelete?: (customer: Store.Customer) => void
}

const CustomerDetails: React.FC<Props> = ({ customer, onDelete }) => {
  const { t } = useTranslation()

  return (
    <>
      <PageHeader backRoute={routes.customers} title={customer.businessName}>
        <Button
          href={generatePath(routes.customerDetails.url, { id: customer.id.toString() }) + '?editmode=true'}
        >
          <EditOutlined />
          {t('btn.edit')}
        </Button>
        <Button type="text" onClick={() => onDelete(customer)}>
          <DeleteOutlined />
          {t('btn.deleteCustomer')}
        </Button>
      </PageHeader>
      <Card>
        <Descriptions column={1} colon={false} title={t('general.basicInfo')} className="descriptions-half">
          <Descriptions.Item label={t('customer.vatNr')}> {customer.vatNr} </Descriptions.Item>
          <Descriptions.Item label={t('general.email')}> {customer.email} </Descriptions.Item>
          <Descriptions.Item label={t('general.phone')}> {customer.phone} </Descriptions.Item>
          <Descriptions.Item label={t('general.address')}> {customer.address} </Descriptions.Item>
          <Descriptions.Item label={t('general.website')}> {customer.website} </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  )
}

export default CustomerDetails
