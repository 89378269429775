import { config } from 'core'
import { AxiosError, AxiosResponse } from 'axios'
import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'
import * as Api from 'types/api'

const axios = getAxios()

export async function createWorklogtByWorker(
  model: Api.WorklogPost,
  forced: boolean,
): Promise<Api.WorklogResponse> {
  try {
    const response = await axios.post<Api.WorklogResponse>(
      config.api.createWorklogByWorker(forced),
      model,
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function createWorklogByAdmin(model: Api.WorklogPost): Promise<Api.WorklogResponse> {
  try {
    const response = await axios.post<Api.WorklogResponse>(
      config.api.createWorklogByAdmin(),
      model,
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function updateWorklog(model: Api.WorklogPost, id: number) {
  try {
    const response = await axios.put(config.api.worklog(id), model, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getWorklog(id: number): Promise<Api.WorklogResponse> {
  try {
    const response = await axios.get<Api.WorklogResponse>(
      config.api.worklog(id),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getWorklogList(
  card: string,
  page: string,
  worker: string,
  machine: string,
  dateStart: string,
  dateEnd: string,
): Promise<Api.WorklogtListResponse> {
  try {
    const response = await axios.get<Api.WorklogtListResponse>(
      config.api.getWorklogList(card, page, worker, machine, dateStart, dateEnd),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteWorklog(id: number): Promise<AxiosResponse> {
  try {
    const response = await axios.delete(config.api.worklog(id), await getAuthenticationHeader())
    return response as AxiosResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
