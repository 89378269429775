import { UserRoles } from 'types/enums'

const root = process.env.REACT_APP_HOMEPAGE

const allUsers = [UserRoles.Admin, UserRoles.Supervisor, UserRoles.Worker]
const privilegedUsers = [UserRoles.Admin, UserRoles.Supervisor]

const routes: Routes = {
  home: {
    key: 'home',
    url: `${root}/`,
    private: true,
    menu: 'menu.home',
    authorize: allUsers,
  },
  users: {
    key: 'users',
    url: `${root}/users`,
    private: true,
    menu: 'menu.users',
    authorize: privilegedUsers,
  },
  userDetails: {
    key: 'userDetails',
    url: `${root}/user/:id`,
    private: true,
    menu: 'menu.usersDetails',
    authorize: privilegedUsers,
  },
  workers: {
    key: 'workers',
    url: `${root}/workers`,
    private: true,
    menu: 'menu.workers',
    authorize: privilegedUsers,
  },
  workerDetails: {
    key: 'workerDetails',
    url: `${root}/worker/:id`,
    private: true,
    menu: 'menu.workerDetails',
    authorize: privilegedUsers,
  },
  order: {
    key: 'order',
    url: `${root}/order/:id`,
    private: true,
    menu: 'menu.order',
    authorize: privilegedUsers,
  },
  orders: {
    key: 'orders',
    url: `${root}/orders`,
    private: true,
    menu: 'menu.orders',
    authorize: privilegedUsers,
  },
  translations: {
    key: 'translations',
    url: `${root}/translations`,
    private: true,
    menu: 'menu.translations',
    authorize: privilegedUsers,
  },
  translationDetails: {
    key: 'translation',
    url: `${root}/translation/:id`,
    private: true,
    menu: 'translations.translationsDetails',
    authorize: privilegedUsers,
  },
  languages: {
    key: 'languages',
    url: `${root}/languages`,
    private: true,
    menu: 'menu.languages',
    authorize: privilegedUsers,
  },
  languageDetails: {
    key: 'languageDetails',
    url: `${root}/language/:id`,
    private: true,
    menu: 'menu.languageDetails',
    authorize: privilegedUsers,
  },
  customers: {
    key: 'customers',
    url: `${root}/customers`,
    private: true,
    menu: 'menu.customers',
    authorize: privilegedUsers,
  },
  customerDetails: {
    key: 'customerDetails',
    url: `${root}/customer/:id`,
    private: true,
    menu: 'menu.customerDetails',
    authorize: privilegedUsers,
  },
  manifacturingCards: {
    key: 'manifacturingCards',
    url: `${root}/customers/card`,
    private: true,
    menu: 'menu.manifacturingCards',
    authorize: privilegedUsers,
  },
  manifacturingCard: {
    key: 'manifacturingCard',
    url: `${root}/customers/card/:id`,
    private: true,
    menu: '',
    authorize: privilegedUsers,
  },
  machines: {
    key: 'machines',
    url: `${root}/machines`,
    private: true,
    menu: 'menu.machines',
    authorize: privilegedUsers,
  },
  iotData: {
    key: 'iotData',
    url: `${root}/iotData`,
    private: true,
    menu: 'menu.iotData',
    authorize: privilegedUsers,
  },
  variableData: {
    key: 'variableData',
    url: `${root}/iotData/:deviceId/variable/:id`,
    private: true,
    menu: 'menu.variableData',
    authorize: privilegedUsers,
  },
  machine: {
    key: 'machine',
    url: `${root}/machine/:id`,
    private: true,
    menu: 'menu.Machine',
    authorize: privilegedUsers,
  },
  planning: {
    key: 'planning',
    url: `${root}/production/planning`,
    private: true,
    menu: 'menu.planning',
    authorize: privilegedUsers,
  },
  shiftPlanning: {
    key: 'shift-planning',
    url: `${root}/production/shift-planning`,
    private: true,
    menu: 'menu.shiftPlanning',
    authorize: privilegedUsers,
  },
  workReports: {
    key: 'work-reports',
    url: `${root}/production/work-report`,
    private: true,
    menu: 'menu.workReport',
    authorize: privilegedUsers,
  },
  workReport: {
    key: 'work-report',
    url: `${root}/production/work-report/:id`,
    private: true,
    menu: '',
    authorize: privilegedUsers,
  },
  reportMachines: {
    key: 'reportMachines',
    url: `${root}/reports/by-machines`,
    private: true,
    menu: 'menu.reportMachines',
    authorize: privilegedUsers,
  },
  reportArticles: {
    key: 'reportArticles',
    url: `${root}/reports/by-articles`,
    private: true,
    menu: 'menu.reportArticles',
    authorize: privilegedUsers,
  },
  wharehouse: {
    key: 'wharehouse',
    url: `${root}/wharehouse`,
    private: true,
    menu: 'menu.wharehouse',
    authorize: privilegedUsers,
  },

  //Authentication Routes
  login: {
    key: 'login',
    url: `${root}/login`,
    private: false,
    menu: '',
  },
  reportPdf: {
    key: 'generate-report-pdf',
    url: `${root}/reports/:type/pdf`,
    private: false,
    menu: '',
  },
  confirmEmail: {
    key: 'confirmEmail',
    url: `${root}/auth/confirm-email`,
    private: false,
    menu: '',
  },
  forgotPassword: {
    key: 'forgotPassword',
    url: '/forgot-password',
    private: false,
    menu: '',
  },
  resetPassword: {
    key: 'resetPassword',
    url: '/reset-password',
    private: false,
    menu: '',
  },
  //Error Routes
  notFoundPage: {
    key: 'not-found',
    url: `${root}/page-not-found`,
    private: false,
    menu: '',
  },
  errorPage: {
    key: 'error',
    url: `${root}/error`,
    private: false,
    menu: '',
  },
  playgroundPage: {
    key: 'playground',
    url: `${root}/playground`,
    private: true,
    menu: '',
  },
}

export default routes
