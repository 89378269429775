import * as Api from 'types/api'
import * as Store from 'types/store'
import { config } from 'core'
import moment from 'moment'

//MAchines
export const mapReportsMachineData = (json: Api.MachineReportsByDate[]): Store.MachineReportsByDate[] => {
  return json.map(({ date, piecesCompleted, piecesPlanned }) => ({
    piecesPlanned,
    piecesCompleted,
    date: moment(date).format(config.getDateFormat()),
  }))
}

export const mapReportsMachine = ({
  piecesCompleted,
  machine,
  machineId,
  data,
  typeId,
}: Api.MachineReportsData): Store.MachineReportsData => ({
  total: piecesCompleted,
  machine,
  machineId,
  data: mapReportsMachineData(data),
  typeId,
})

// Activity Types
export const mapReportsActivityTypeData = (
  json: Api.ActivityTypesReportsByDate[],
): Store.ActivityTypesReportsByDate[] => {
  return json.map(({ date, piecesCompleted, piecesPlanned }) => ({
    piecesPlanned,
    piecesCompleted,
    date: moment(date).format(config.getDateFormat()),
  }))
}

export const mapReportsActivityType = ({
  piecesCompleted,
  activityType,
  activityTypeId,
  data,
}: Api.ActivityTypesReportsData): Store.ActivityTypesReportsData => ({
  total: piecesCompleted,
  activityType,
  activityTypeId,
  data: mapReportsActivityTypeData(data),
})

//Articles
export const mapReportsArticleData = (json: Api.ArticlesReportsByDate[]): Store.ArticlesReportsByDate[] => {
  return json.map(({ date, piecesCompleted, piecesPlanned }) => ({
    piecesPlanned,
    piecesCompleted,
    date: moment(date).format(config.getDateFormat()),
  }))
}

export const mapReportsArticle = ({
  piecesCompleted,
  article,
  articleId,
  data,
}: Api.ArticlesReportsData): Store.ArticlesReportsData => ({
  total: piecesCompleted,
  article,
  articleId,
  data: mapReportsArticleData(data),
})
