import React from 'react'
import { useParams } from 'react-router-dom'

import WorklogCreateContainer from 'containers/worklogs/WorklogCreate'
import WorklogDetails from 'containers/worklogs/WorklogDetails'

const WorklogPage: React.FC = () => {
  const { id } = useParams()

  if (id === 'new') {
    return <WorklogCreateContainer />
  }

  return <WorklogDetails />
}

export default WorklogPage
