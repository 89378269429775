import React from 'react'

import UsersTable from 'components/users'
import { useDeleteUser, useLoadUserList } from 'services/query/users'

const UsersContainer: React.FC = () => {
  const { data: users, refetch, isFetched, isLoading } = useLoadUserList()
  const dataLoading: boolean = isLoading || !isFetched

  const deleteUser = useDeleteUser()
  const handleDelete = async (userId: number) => {
    await deleteUser(userId)
    refetch()
  }

  return <UsersTable users={users} dataLoading={dataLoading} handleDelete={handleDelete} />
}

export default UsersContainer
