import React from 'react'
import { Card, Col, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

interface Props {
  online: number
  offline: number
}

const StatusCards: React.FC<Props> = ({ online, offline }) => {
  const { t } = useTranslation()

  return (
    <Row className="status-boxes-container mb-24" gutter={[24, 24]} align="top">
      <Col xs={12} lg={9} xxl={6}>
        <Card className="status-box status-box--online">
          <Typography.Title level={4}>{online}</Typography.Title>

          <Typography.Title level={5}>{t('iot.onlineDevices')}</Typography.Title>
        </Card>
      </Col>
      <Col xs={12} lg={9} xxl={6}>
        <Card className="status-box status-box--offline">
          <Typography.Title level={4}>{offline}</Typography.Title>

          <Typography.Title level={5}>{t('iot.offlineDevices')}</Typography.Title>
        </Card>
      </Col>
    </Row>
  )
}

export default StatusCards
