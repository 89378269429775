import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Row, Typography } from 'antd'
import PageHeader from 'components/PageHeader'
import StatusCards from './StatusCards'
import VariableCard from './VariableCard'
import DevicesList from './DevicesList'
import { useLoadDeviceVariables, useLoadIotDevices } from 'services/query/iotDevices'
import Loading from 'components/Loading'
import { VariableValueType } from 'types/enums'
import styles from './styles.module.scss'
import { ReloadOutlined } from '@ant-design/icons'
import classNames from 'classnames'

export const LATEST_ACTIVITY_DAYS = 7

const IotData: React.FC = () => {
  const { t } = useTranslation()
  const [userSelectedDevice, setUserSelectedDevice] = useState<number | null>(null)

  const { data: devices } = useLoadIotDevices()
  const selectedDevice = useMemo(
    () =>
      userSelectedDevice === null && Array.isArray(devices) && devices.length > 0
        ? devices[0].id
        : userSelectedDevice,
    [devices, userSelectedDevice],
  )

  const {
    data: variables,
    refetch: variablesRefetch,
    isRefetching,
    isFetched,
  } = useLoadDeviceVariables(selectedDevice)

  const onlineDevicesAmount = devices.filter((device) => device.statusActive === true).length
  const offlineDevicesAmount = devices.filter((device) => device.statusActive !== true).length

  const renderVariableCards = () => {
    if (variables === null) {
      return <Loading />
    }

    return variables ? (
      <Row
        className={classNames('variables-wrapper', { blinked: !isRefetching && isFetched })}
        gutter={[24, 24]}
      >
        {variables.map((variable) => (
          <Col key={variable.id} xs={24} xl={12} xxl={8}>
            <VariableCard data={variable} withLink={variable.valueType === VariableValueType.Numeric} />
          </Col>
        ))}
      </Row>
    ) : (
      <Col className="no-variables-wrapper" span={24}>
        <Typography.Title level={4}>{t('iot.noVariables')}</Typography.Title>
      </Col>
    )
  }

  return (
    <section className={styles['iot-data-dashboard']}>
      <Row>
        <Col>
          <Typography.Title level={2}>{`${t('menu.dashboards')}:`}</Typography.Title>
        </Col>
        <Col className="page-header-col">
          <PageHeader title={`${t('menu.iotData')}`}>
            <Button
              type="primary"
              icon={<ReloadOutlined />}
              loading={isRefetching}
              onClick={() => variablesRefetch()}
            >
              {t('btn.refresh')}
            </Button>
          </PageHeader>
        </Col>
      </Row>
      <Row className="widgets-container" gutter={[24, 24]}>
        <Col xs={24} lg={7} className="devices-container">
          <DevicesList
            data={devices}
            selectedDevice={selectedDevice}
            setSelectedDevice={setUserSelectedDevice}
          />
        </Col>

        <Col xs={24} lg={17} className="status-variables-container">
          <StatusCards online={onlineDevicesAmount} offline={offlineDevicesAmount} />
          <div className="variables-container">{renderVariableCards()}</div>
        </Col>
      </Row>
    </section>
  )
}

export default IotData
