import React, { useState } from 'react'
import routes from 'config/routes'
import { Machine } from 'types/store'
import { useTranslation } from 'react-i18next'
import { useCreateMachine } from 'services/query/machines'
import { generatePath, useNavigate } from 'react-router-dom'
import { useLoadActivityTypeList } from 'services/query/admin'
import MachineCreateEdit from 'components/machines/MachineCreateEdit'

import { notification } from 'antd'

const MachineCreateContainer: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const createMachine = useCreateMachine()
  const { data: activityTypes } = useLoadActivityTypeList()

  const [spin, setSpin] = useState(false)

  const onCreate = async (model: Machine) => {
    try {
      setSpin(true)
      await createMachine(model)
      setSpin(false)
      navigate(generatePath(routes.machines.url), { replace: true })
      notification.success({ message: t('messagesCrud.machineCreated') })
    } catch (error) {
      console.error(error)
      setSpin(false)
      notification.error({ message: t('messagesCrud.error') })
    }
  }

  return <MachineCreateEdit spin={spin} machine={null} onCreate={onCreate} activityTypes={activityTypes} />
}

export default MachineCreateContainer
