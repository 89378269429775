import React from 'react'
import { Button, Typography, Card, Avatar } from 'antd'

import SuccessIcon from 'assets/icon/success.png'

interface Props {
  message: string
  buttonText: string
  onClick: () => void
}

const SuccessMessage: React.FC<Props> = ({ message, buttonText, onClick }) => {
  return (
    <Card className="status-message status-mesagge--success">
      <div className="wrapper">
        <div className="first-row">
          <Avatar shape="circle" size={200} src={SuccessIcon} />

          <Typography.Text>{message}</Typography.Text>
        </div>
        <div className="second-row">
          <Button type="primary" size="large" onClick={onClick}>
            {buttonText}
          </Button>
        </div>
      </div>
    </Card>
  )
}

export default SuccessMessage
