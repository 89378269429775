import { Button, Form, Radio, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { WorkerShifts } from 'types/enums'
import { ModalOptions } from 'containers/ShiftPlanning/ShiftPlanning'
import { useGetWorker } from 'services/query/workers'
import { DeleteOutlined } from '@ant-design/icons'

interface Form {
  shift: WorkerShifts
}

interface Props {
  modalOptions: ModalOptions
  initialValues: Partial<Form>
  onFinish: (shift: WorkerShifts) => void
  onDelete: () => void
}

const ShiftPlanningModal: React.FC<Props> = ({
  modalOptions: { shiftPlanning, workerId },
  initialValues,
  onFinish,
  onDelete,
}) => {
  const { t } = useTranslation()
  const { data: worker } = useGetWorker(shiftPlanning?.workerId || workerId)

  return (
    <>
      <Typography.Title className="mb-24" level={2}>{`${worker?.name} ${worker?.surname}`}</Typography.Title>
      <Form initialValues={initialValues}>
        <Form.Item className="mb-16" name="shift" style={{ textAlign: 'center' }}>
          <Radio.Group defaultValue={initialValues?.shift} onChange={(value) => onFinish(value.target.value)}>
            <Radio.Button value={WorkerShifts.ShiftOne}>{t('planning.shiftOne')}</Radio.Button>
            <Radio.Button value={WorkerShifts.ShiftTwo}>{t('planning.shiftTwo')}</Radio.Button>
          </Radio.Group>
        </Form.Item>

        {onDelete && (
          <Form.Item className="mb-0">
            <Button type="text" onClick={onDelete}>
              <DeleteOutlined /> {t('btn.delete')}
            </Button>
          </Form.Item>
        )}
      </Form>
    </>
  )
}

export default ShiftPlanningModal
