import React, { useEffect, useState } from 'react'
import PageHeader from 'components/PageHeader'
import classNames from 'classnames'
import { useTheme } from 'providers/ThemeProvider'
import { themes } from './themes'

import { useTranslation } from 'react-i18next'
import { Button, Card, Col, DatePicker, Dropdown, Menu, Row, Select } from 'antd'
import { generateQueryParams } from 'core/helpers'
import { DownloadOutlined } from '@ant-design/icons'
import { ReportGroups } from 'types/enums'
import { useSearchParams } from 'react-router-dom'
import debounce from 'debounce'
import moment from 'moment'
import TableByMachines from './ByMachines/TableByMachines'
import TableByActivityTypes from './ByActivity/TableByActivityTypes'
import PDFIcon from 'assets/icon/pdf-icon.svg'
import ExcelIcon from 'assets/icon/excel-icon.svg'
import {
  useGenerateCSVReports,
  useGeneratePdfReports,
  useLoadMachinesReportsData,
} from 'services/query/reports'
import FileDownload from 'js-file-download'
import { config } from 'core'
import { useSelector } from 'react-redux'
import { selectLanguage } from 'store/user/selectors'

interface Filters {
  type: string
  dateStart: string
  dateEnd: string
}

type FilterKeys = keyof Filters

const ReportMachines: React.FC = () => {
  const { t } = useTranslation()
  const languageIso = useSelector(selectLanguage)
  const { RangePicker } = DatePicker

  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const componentClassname = classNames(themeStyles['theme'])

  const [searchParams, setSearchParams] = useSearchParams()
  const [filters, setFilters] = useState<Filters>({
    dateStart: searchParams.get('s') || '',
    dateEnd: searchParams.get('e') || '',
    type: searchParams.get('type') || ReportGroups.ByMachine,
  })
  const { dateStart, dateEnd, type } = filters
  const { data: machines, isFetching } = useLoadMachinesReportsData(dateStart, dateEnd)

  const defaultPeriodStart = machines.at(0)?.data.at(0).date
  const defaultPeriodEnd = machines.at(0)?.data.at(-1).date

  const getDateStart = () => {
    if (dateStart) {
      return moment(dateStart)
    } else if (defaultPeriodStart) {
      return moment(defaultPeriodStart, config.getDateTimeFormat())
    }
    return null
  }

  const getDateEnd = () => {
    if (dateEnd) {
      return moment(dateEnd)
    } else if (defaultPeriodEnd) {
      return moment(defaultPeriodEnd, config.getDateTimeFormat())
    }

    return null
  }

  const { mutateAsync: downloadPdf, isLoading: IsPdfLoading } = useGeneratePdfReports(
    dateStart,
    dateEnd,
    type,
  )
  const { mutateAsync: downloadCSV, isLoading: isCSVLoading } = useGenerateCSVReports(
    dateStart,
    dateEnd,
    type,
    languageIso,
  )

  const onDownloadPdfClick = async () => {
    const response = await downloadPdf()
    if (response?.data) {
      const startDay = dateStart !== '' ? moment(dateStart) : moment().startOf('month')
      const endDay = dateEnd !== '' ? moment(dateEnd) : moment().endOf('month')

      FileDownload(response.data, config.getReportPdfName(type, startDay, endDay, config.getDateFormat()))
    }
  }

  const onDownloadCSVClick = async () => {
    const response = await downloadCSV()
    if (response?.data) {
      const startDay = dateStart !== '' ? moment(dateStart) : moment().startOf('month')
      const endDay = dateEnd !== '' ? moment(dateEnd) : moment().endOf('month')

      FileDownload(response.data, config.getReportCSVName(type, startDay, endDay, config.getDateFormat()))
    }
  }

  const buttonDropdown = (
    <Menu onClick={(info) => info.key}>
      <Menu.Item icon={<img src={PDFIcon} />} onClick={() => onDownloadPdfClick()}>
        {t('general.exportPdf')}
      </Menu.Item>
      <Menu.Item icon={<img src={ExcelIcon} />} onClick={() => onDownloadCSVClick()}>
        {t('general.exportXls')}
      </Menu.Item>
    </Menu>
  )

  useEffect(() => {
    const query = generateQueryParams({ dateStart, dateEnd, type })
    setSearchParams(query)
  }, [dateStart, dateEnd, type])

  const onSetFilter = (key: FilterKeys, value: string) => {
    debounce(
      setFilters((filters) => ({ ...filters, [key]: value })),
      300,
    )
  }

  return (
    <section className={componentClassname}>
      <PageHeader title={`${t('reports.reportbyMachines')}`}>
        <RangePicker
          placeholder={[t('general.startDate'), t('general.endDate')]}
          value={[getDateStart(), getDateEnd()]}
          onChange={(dates) => {
            if (dates) {
              const [dateStart, dateEnd] = dates
              onSetFilter('dateStart', dateStart.toISOString())
              onSetFilter('dateEnd', dateEnd.toISOString())
            } else {
              onSetFilter('dateStart', '')
              onSetFilter('dateEnd', '')
            }
          }}
        />
      </PageHeader>

      <Card>
        <Row className="mb-24" justify="space-between" align="middle" gutter={[24, 16]}>
          <Col xs={24} lg={6}>
            <Select value={type} onSelect={(value) => onSetFilter('type', value)}>
              <Select.Option value={ReportGroups.ByMachine}>{t('menu.machines')}</Select.Option>
              <Select.Option value={ReportGroups.ByActivityType}>{t('machines.activityType')}</Select.Option>
            </Select>
          </Col>

          <Col>
            <Dropdown overlay={buttonDropdown}>
              <Button
                type="primary"
                className="btn-icon"
                icon={<DownloadOutlined />}
                loading={IsPdfLoading || isCSVLoading}
              >
                {t('btn.export')}
              </Button>
            </Dropdown>
          </Col>
        </Row>

        {type === ReportGroups.ByMachine ? (
          <TableByMachines data={machines} isFetching={isFetching} />
        ) : (
          <TableByActivityTypes dateStart={dateStart} dateEnd={dateEnd} />
        )}
      </Card>
    </section>
  )
}

export default ReportMachines
