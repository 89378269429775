import { config } from 'core'
import { AxiosError, AxiosResponse } from 'axios'
import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'
import * as Api from 'types/api/admin'
import { UserRole } from 'types/store'

const axios = getAxios()

export async function listUsers(): Promise<any> {
  try {
    const response = await axios.get(config.api.users(), await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getUserDetails(userId: number): Promise<Api.UserListData> {
  try {
    const response = await axios.get(config.api.user(userId), await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function createUser(model: Api.UserCreateEditRequest): Promise<Api.UserListData> {
  try {
    const response = await axios.post(config.api.users(), model, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function editUserPassword(newPassword: string, id: number): Promise<Api.UserListData> {
  try {
    const response = await axios.put(
      config.api.userChangePassword(id),
      { newPassword },
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function editUser(userId: number, model: Api.UserCreateEditRequest): Promise<Api.UserListData> {
  try {
    const response = await axios.put(config.api.user(userId), model, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteUser(userId: number): Promise<AxiosResponse> {
  try {
    const response = await axios.delete(config.api.user(userId), await getAuthenticationHeader())
    return response.data as AxiosResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getUserRoles(): Promise<UserRole[]> {
  try {
    const response = await axios.get<UserRole[]>(config.api.userRoles(), await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
