import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Upload, notification } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { UploadFile } from 'antd/lib/upload/interface'
import { useUploadProductionCardImages } from 'services/query/productionCards'
import routes from 'config/routes'
import { generatePath, useNavigate } from 'react-router-dom'

interface Props {
  cardId: number
  isPosted: boolean
  setSpin: (isSpinning: boolean) => void
}

const ImageUpload: React.FC<Props> = ({ cardId, isPosted, setSpin }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const { t } = useTranslation()
  const uploadImages = useUploadProductionCardImages(fileList, cardId)
  const navigate = useNavigate()

  const handleUpload = async () => {
    try {
      await uploadImages()
    } catch (error) {
      notification.error({ message: t('messagesCrud.imageUploadError') })
    } finally {
      setSpin(false)
      navigate(generatePath(routes.manifacturingCards.url), { replace: true })
    }
  }

  useEffect(() => {
    cardId && isPosted && handleUpload()
  }, [cardId, isPosted])

  return (
    <>
      <Upload
        multiple
        listType="picture-card"
        onRemove={(file) => {
          const index = fileList.indexOf(file)
          const newFileList = fileList.slice()
          newFileList.splice(index, 1)
          setFileList(newFileList)
        }}
        beforeUpload={(_, files) => {
          setFileList([...fileList, ...files])

          return false
        }}
        fileList={fileList}
      >
        <Button icon={<UploadOutlined />}>{t('general.select.file')}</Button>
      </Upload>
    </>
  )
}

export default ImageUpload
