import { config } from 'core'
import { AxiosError } from 'axios'
import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'
import * as Api from 'types/api'

const axios = getAxios()

export async function getDailyDashboardData(): Promise<Api.DailyDashboardData> {
  try {
    const response = await axios.get<Api.DailyDashboardData>(
      config.api.dashboardDaily(),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getPeriodDashboardData(
  start?: string | undefined,
  end?: string | undefined,
): Promise<Api.DailyDashboardData[]> {
  try {
    const response = await axios.get<Api.DailyDashboardData[]>(
      config.api.dashboardPeriodData(start, end),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getMachinesDashboardData(): Promise<Api.MachineDashboardData[]> {
  try {
    const response = await axios.get<Api.MachineDashboardData[]>(
      config.api.dashboardMachinesData(),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getArticlesDashboardData(): Promise<Api.ArticleDashboardData[]> {
  try {
    const response = await axios.get<Api.ArticleDashboardData[]>(
      config.api.dashboardArticlesData(),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
