import React, { useState, useEffect } from 'react'
import debounce from 'debounce'
import routes from 'config/routes'
import * as Store from 'types/store'
import { tableSorter } from 'core/helpers'
import { useTranslation } from 'react-i18next'
import { generatePath, Link, useSearchParams } from 'react-router-dom'
import { useLoadCustomersList, useDeleteCustomer } from 'services/query/customers'

import PageHeader from 'components/PageHeader'
import Table from 'components/Layout/Table'
import FilterPanel from 'components/FilterPanel'

import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus, AiOutlineEye } from 'react-icons/ai'
import { Button, Card, Modal, notification, Space, Tooltip, Typography } from 'antd'

const CustomersTableComponent: React.FC = () => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const [filters, setFilters] = useState({
    filter: searchParams.get('filter') || '',
    page: searchParams.get('page') || '1',
  })
  const { filter, page } = filters

  const { data: customers, isLoading, isFetched, refetch } = useLoadCustomersList(filter, page)

  const onFilter = (value: string) => {
    debounce(setFilters({ ...filters, filter: value, page: '1' }), 300)
  }

  useEffect(() => {
    const query = Object.entries({ filter, page }).reduce((object, [key, value]) => {
      if (value) {
        object[key] = value
      }
      return object
    }, {})

    setSearchParams(query)
  }, [filter, page])

  const dataLoading: boolean = isLoading || !isFetched

  const deleteCustomer = useDeleteCustomer()

  const onDelete = async (customer: Store.Customer) => {
    try {
      await deleteCustomer(customer.id)
      refetch()
      notification.success({ message: t('messagesCrud.customerDeleted') })
    } catch (error) {
      notification.error({ message: t('messagesCrud.deleteError') })
    }
  }

  const handleDelete = (customer: Store.Customer) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      onOk() {
        onDelete(customer)
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  const columns: any = [
    {
      key: 'id',
      title: t('general.id'),
      dataIndex: 'id',
      width: 100,
      sorter: (a, b) => tableSorter(a.id, b.id),
    },
    {
      key: 'businessName',
      title: t('customer.businessName'),
      dataIndex: 'businessName',
      sorter: (a, b) => tableSorter(a.businessName, b.businessName),
    },
    {
      key: 'vatNr',
      title: t('customer.vatNr'),
      dataIndex: 'vatNr',
      sorter: (a, b) => tableSorter(a.vatNr, b.vatNr),
    },
    {
      key: 'email',
      title: t('general.email'),
      dataIndex: 'email',
      sorter: (a, b) => tableSorter(a.email, b.email),
    },
    {
      key: 'actions',
      title: t('general.actions'),
      dataIndex: 'id',
      width: 100,
      className: 'cell-actions',
      render: function actions(id, record: Store.Customer) {
        return (
          <Space size={0}>
            <Tooltip key={`action-details-${id}`} title={t('tooltip.details')}>
              <Link to={generatePath(routes.customerDetails.url, { id: id })}>
                <Button type="link" size="small">
                  <AiOutlineEye />
                </Button>
              </Link>
            </Tooltip>
            <Tooltip key={`action-edit-${id}`} title={t('tooltip.edit')}>
              <Link to={generatePath(routes.customerDetails.url, { id: id }) + '?editmode=true'}>
                <Button type="link" size="small">
                  <AiOutlineEdit />
                </Button>
              </Link>
            </Tooltip>
            <Tooltip key={`action-delete-${id}`} title={t('tooltip.delete')}>
              <Button type="link" size="small">
                <AiOutlineDelete onClick={() => handleDelete(record)} />
              </Button>
            </Tooltip>
          </Space>
        )
      },
    },
  ]

  return (
    <section>
      <PageHeader title={t('menu.customers')}>
        <Link to={generatePath(routes.customerDetails.url, { id: 'new' })}>
          <Button type="primary" className="btn-icon" icon={<AiOutlinePlus />}>
            {t('btn.new')}
          </Button>
        </Link>
      </PageHeader>
      <Card>
        <FilterPanel filter={filter} onFilter={onFilter} />
        <Table
          total={customers.totalItems}
          columns={columns}
          isLoading={dataLoading}
          dataSource={customers.result}
          setPage={(page) => setFilters((filters) => ({ ...filters, page }))}
        />
      </Card>
    </section>
  )
}

export default CustomersTableComponent
