import React from 'react'
import routes from 'config/routes'
import { useTranslation } from 'react-i18next'
import PageHeader from 'components/PageHeader'
import { Button, Card, Col, Divider, Row, Typography } from 'antd'
import VariableCard from '../VariableCard'

import { Tooltip, CartesianGrid, ComposedChart, ResponsiveContainer, XAxis, YAxis, Line } from 'recharts'
import colors from 'config/colors'
import { useParams } from 'react-router-dom'
import { useLoadVariableLogs } from 'services/query/iotDevices'
import Loading from 'components/Loading'
import { LATEST_ACTIVITY_DAYS } from '../IotData'
import { config } from 'core'

import classNames from 'classnames'
import { useTheme } from 'providers/ThemeProvider'
import { themes } from './themes'
import styles from './styles.module.scss'

import moment from 'moment'
import { ReloadOutlined } from '@ant-design/icons'

const VariableData: React.FC = () => {
  const { t } = useTranslation()
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)

  const { deviceId, id } = useParams()
  const {
    data: { variable, device, logs },
    refetch,
    isRefetching,
    isFetched,
  } = useLoadVariableLogs(parseInt(deviceId), id)

  const componentClassname = classNames(styles['variable-data'], themeStyles['theme'])

  if (variable === null) {
    return <Loading />
  }

  return (
    <section className={componentClassname}>
      <PageHeader backRoute={routes.iotData} title={`${device.supplier} ${device.model}`}>
        <Button type="primary" icon={<ReloadOutlined />} loading={isRefetching} onClick={() => refetch()}>
          {t('btn.refresh')}
        </Button>
      </PageHeader>

      <Row className={classNames({ blinked: !isRefetching && isFetched })}>
        <Col xs={24} xl={12} xxl={8}>
          <VariableCard data={variable} />
        </Col>
      </Row>

      <Card>
        <Typography.Title level={4}>
          {t('iot.latestActivity', { number: LATEST_ACTIVITY_DAYS })}
        </Typography.Title>
        <Divider className="mt-0" />

        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart
            width={500}
            height={400}
            data={logs}
            margin={{
              top: 20,
              right: 80,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey="logTimestamp"
              tickFormatter={(value) => moment(value).format(config.getDateFormat())}
              label={{ position: 'insideBottom', offset: -15 }}
            />
            <YAxis label={{ angle: -90, position: 'insideLeft' }} />
            <Tooltip labelFormatter={(label) => moment(label).format(config.getDateTimeFormat())} />

            <Line
              type="monotone"
              name={t('iot.values')}
              dataKey="value"
              stroke={colors.piecesPlannedWeek}
              dot={false}
              activeDot={{ stroke: colors.piecesPlannedWeek, strokeWidth: 3, r: 7 }}
              strokeWidth={3}
            />
            {/* <Bar name={t('iot.values')} dataKey="value" fill={colors.piecesCompletedWeek} /> */}
          </ComposedChart>
        </ResponsiveContainer>
      </Card>
    </section>
  )
}

export default VariableData
