import React, { useState } from 'react'
import * as Store from 'types/store'
import { useRouter } from 'core/useRouter'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLoadActivityTypeList } from 'services/query/admin'
import { useEditProductionCard, useLoadProductionCard } from 'services/query/productionCards'

import Loading from 'components/Loading'
import ImageUpload from 'components/productionCards/ProductionCardCreateEdit/ImageUpload'
import ProductionCardCreateEdit from 'components/productionCards/ProductionCardCreateEdit'
import ProductionCardDetailsComponent from 'components/productionCards/ProductionCardDetails'

import { notification } from 'antd'

const ProductionCardDetails: React.FC = () => {
  const { t } = useTranslation()
  const update = useEditProductionCard()
  const { data: activityTypes } = useLoadActivityTypeList()

  const { id } = useParams()
  const router = useRouter()
  const cardId = parseInt(id)
  const [editMode] = useState(router.query.editmode ? true : false)
  const { data: card } = useLoadProductionCard(parseInt(id))
  const [isPosted, setUploadFinishData] = useState(false)

  const [spin, setSpin] = useState(false)

  const onUpdate = async (model: Store.ProductionCard) => {
    model.id = parseInt(id)
    try {
      setSpin(true)
      await update(model)
      setUploadFinishData(true)
      notification.success({ message: t('messagesCrud.productCard') })
    } catch (error) {
      console.error(error)
      setSpin(false)
      notification.error({ message: t('messagesCrud.error') })
    }
  }

  if (card === null) {
    return <Loading />
  }

  if (editMode) {
    return (
      <ProductionCardCreateEdit spin={spin} card={card} onUpdate={onUpdate} activityTypes={activityTypes}>
        <ImageUpload cardId={cardId} isPosted={isPosted} setSpin={setSpin} />
      </ProductionCardCreateEdit>
    )
  }

  return <ProductionCardDetailsComponent card={card} />
}

export default ProductionCardDetails
