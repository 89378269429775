import React, { useState } from 'react'
import routes from 'config/routes'
import { Worker } from 'types/api/admin'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PageHeader from 'components/PageHeader'

import { Card, Row, Col, Button, Input, Form, Spin, Switch } from 'antd'

interface Props {
  spin: boolean
  onCreate: (newWorker: Worker) => void
}

const WorkerNew: React.FC<Props> = ({ onCreate, spin }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    name: null,
    surname: null,
    disabled: false,
  })

  // const crumbs = () => [{ route: routes.home }, { route: routes.workers }]

  return (
    <section>
      <PageHeader backRoute={routes.workers} title={t('worker.newWorker')}>
        {' '}
      </PageHeader>
      <Card>
        {spin ? (
          <Row align="middle" justify="center" style={{ height: '200px' }}>
            <Spin size="large" />
          </Row>
        ) : (
          <Form onFinish={() => onCreate(formData)} initialValues={formData}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label={t('general.name')}
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <Input
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="surname"
                  label={t('general.surname')}
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <Input
                    value={formData.surname}
                    onChange={(e) => setFormData({ ...formData, surname: e.target.value })}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="disabled"
                  label={t('general.status')}
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <Switch
                    checked={!formData.disabled}
                    onChange={(checked) => setFormData({ ...formData, disabled: !checked })}
                    checkedChildren={t('general.enabled')}
                    unCheckedChildren={t('general.disabled')}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className="mt-30">
              <Col xs={24} lg={{ span: 12, offset: 12 }}>
                <Row justify="end" gutter={[12, 12]}>
                  <Col xs={12} lg={8}>
                    <Button block onClick={() => navigate(routes.workers.url)}>
                      {t('btn.cancel')}
                    </Button>
                  </Col>
                  <Col xs={12} lg={8}>
                    <Button block type="primary" htmlType="submit">
                      {t('btn.save')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Card>
    </section>
  )
}

export default WorkerNew
