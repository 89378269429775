import React from 'react'
import moment from 'moment'
import { config } from 'core'
import routes from 'config/routes'
import * as Store from 'types/store'
import { OrderStatus } from 'types/enums'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import PageHeader from 'components/PageHeader'
import { Card, Descriptions, Tag, Button } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

interface Props {
  order: Store.Order
  onDelete?: (order: Store.Order) => void
}

const OrderDetails: React.FC<Props> = ({ order, onDelete }) => {
  const { t } = useTranslation()

  const getOrderStatus = (status: OrderStatus) => {
    switch (status) {
      case OrderStatus.New:
        return <Tag color="warning">{t('status.new')}</Tag>
      case OrderStatus.InProgress:
        return <Tag color="processing">{t('status.inprogress')}</Tag>
      case OrderStatus.Completed:
        return <Tag color="success">{t('status.completed')}</Tag>
      case OrderStatus.Delivered:
        return <Tag color="magenta">{t('status.delivered')}</Tag>
      case OrderStatus.Archived:
      default:
        return <Tag color="default">{t('status.archived')}</Tag>
    }
  }

  return (
    <>
      <PageHeader
        backRoute={routes.orders}
        subtitle={order.customer}
        title={`${t('menu.order')} #${order.id}`}
      >
        <Button href={generatePath(routes.order.url, { id: order.id.toString() }) + '?editmode=true'}>
          <EditOutlined />
          {t('btn.edit')}
        </Button>
        <Button type="text" onClick={() => onDelete(order)}>
          <DeleteOutlined />
          {t('btn.deleteOrder')}
        </Button>
      </PageHeader>
      <Card>
        <Descriptions column={1} colon={false} title={t('general.basicInfo')} className="descriptions-half">
          <Descriptions.Item label={t('general.numPieces')}>{order.numPieces}</Descriptions.Item>
          <Descriptions.Item label={t('general.orderNumber')}>{order.orderNumber}</Descriptions.Item>
          <Descriptions.Item label={t('general.status')}>{getOrderStatus(order.status)}</Descriptions.Item>
          <Descriptions.Item label={t('general.dueDate')}>
            {moment(order.dueDate).format(config.getDateFormat())}
          </Descriptions.Item>
          <Descriptions.Item label={t('general.orderItems')}>
            {order.cards.map((card) => (
              <Tag key={card.id}>{`${card.id} - ${card.article}`}</Tag>
            ))}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  )
}

export default OrderDetails
