import React from 'react'
import { useTranslation } from 'react-i18next'
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts'
import { Card, Divider, Space, Typography } from 'antd'
import colors from 'config/colors'
import { useLoadTodayDashboardData } from 'services/query/dashboard'

const PiecesToday: React.FC = () => {
  const { t } = useTranslation()

  const { data: dailyData } = useLoadTodayDashboardData()

  const grapghColors = [colors.piecesCompletedToday, colors.piecesPlannedToday]
  const { piecesCompleted, piecesToBeDone, piecesPlanned } = dailyData || {
    piecesCompleted: 0,
    piecesToBeDone: 0,
    piecesPlanned: 0,
  }
  const piecesTotal = piecesToBeDone === 0 ? piecesCompleted : piecesPlanned

  const getData = () => [
    { name: t('dashboard.piecesCompleted'), value: piecesCompleted },
    { name: t('dashboard.piecesPlanned'), value: piecesToBeDone },
  ]

  const getPercentage = (amount, total) => {
    const result = (amount * 100) / (total || 1)

    return `${result.toFixed(2)}%`
  }

  return (
    <Card className="widget-item widget-item--today">
      <Typography.Title level={4}>{t('dashboard.piecesToday')}</Typography.Title>
      <Divider className="mt-0" />

      <ResponsiveContainer width="100%" height={300}>
        <PieChart width={300} height={300}>
          <Tooltip itemStyle={{ color: 'inherit' }} />

          <text x="50%" y="50%" dy={-10} textAnchor="middle" fontSize={14}>
            {t('general.numPieces')}
          </text>
          <text x="50%" y="50%" dy={16} textAnchor="middle" fontSize={24}>
            {piecesTotal}
          </text>

          <Pie
            cx="50%"
            cy="50%"
            dataKey="value"
            innerRadius={100}
            outerRadius={130}
            paddingAngle={3}
            data={getData()}
          >
            {getData().map((entry, index) => (
              <Cell key={`cell-${index}`} fill={grapghColors[index]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>

      <div className="legend">
        <Space direction="horizontal">
          <Typography.Title className="legend-item legend-item--completed" level={5}>
            {t('dashboard.piecesCompleted')}
          </Typography.Title>
          <div className="row">
            <span className="value">{piecesCompleted}</span>
            <span className="percentage">{getPercentage(piecesCompleted, piecesTotal)}</span>
          </div>
        </Space>

        <Space direction="horizontal">
          <Typography.Title className="legend-item legend-item--planned" level={5}>
            {t('dashboard.piecesPlanned')}
          </Typography.Title>
          <div className="row">
            <span className="value">{piecesToBeDone}</span>
            <span className="percentage">{getPercentage(piecesToBeDone, piecesTotal)}</span>
          </div>
        </Space>
      </div>
    </Card>
  )
}

export default PiecesToday
