import { createSelector } from 'reselect'
import * as Store from 'types/store'

const user = (state: { root: { user } }) => state.root.user as Store.UserState

// Data Selectors
export const selectLoginState = createSelector(user, (slice) => slice.login)
export const selectLoadingState = createSelector(user, (slice) => slice.state)
export const selectUser = createSelector(user, (slice) => slice.user)
export const selectErrorCode = createSelector(user, (slice) => slice.error)
export const selectUserSettings = createSelector(user, (slice) => slice.settings)
export const selectLanguage = createSelector(selectUserSettings, (slice) => slice.selectedLanguage)
