import React, { useEffect, useState } from 'react'
import debounce from 'debounce'
import routes from 'config/routes'
import { Machine } from 'types/store'
import { getMachineTypeIcon, tableSorter } from 'core/helpers'
import { useTranslation } from 'react-i18next'
import { MachineActivityType } from 'types/enums'
import { useLoadActivityTypeList } from 'services/query/admin'
import { generatePath, Link, useSearchParams } from 'react-router-dom'
import { useDeleteMachine, useLoadMachineList } from 'services/query/machines'

// import TopPage from 'components/TopPage'
import Table from 'components/Layout/Table'
import FilterPanel from 'components/FilterPanel'
import LoadingComponent from 'components/Loading'

import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus, AiOutlineEye } from 'react-icons/ai'
import { Button, Card, Modal, notification, Space, Tabs, Tooltip, Typography } from 'antd'
import PageHeader from 'components/PageHeader'

const MachinesTable: React.FC = () => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const [filters, setFilters] = useState({
    filter: searchParams.get('filter') || '',
    page: searchParams.get('page') || '1',
    type: searchParams.get('type') || '',
  })
  const { filter, page, type } = filters

  const { data, isLoading, isFetched } = useLoadMachineList(filter, parseInt(page), type)
  const {
    data: activityTypes,
    isLoading: isLoadingActivityTypes,
    isFetched: isFetchedActivityTypes,
  } = useLoadActivityTypeList()
  const dataLoading: boolean = isLoading || !isFetched
  const activityTypesLoading: boolean = isLoadingActivityTypes || !isFetchedActivityTypes
  const deleteMachine = useDeleteMachine()

  // const crumbs = () => [{ route: routes.home }, { route: routes.machines }]

  const onFilter = (value: string) => {
    debounce(setFilters({ ...filters, filter: value, page: '1' }), 300)
  }

  const onDelete = async (machine: Machine) => {
    try {
      await deleteMachine(machine)
      notification.success({ message: t('messagesCrud.machineDeleted') })
    } catch (error) {
      notification.error({ message: t('messagesCrud.deleteError') })
    }
  }

  useEffect(() => {
    const query = Object.entries({ filter, page, type }).reduce((object, [key, value]) => {
      if (value) {
        object[key] = value
      }
      return object
    }, {})

    setSearchParams(query)
  }, [filter, page, type])

  const handleDelete = (machine: Machine) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      onOk() {
        onDelete(machine)
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  const columns: any = [
    {
      key: 'nickname',
      title: t('machines.nickname'),
      dataIndex: 'nickname',
      sorter: (a, b) => tableSorter(a.nickname, b.nickname),
    },
    {
      key: 'number',
      title: t('machines.number'),
      dataIndex: 'number',
      sorter: (a, b) => tableSorter(a.number, b.number),
    },
    {
      key: 'ip',
      title: t('machines.ip'),
      dataIndex: 'ip',
      sorter: (a, b) => tableSorter(a.ip, b.ip),
    },
    {
      key: 'model',
      title: t('machines.model'),
      dataIndex: 'model',
      sorter: (a, b) => tableSorter(a.model, b.model),
    },
    {
      key: 'year',
      title: t('machines.year'),
      dataIndex: 'year',
      align: 'center',
      sorter: (a, b) => tableSorter(a.year, b.year),
    },
  ]

  const columnsAll: any = [
    {
      key: 'nickname',
      title: t('machines.nickname'),
      dataIndex: 'nickname',
      sorter: (a, b) => tableSorter(a.nickname, b.nickname),
    },
    {
      key: 'number',
      title: t('machines.number'),
      dataIndex: 'number',
      sorter: (a, b) => tableSorter(a.number, b.number),
    },
    {
      key: 'ip',
      title: t('machines.ip'),
      dataIndex: 'ip',
      sorter: (a, b) => tableSorter(a.ip, b.ip),
    },
    {
      key: 'model',
      title: t('machines.model'),
      dataIndex: 'model',
      sorter: (a, b) => tableSorter(a.model, b.model),
    },
    {
      key: 'type',
      title: t('machines.type'),
      dataIndex: 'typeId',
      align: 'center',
      sorter: (a, b) => tableSorter(a.typeId, b.typeId),
      // eslint-disable-next-line react/display-name
      render: (typeId) =>
        t(`machines.activityTypes.${activityTypes.find((type) => type.id === typeId).type.toLowerCase()}`),
    },

    {
      key: 'year',
      title: t('machines.year'),
      dataIndex: 'year',
      align: 'center',
      sorter: (a, b) => tableSorter(a.year, b.year),
    },
  ]

  const secondColumns: any = [
    {
      key: 'nickname',
      title: t('machines.nickname'),
      dataIndex: 'nickname',
      sorter: (a, b) => tableSorter(a.nickname, b.nickname),
    },
    {
      key: 'number',
      title: t('machines.number'),
      dataIndex: 'number',
      sorter: (a, b) => tableSorter(a.number, b.number),
    },
  ]

  const actionColumn = {
    key: 'actions',
    title: t('general.actions'),
    dataIndex: 'id',
    width: 150,
    className: 'cell-actions',
    render: function actions(id, record) {
      return (
        <Space size={0}>
          <Tooltip key={`action-details-${id}`} title={t('tooltip.details')}>
            <Link to={generatePath(routes.machine.url, { id: id })}>
              <Button type="link" size="small">
                <AiOutlineEye />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip key={`action-edit-${id}`} title={t('tooltip.edit')}>
            <Link to={`${generatePath(routes.machine.url, { id: id })}?editmode=true`}>
              <Button type="link" size="small">
                <AiOutlineEdit />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip key={`action-delete-${id}`} title={t('tooltip.delete')}>
            <Button type="link" size="small">
              <AiOutlineDelete onClick={() => handleDelete(record)} />
            </Button>
          </Tooltip>
        </Space>
      )
    },
  }

  columns.push(actionColumn)
  secondColumns.push(actionColumn)
  columnsAll.push(actionColumn)

  const getCorrectColumns = () => {
    if (!type) {
      return columnsAll
    } else if (
      parseInt(type) === MachineActivityType.Grinding ||
      parseInt(type) === MachineActivityType.Packaging
    ) {
      return secondColumns
    }

    return columns
  }

  return (
    <>
      <PageHeader title={`${t('menu.machines')}`}>
        {/* <TopPage title={t('menu.machines')} crumbs={crumbs()}> */}
        <Button type="primary" className="my-10">
          <Link key="action-create" to={generatePath(routes.machine.url, { id: 'new' })}>
            <AiOutlinePlus /> {t('btn.new')}
          </Link>
        </Button>
      </PageHeader>

      {/* </TopPage> */}
      <Card>
        {activityTypesLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <FilterPanel filter={filter} onFilter={onFilter} />
            <Tabs
              defaultActiveKey="all"
              onChange={(type) => debounce(setFilters({ ...filters, page: '1', type }), 300)}
            >
              <Tabs.TabPane tab={t('general.all')} key="" />
              {activityTypes.map((activity) => (
                <Tabs.TabPane
                  tab={
                    <>
                      <img width={20} height={20} src={getMachineTypeIcon(activity.id)} />
                      {t(`machines.activityTypes.${activity.type.toLowerCase()}`)}
                    </>
                  }
                  key={activity.id}
                />
              ))}
            </Tabs>

            <Table
              setPage={(page) => debounce(setFilters({ ...filters, page }), 300)}
              isLoading={dataLoading}
              dataSource={data.result}
              columns={getCorrectColumns()}
              total={data.totalItems}
            />
          </>
        )}
      </Card>
    </>
  )
}

export default MachinesTable
