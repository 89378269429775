import { Machine } from 'types/store'
import * as queryKeys from 'config/queryKeys'
import { mapMachinesList, mapMachine } from 'services/map/machines'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { createMachine, deleteMachine, listMachines, updateMachine, getMachine } from 'api/machines'

export function useLoadMachineList(filter: string, page: number, type: string) {
  const queryFunction = async () => {
    const response = await listMachines(filter, page, type)
    return mapMachinesList(response)
  }

  return useQuery(queryKeys.machinesListFilter(filter, page, type), queryFunction, {
    placeholderData: {
      currentPage: 1,
      result: [],
      totalItems: 0,
      totalPages: 0,
    },
  })
}

export function useLoadMachine(machineId: number) {
  const queryFunction = async () => {
    const response = await getMachine(machineId)
    return mapMachine(response)
  }

  return useQuery(queryKeys.machine(machineId), queryFunction, {
    placeholderData: null,
  })
}

export function useCreateMachine() {
  const mutateFunction = async (machine: Machine) => {
    const response = await createMachine(machine)
    machine.id = response.id
    return machine as Machine
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (machine: Machine) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.machineDetail(machine.id)),
        queryClient.invalidateQueries(queryKeys.machinesList()),
      ])
    },
  })
  return mutateAsync
}

export function useEditMachine() {
  const mutateFunction = async (Machine: Machine) => {
    await updateMachine(Machine)
    return Machine
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (machine) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.machine(machine.id)),
        queryClient.invalidateQueries(queryKeys.machinesList()),
      ])
    },
  })
  return mutateAsync
}

export function useDeleteMachine() {
  const mutateFunction = async (machine: Machine) => {
    await deleteMachine(machine.id)
    return machine
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (translation) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.machine(translation.id)),
        queryClient.invalidateQueries(queryKeys.machinesList()),
      ])
    },
  })
  return mutateAsync
}
