import { useMutation } from 'react-query'
import * as api from 'api/auth'
import * as Api from 'types/api/auth'

export function useConfirmEmail() {
  const mutateFunction = async (data: Api.ConfirmEmailResetPasswordRequest) => {
    await api.confirmEmail(data)
    return true
  }

  const { mutateAsync, isLoading, error } = useMutation(mutateFunction)

  return { confirmEmail: mutateAsync, isLoading, error }
}

export function useForgotPassword() {
  const mutateFunction = async (email: string): Promise<boolean> => {
    await api.forgotPassword(email)
    return true
  }

  const { mutateAsync, isLoading, error } = useMutation(mutateFunction)

  return { forgotPassword: mutateAsync, isLoading, error }
}

export function useResetPassword() {
  const mutateFunction = async (data: Api.ConfirmEmailResetPasswordRequest): Promise<boolean> => {
    await api.resetPassword(data)
    return true
  }

  const { mutateAsync, isLoading, error } = useMutation(mutateFunction)

  return { resetPassword: mutateAsync, isLoading, error }
}
