import * as Api from 'types/api'
import * as Store from 'types/store'
import * as queryKeys from 'config/queryKeys'

import { useMutation, useQuery, useQueryClient } from 'react-query'
import * as api from 'api/worklog'
import { mapWorklog, mapReportList } from 'services/map/reporting'

export function useCreateWorkLogByWorker() {
  const mutateFunction = async ({ formData, forced }: { formData: Api.WorklogPost; forced: boolean }) => {
    const response = await api.createWorklogtByWorker(formData, forced)

    return mapWorklog(response)
  }
  const queryClient = useQueryClient()
  return useMutation(mutateFunction, {
    onSuccess: (worklog: Store.Worklog) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.worklogDetail(worklog.id)),
        queryClient.invalidateQueries(queryKeys.worklogsList()),
      ])
    },
  })
}

export function useLoadWorklogList(
  card: string,
  page: string,
  worker: string,
  machine: string,
  dateStart: string,
  dateEnd: string,
) {
  const queryFunction = async () => {
    const response = await api.getWorklogList(card, page, worker, machine, dateStart, dateEnd)
    return mapReportList(response)
  }

  return useQuery(
    queryKeys.worklogListFilter(card, page, worker, machine, dateStart, dateEnd),
    queryFunction,
    {
      placeholderData: {
        currentPage: 1,
        result: [],
        totalItems: 0,
        totalPages: 0,
      },
    },
  )
}

export function useDeleteWorklog() {
  const mutateFunction = async (id: number) => {
    await api.deleteWorklog(id)
    return id
  }

  const queryClient = useQueryClient()
  return useMutation(mutateFunction, {
    onSuccess: (id) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.worklogDetail(id)),
        queryClient.invalidateQueries(queryKeys.worklogsList()),
      ])
    },
  })
}

export function useCreateWorklogByAdmin() {
  const mutateFunction = async (formData: Api.WorklogPost) => {
    const response = await api.createWorklogByAdmin(formData)

    return mapWorklog(response)
  }
  const queryClient = useQueryClient()
  return useMutation(mutateFunction, {
    onSuccess: (report: Store.Worklog) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.worklogDetail(report.id)),
        queryClient.invalidateQueries(queryKeys.worklogsList()),
      ])
    },
  })
}

export function useEditWorklog(id: number) {
  const mutateFunction = async (formData: Api.WorklogPost) => {
    await api.updateWorklog(formData, id)
  }
  const queryClient = useQueryClient()

  return useMutation(mutateFunction, {
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.worklogDetail(id)),
        queryClient.invalidateQueries(queryKeys.worklogsList()),
      ])
    },
  })
}

export function useLoadWorklog(id: number) {
  const queryFunction = async () => {
    const response = await api.getWorklog(id)
    return mapWorklog(response)
  }

  return useQuery(queryKeys.worklogDetail(id), queryFunction, {
    placeholderData: null,
  })
}
