import React from 'react'

import { Spin, Row, Col } from 'antd'

const Loading: React.FC = () => {
  return (
    <Row align="middle" justify="center" className="my-100" style={{ flexGrow: 1 }}>
      <Col xs={24} className="align-center">
        <Spin size="large" />
      </Col>
    </Row>
  )
}

export default Loading
