import React from 'react'
import routes from 'config/routes'
import { UserRole } from 'types/store'
import { User } from 'types/app/admin'
import { Operation } from 'types/enums'
import { useTranslation } from 'react-i18next'
import PageHeader from 'components/PageHeader'
import ChangeAccountDetails from './ChangeUserDetails'
import UserChangePasswordContainer from 'containers/users/UserChangePassword'

import { Button, Card } from 'antd'
import style from './styles.module.scss'
import { DeleteOutlined } from '@ant-design/icons'

interface Props {
  operationType: Operation
  user: User
  roles: UserRole[]
  onUserDetailsSaved: (userValues: Json) => void
  loading: boolean
  onDelete?: (userId: number) => void
}

const UserEdit: React.FC<Props> = ({ operationType, user, onUserDetailsSaved, roles, loading, onDelete }) => {
  const { t } = useTranslation()
  // const onAccountDetailsSubmitted = (values: Json) => {
  //   onUserDetailsSaved(values)
  // }

  return (
    <section className={style['user-edit']}>
      <PageHeader backRoute={routes.users} title={user ? `${t('menu.user')} #${user.id}` : t('user.newUser')}>
        {user && (
          <Button type="text" onClick={() => onDelete(user.id)}>
            <DeleteOutlined /> {t('btn.deleteUser')}
          </Button>
        )}
      </PageHeader>

      <Card className="mt-5">
        <ChangeAccountDetails
          onFinish={onUserDetailsSaved}
          user={user}
          roles={roles}
          loading={loading}
          operationType={operationType}
        />
      </Card>
      {operationType === Operation.Update && <UserChangePasswordContainer />}
    </section>
  )
}

UserEdit.displayName = 'UserEdit'

export default UserEdit
