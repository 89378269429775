import * as queryKeys from 'config/queryKeys'
import { useQuery } from 'react-query'
import { getDeviceVariables, getIotDevices, getVariableLogs } from 'api/iotDevices'
import { mapDeviceVariable, mapIotDevice, mapLogsReponse } from 'services/map/iotDevices'
import { isValidId } from 'core/helpers'

export function useLoadIotDevices() {
  const queryFunction = async () => {
    const response = await getIotDevices()
    return response.map(mapIotDevice)
  }

  return useQuery(queryKeys.iotDeviceList(), queryFunction, {
    placeholderData: [],
  })
}

export function useLoadDeviceVariables(deviceId: number | null) {
  const queryFunction = async () => {
    const response = await getDeviceVariables(deviceId)
    return response.map(mapDeviceVariable)
  }

  return useQuery(queryKeys.iotDeviceVariableList(deviceId), queryFunction, {
    placeholderData: null,
    enabled: deviceId !== null && isValidId(deviceId),
  })
}

export function useLoadVariableLogs(deviceId: number, variableId: string) {
  const queryFunction = async () => {
    const response = await getVariableLogs(deviceId, variableId)
    return mapLogsReponse(response)
  }

  return useQuery(queryKeys.iotVariable(deviceId, variableId), queryFunction, {
    placeholderData: { variable: null, device: null, logs: [] },
  })
}
