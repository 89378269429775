import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'

import { BsArrowLeft } from 'react-icons/bs'
import { PageHeader, Typography, Button } from 'antd'
import classNames from 'classnames'

import styles from './styles.module.scss'

interface Props {
  title?: string
  label?: any
  subtitle?: any
  backRoute?: Route
  classname?: string
}
const PageHeaderComponent: React.FC<Props> = ({
  children,
  title = null,
  subtitle = null,
  backRoute = null,
  classname = '',
}) => {
  const { t } = useTranslation()

  return (
    <section className={styles['page-header']}>
      {backRoute && (
        <Link to={generatePath(backRoute.url)}>
          <Button size="large" type="text" className="p-0 mb-40 btn-icon" icon={<BsArrowLeft size={20} />}>
            {t(backRoute.menu)}
          </Button>
        </Link>
      )}
      <PageHeader
        title={
          <Typography.Title level={2} className="m-0">
            {title}
          </Typography.Title>
        }
        subTitle={
          <Typography.Title level={3} className="normal m-0">
            {subtitle}
          </Typography.Title>
        }
        backIcon={null}
        extra={children}
        className={classNames(classname)}
      />
    </section>
  )
}

export default PageHeaderComponent
