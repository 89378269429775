import React from 'react'

import LanguagesTable from 'components/languages'
import { useDeleteLanguage, useEditLanguage, useLoadLanguageList } from 'services/query/admin'
import { useTranslation } from 'react-i18next'
import { Language } from 'types/app/admin'
import { notification } from 'antd'

const LanguagesTableContainer: React.FC = () => {
  const { t } = useTranslation()
  const { data: languages, refetch, isFetched, isLoading } = useLoadLanguageList()
  const dataLoading: boolean = isLoading || !isFetched

  const deleteLanguage = useDeleteLanguage()
  const editLanguage = useEditLanguage()

  const onSaveLanguage = async (model: Language) => {
    try {
      await editLanguage(model)
      notification.success({ message: t('messagesCrud.languageUpdated') })
    } catch (error) {
      console.error(error)
      notification.error({ message: t('messagesCrud.saveError') })
    }
  }

  const onDeleteLanguage = async (language: Language) => {
    try {
      await deleteLanguage(language)
      notification.success({ message: t('messagesCrud.languageDeleted') })
    } catch (error) {
      console.error(error)
      notification.error({ message: t('messagesCrud.deleteError') })
    }
  }

  return (
    <LanguagesTable
      languages={languages}
      dataLoading={dataLoading}
      onDeleteLanguage={onDeleteLanguage}
      refetch={refetch}
      onSaveLanguage={onSaveLanguage}
    />
  )
}

LanguagesTableContainer.displayName = 'LanguagesTableContainer'

export default LanguagesTableContainer
