import React from 'react'
import { Table, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLoadActivityTypesReportsData } from 'services/query/reports'
import styles from '../styles.module.scss'
import ActivityTypeBarchart from 'components/reports/ReportMachines/ByActivity/ActivityTypeBarchart'

interface Props {
  dateStart: string
  dateEnd: string
}

const ReportByActivityType: React.FC<Props> = ({ dateStart, dateEnd }) => {
  const { t } = useTranslation()

  const columns: any = [
    {
      key: 'date',
      title: t('general.date'),
      dataIndex: 'date',
    },
    {
      key: 'piecesCompleted',
      title: t('dashboard.piecesCompleted'),
      dataIndex: 'piecesCompleted',
      align: 'center',
    },
    {
      key: 'piecesPlanned',
      title: t('dashboard.piecesPlanned'),
      dataIndex: 'piecesPlanned',
      align: 'center',
    },
  ]

  const { data: activityTypes, isFetching } = useLoadActivityTypesReportsData(dateStart, dateEnd)

  if (isFetching) {
    return null
  }

  return (
    <section className={styles['report-pdf']}>
      {activityTypes.map((block) => {
        return (
          <section className="block" key={block.activityTypeId}>
            <div className="block-header">
              <Typography.Title
                className={`with-type-icon with-type-icon--${block.activityType.toLowerCase()}`}
                level={4}
              >
                {block.activityType}
              </Typography.Title>
              <Typography.Text>{block.total}</Typography.Text>
            </div>
            <ActivityTypeBarchart data={block} />

            <Table dataSource={block.data} columns={columns} pagination={false} />
          </section>
        )
      })}
    </section>
  )
}

export default ReportByActivityType
