import {
  createLanguage,
  deleteLanguage,
  editLanguage,
  listLanguages,
  listTranslations,
  deleteTranslation,
  createTranslation,
  editTranslation,
  listActivityTypes,
  listActivitySubTypes,
} from 'api/admin'
import * as queryKeys from 'config/queryKeys'

import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  mapLanguageList,
  mapTranslationList,
  mapActivityTypeList,
  mapActivitySubTypeList,
} from 'services/map/admin'
import { Language, Label } from 'types/app/admin'

// languages
export function useLoadLanguageList() {
  const queryFunction = async () => {
    const response = await listLanguages()
    return mapLanguageList(response)
  }
  return useQuery(queryKeys.languageList(), queryFunction, {
    placeholderData: [],
  })
}

export function useCreateLanguage() {
  const mutateFunction = async (language: Language) => {
    await createLanguage({ name: language.name, iso: language.iso })
    return language as Language
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (language) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.languageDetails(language.id)),
        queryClient.invalidateQueries(queryKeys.languageList()),
      ])
    },
  })
  return mutateAsync
}

export function useEditLanguage() {
  const mutateFunction = async (language: Language) => {
    await editLanguage(language.id, { name: language.name, iso: language.iso })
    return language as Language
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (language) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.languageDetails(language.id)),
        queryClient.invalidateQueries(queryKeys.languageList()),
      ])
    },
  })
  return mutateAsync
}

export function useDeleteLanguage() {
  const mutateFunction = async (language: Language) => {
    await deleteLanguage(language.id)
    return language as Language
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (language) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.languageDetails(language.id)),
        queryClient.invalidateQueries(queryKeys.languageList()),
      ])
    },
  })
  return mutateAsync
}

// translations

export function useLoadTranslationsList(filter: string, page: number) {
  const queryFunction = async () => {
    const response = await listTranslations(filter, page)

    return mapTranslationList(response)
  }

  return useQuery(queryKeys.translationListFilter(filter, page), queryFunction, {
    placeholderData: { currentPage: 1, labels: [], totalItems: 0, totalPages: 0 },
  })
}

export function useCreateTranslation() {
  const mutateFunction = async (translation: Label) => {
    await createTranslation({
      labelTag: translation.labelTag,
      translations: translation.translations,
    })
    return translation as Label
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (translation) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.translationDetailsNew(translation.labelTag)),
        queryClient.invalidateQueries(queryKeys.translationList()),
      ])
    },
  })
  return mutateAsync
}

export function useEditTranslation() {
  const mutateFunction = async (translation: Label) => {
    await editTranslation({
      id: translation.id,
      labelTag: translation.labelTag,
      translations: translation.translations,
    })
    return translation as Label
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (translation) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.translationDetails(translation.id)),
        queryClient.invalidateQueries(queryKeys.translationList()),
      ])
    },
  })
  return mutateAsync
}

export function useDeleteTranslation() {
  const mutateFunction = async (translation: Label) => {
    await deleteTranslation(translation.id)
    return translation as Label
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (translation) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.translationDetails(translation.id)),
        queryClient.invalidateQueries(queryKeys.translationList()),
      ])
    },
  })
  return mutateAsync
}

//activity types
export function useLoadActivityTypeList() {
  const queryFunction = async () => {
    const response = await listActivityTypes()
    return mapActivityTypeList(response)
  }
  return useQuery(queryKeys.activityTypeList(), queryFunction, {
    placeholderData: [],
  })
}

export function useLoadActivitySubTypeList() {
  const queryFunction = async () => {
    const response = await listActivitySubTypes()
    return mapActivitySubTypeList(response)
  }
  return useQuery(queryKeys.activitySubTypeList(), queryFunction, {
    placeholderData: [],
  })
}
