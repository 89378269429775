import React, { useEffect, useState } from 'react'
import Planning from 'components/Planning'
import { LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'
import Title from 'antd/lib/typography/Title'
import PlanningCreate from './PlanningCreate'
import * as Store from 'types/store'
import PlanningEdit from './PlanningEdit'
import { t } from 'i18next'
import { config } from 'core'
import { generateDatePeriodTitle } from 'core/helpers'

export interface ModalOptions {
  visible: boolean
  machineId?: number
  event?: Store.PlanningEvent
  startDate?: moment.Moment
  endDate?: moment.Moment
}

const PlanningContainer: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const date = searchParams.get('startDay')
  const [startDay, setStartDay] = useState(
    (date ? moment.utc(date, config.getDateFormat()) : moment().utc().startOf('day')).weekday(0),
  )

  const defaultModalOptions: ModalOptions = {
    visible: false,
  }

  const [modalOptions, setModalOptions] = useState<ModalOptions>(defaultModalOptions)

  useEffect(() => {
    if (startDay) {
      const query = { startDay: startDay.format(config.getDateFormat()) }

      setSearchParams(query)
    }
  }, [startDay])

  const onEmptyClick = (date: moment.Moment, machineId: number) => {
    const startDate = date.clone().local().set({ h: 6, m: 0, s: 0 })
    const endDate = date.clone().local().set({ h: 18, m: 0, s: 0 })
    setModalOptions({
      visible: true,
      startDate,
      machineId,
      endDate,
    })
  }

  const onEventClick = (event: Store.PlanningEvent) => {
    setModalOptions({ visible: true, event })
  }

  const onClose = () => setModalOptions(defaultModalOptions)

  const header = (
    <Row className="calendar-header mb-16" align="middle" justify="space-between" gutter={[0, 16]}>
      <Col className="title-col">
        <Title ellipsis={{ tooltip: true }} className="m-0" level={2} style={{ textTransform: 'capitalize' }}>
          {generateDatePeriodTitle(startDay, startDay.clone().weekday(7))}
        </Title>

        <div className="back-fwd-buttons">
          <Button type="default" onClick={() => setStartDay(startDay.clone().subtract(1, 'week'))}>
            <LeftOutlined />
          </Button>
          <Button type="default" onClick={() => setStartDay(startDay.clone().add(1, 'week'))}>
            <RightOutlined />
          </Button>
        </div>
      </Col>
      <Col className="action-col">
        <Button type="primary" onClick={() => setModalOptions({ ...defaultModalOptions, visible: true })}>
          <PlusOutlined />
          {t('planning.newPlan')}
        </Button>
      </Col>
    </Row>
  )

  return (
    <>
      {header}
      <PlanningCreate onClose={onClose} modalOptions={modalOptions} />
      <PlanningEdit onClose={onClose} modalOptions={modalOptions} />
      <Planning onEmptyClick={onEmptyClick} onEventClick={onEventClick} startDay={startDay} />
    </>
  )
}

export default PlanningContainer
