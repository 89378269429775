import React, { useState } from 'react'
import routes from 'config/routes'
import { generatePath, useNavigate } from 'react-router-dom'
import { useCreateTranslation, useLoadLanguageList } from 'services/query/admin'

import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { Label } from 'types/app/admin'
import TranslationDetailsNew from 'components/translations/translationDetails/TranslationDetailsNew'

const TranslationDetailsContainer: React.FC = () => {
  const { t } = useTranslation()
  const { data: languages } = useLoadLanguageList()
  const navigate = useNavigate()
  const createTranslation = useCreateTranslation()

  const [spin, setSpin] = useState(false)

  const onCreateTranslation = async (model: Label) => {
    try {
      setSpin(true)
      await createTranslation(model)
      setSpin(false)
      navigate(generatePath(routes.translations.url), { replace: true })
      notification.success({ message: t('messagesCrud.translationCreated') })
    } catch (error) {
      console.error(error)
      setSpin(false)
      notification.error({ message: t('messagesCrud.existingTag') })
    }
  }

  return <TranslationDetailsNew onCreateTranslation={onCreateTranslation} languages={languages} spin={spin} />
}

export default TranslationDetailsContainer
