import React from 'react'

import routes from 'config/routes'
import * as Store from 'types/store'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import PageHeader from 'components/PageHeader'
import Loading from 'components/Loading'
import { DeleteOutlined } from '@ant-design/icons'
import { Card, Row, Col, Button, Input, Form, Typography, Divider } from 'antd'
import { checkSubmitButtonBeDisabled } from 'core/helpers'

interface Props {
  spin?: boolean
  customer: Store.Customer
  onCreate?: (customer: Store.Customer) => void
  onUpdate?: (Customer: Store.Customer) => void
  onDelete?: (Customer: Store.Customer) => void
}

const CustomerCreateEdit: React.FC<Props> = ({ customer, spin, onCreate, onUpdate, onDelete }) => {
  const { t } = useTranslation()

  const [form] = Form.useForm()

  const onFinish = (model) => {
    customer ? onUpdate(model) : onCreate(model)
  }

  const requiredFields = ['businessName', 'vatNr', 'email', 'phone', 'address', 'website']

  return (
    <>
      <PageHeader backRoute={routes.customers} title={customer ? customer.businessName : t('customer.new')}>
        {customer && (
          <Button type="text" onClick={() => onDelete(customer)}>
            <DeleteOutlined /> {t('btn.deleteCustomer')}
          </Button>
        )}
      </PageHeader>
      <Card>
        {spin ? (
          <Loading />
        ) : (
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{
              ...customer,
            }}
          >
            <>
              <Typography.Title level={4}>{t('general.basicInfo')}</Typography.Title>
              <Divider className="mt-0" />
              <Row key={customer?.id}>
                <Col span={24}>
                  <Form.Item
                    label={t('customer.businessName')}
                    name="businessName"
                    rules={[
                      { min: 3, message: t('validationForm.nameCharacters') },
                      { required: true, message: t('validationForm.fieldRequired') },
                    ]}
                  >
                    <Input type="text" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={t('customer.vatNr')}
                    name="vatNr"
                    rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                  >
                    <Input type="text" />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={t('user.email')}
                    name="email"
                    rules={[
                      { type: 'email', message: t('validationForm.emailNotValid') },
                      { required: true, message: t('validationForm.fieldRequired') },
                    ]}
                  >
                    <Input type="text" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={t('general.phone')}
                    name="phone"
                    rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                  >
                    <Input type="text" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={t('general.address')}
                    name="address"
                    rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                  >
                    <Input type="text" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={t('general.website')}
                    name="website"
                    rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                  >
                    <Input type="text" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item shouldUpdate className="btn mt-15 align-right">
                {() => (
                  <Col xs={24} lg={{ span: 12, offset: 12 }} className="mt-48">
                    <Row justify="end" gutter={[12, 12]}>
                      <Col xs={12} lg={8}>
                        <Button block>
                          <Link to={routes.customers.url}>{t('btn.cancel')}</Link>
                        </Button>
                      </Col>
                      <Col xs={12} lg={8}>
                        <Button
                          block
                          type="primary"
                          htmlType="submit"
                          loading={spin}
                          disabled={checkSubmitButtonBeDisabled(form, requiredFields)}
                        >
                          {t('btn.save')}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Form.Item>
            </>
          </Form>
        )}
      </Card>
    </>
  )
}

export default CustomerCreateEdit
