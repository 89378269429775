import React, { useEffect, useState } from 'react'
import Debug from 'debug'
import debounce from 'debounce'
import routes from 'config/routes'
import { Label } from 'types/app/admin'
import { useTranslation } from 'react-i18next'
import EditTable from 'components/Layout/EditTable'
import PageHeader from 'components/PageHeader'
import FilterPanel from 'components/FilterPanel'

import { generatePath, Link, useSearchParams } from 'react-router-dom'
import { useLoadLanguageList, useLoadTranslationsList, useEditTranslation } from 'services/query/admin'

import { AiOutlinePlus } from 'react-icons/ai'
import { Button, Card, notification } from 'antd'
import style from './styles.module.scss'

interface Props {
  onDeleteTranslation: (translation) => void
}

const TranslationsTable: React.FC<Props> = ({ onDeleteTranslation }) => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const [filters, setFilters] = useState({
    filter: searchParams.get('filter') || '',
    page: searchParams.get('page') || '1',
  })
  const { filter, page } = filters

  const { data: languages } = useLoadLanguageList()
  const { data, isFetched, isLoading } = useLoadTranslationsList(filter, parseInt(page))
  const dataLoading: boolean = isLoading || !isFetched

  const editTranslation = useEditTranslation()

  const onSaveTranslation = async (model: Label) => {
    try {
      await editTranslation(model)
      notification.success({ message: t('messagesCrud.translationUpdated') })
    } catch (error) {
      Debug(`${'Saved Failed:'} ${error}`)
      notification.error({ message: t('messagesCrud.existingTag') })
    }
  }

  // const crumbs = () => [{ route: routes.home }, { route: routes.translations }]

  const onFilter = (value: string) => {
    debounce(setFilters({ ...filters, filter: value, page: '1' }), 300)
  }

  useEffect(() => {
    const query = Object.entries({ filter, page }).reduce((object, [key, value]) => {
      if (value) {
        object[key] = value
      }
      return object
    }, {})

    setSearchParams(query)
  }, [filter, page])

  return (
    <section className={style['transactions-table']}>
      <PageHeader title={`${t('menu.translations')}`}>
        <Button type="primary" className="my-10">
          <Link
            key="action-create"
            to={generatePath(routes.translationDetails.url, { id: 'new' }) + '?editmode=details'}
          >
            <AiOutlinePlus />
            {t('btn.new')}
          </Link>
        </Button>
      </PageHeader>
      <Card>
        <FilterPanel filter={filter} onFilter={onFilter} />
        <EditTable
          onSaveTranslation={onSaveTranslation}
          onDeleteTranslation={onDeleteTranslation}
          languages={languages}
          dataSource={data}
          dataLoading={dataLoading}
          setPage={(page) => debounce(setFilters({ ...filters, page }), 300)}
        />
      </Card>
    </section>
  )
}

export default TranslationsTable
