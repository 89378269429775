import { Modal, notification, Spin } from 'antd'
import PlanningForm from 'components/Planning/PlanningForm'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDeletePlanningEvent, useEditPlanningEvent } from 'services/query/planning'
import * as Api from 'types/api'
import { ModalOptions } from '../Planning'

interface Props {
  onClose: () => void
  modalOptions: ModalOptions
}

const PlanningEdit: React.FC<Props> = ({ modalOptions, onClose }) => {
  const { visible, event } = modalOptions
  const [editPlanningEvent, isLoading] = useEditPlanningEvent(event?.id)
  const [deletePlanningEvent, isDeleting] = useDeletePlanningEvent()
  const { t } = useTranslation()

  const onFinish = async (data: Api.PlanningForm) => {
    try {
      await editPlanningEvent(data)
      notification.success({ message: t('messagesCrud.eventUpdated') })
      onClose()
    } catch {
      notification.error({ message: t('messagesCrud.errorUpdated') })
    }
  }

  const onDelete = async () => {
    try {
      await deletePlanningEvent(event?.id)
      notification.success({ message: t('messagesCrud.eventCreated') })
      onClose()
    } catch {
      notification.error({ message: t('messagesCrud.errorCreate') })
    }
  }

  return (
    <Modal
      visible={visible && event != null}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      className="planning-modal"
      title={t('planning.editTitle')}
    >
      <Spin spinning={isLoading || isDeleting}>
        <PlanningForm
          modalOptions={modalOptions}
          onFinish={onFinish}
          onDelete={onDelete}
          initialValues={{
            cardId: event?.cardId,
            customerId: event?.customerId,
            endDate: event?.endDate,
            machineId: event?.machineId,
            numPieces: event?.numPieces,
            startDate: event?.startDate,
          }}
        />
      </Spin>
    </Modal>
  )
}

export default PlanningEdit
