import { Worker } from 'types/app/admin'
import * as queryKeys from 'config/queryKeys'
import { Workers } from 'types/store'
import { listWorkers, createWorker, editWorker, deleteWorker, getWorker } from 'api/workers'
import { WorkerCreateEditRequest } from 'types/api/admin'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import { mapWorkerList, mapCreateEditFromWorker, mapWorker } from 'services/map/admin'

export function useLoadWorkerList(filter: string, page: string, status: string) {
  const queryFunction = async () => {
    const response = await listWorkers(filter, page, status)
    return mapWorkerList(response) as Workers
  }
  return useQuery(queryKeys.workerListFilter(filter, page, status), queryFunction, {
    placeholderData: { currentPage: 1, result: [], totalItems: 0, totalPages: 0 },
  })
}

export function useCreateWorker() {
  const mutateFunction = async (worker: WorkerCreateEditRequest) => {
    await createWorker(worker)
    return worker as Worker
  }
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useMutation(mutateFunction, {
    onSuccess: (worker) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.workerDetails(worker.id)),
        queryClient.invalidateQueries(queryKeys.workerList()),
      ])
    },
  })
  return { createWorker: mutateAsync, isLoading }
}

export function useEditWorker() {
  const mutateFunction = async (worker: Worker) => {
    const mappedData: WorkerCreateEditRequest = mapCreateEditFromWorker(worker)
    await editWorker(worker.id, mappedData)
    return worker as Worker
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (worker) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.workerDetails(worker.id)),
        queryClient.invalidateQueries(queryKeys.workerList()),
      ])
    },
  })
  return mutateAsync
}

export function useDeleteWorker() {
  const deleteFunction = async (workerId: number) => {
    const result = await deleteWorker(workerId)

    return result
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(deleteFunction, {
    onSuccess: () => {
      return queryClient.invalidateQueries(queryKeys.workerList())
    },
  })
  return mutateAsync
}

export function useGetWorker(id: number) {
  const queryFunction = async () => {
    const result = await getWorker(id)

    return mapWorker(result)
  }
  return useQuery(queryKeys.workerDetails(id), queryFunction, {
    placeholderData: null,
  })
}
