export enum RequestStatus {
  None = 0,
  Requesting = 1,
  Success = 2,
  Error = 3,
}

export enum ThemeType {
  Light = 'light',
  Dark = 'dark',
}

export enum ErrorCode {
  Unknown = 0,
  Api = 1,
  Parameters = 3,
  PageUndefined = 4,
  GraphQlApi = 11,
  BootstrapFailed = 101,
  Login = 102,
  Register = 103,
  RegisterCodeNotFound = 104,
  RegisterInvalidPassword = 105,
  RegisterEmailTaken = 106,
  UserAlreadyRegistered = 107,
  AuthenticationFailed = 108,
  GenericError = 999,
}

export enum UserOperation {
  Login = 'login',
  ForgotPassword = 'forgotPassword',
  ResetPassword = 'resetPassword',
}

export enum LoadingState {
  Unset = 'unset',
  Loading = 'loading',
  Loaded = 'loaded',
  LoadFailure = 'load failure',
  Deleting = 'deleting',
  Deleted = 'deleted',
  DeleteFailed = 'deleting failed',
  Creating = 'creating',
  Created = 'created',
  CreateFailure = 'created failure',
  Updating = 'updating',
  Updated = 'updated',
  UpdateFailure = 'update failure',
}

export enum Operation {
  Create = 'CREATE',
  Read = 'READ',
  Update = 'UPDATE',
  Delete = 'DELETE',
}

export enum MachineActivityType {
  Cutting = 1,
  SandBlasting = 2,
  Grinding = 3,
  Packaging = 4,
}

export enum OrderStatus {
  New = 'New',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Delivered = 'Delivered',
  Archived = 'Archived',
}

export enum UserRoles {
  Admin = 'Admin',
  Supervisor = 'Supervisor',
  Worker = 'Worker',
}
export enum MachineShifts {
  OneDailyShift = 'OneDailyShift',
  OneShiftPerDay = 'OneShiftPerDay',
  TwoShiftsPerDay = 'TwoShiftsPerDay',
  ThreeShiftsPerDay = 'ThreeShiftsPerDay',
}

export enum PlanningShifts {
  ShiftOne = 1,
  ShiftTwo = 2,
}

export enum WorkerShifts {
  ShiftOne = 'ShiftOne',
  ShiftTwo = 'ShiftTwo',
}

export enum ReportGroups {
  ByMachine = 'by-machine',
  ByActivityType = 'by-activity-type',
  ByArticle = 'by-article',
}

export enum VariableValueType {
  Numeric = 'Numeric',
  String = 'String',
  Boolean = 'Boolean',
  Empty = 'Empty',
}
