import { useTheme } from 'providers/ThemeProvider'
import React from 'react'
import { ThemeType } from 'types/enums'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { themes } from './themes'

const ThemeSwitcher: React.FC = () => {
  const { theme, setTheme, getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const switcherClass = classNames(styles['theme-switcher'], themeStyles['theme'])

  return (
    <div
      className={switcherClass}
      onClick={() => (theme === ThemeType.Light ? setTheme(ThemeType.Dark) : setTheme(ThemeType.Light))}
    >
      <div className={theme === ThemeType.Light ? 'sun' : 'moon'} />
    </div>
  )
}

ThemeSwitcher.displayName = 'ThemeSwitcher'

export default ThemeSwitcher
