export enum GraphQlErrors {
  AccessDenied = 'AccessDeniedError',
}

export enum ResponseCode {
  BadRequest = 'BAD_REQUEST',
  Success = 'SUCCESS',
  Unauthorized = 'UNAUTHORIZED',
  GenericError = 'GENERIC_ERROR',
  StrategyDoesNotExist = 'STRATEGY_NOT_EXIST',
  StrategyAlreadyExist = 'STRATEGY_ALREADY_EXIST',
  PortfolioDoesNotExist = 'PORTFOLIO_NOT_EXIST',
  PortfolioAlreadyExist = 'PORTFOLIO_ALREADY_EXIST',
  PasswordContainsName = 'PASSWORD_CONTAINS_NAME',
  PasswordContainsSurname = 'PASSWORD_CONTAINS_SURNAME',
  PasswordContainsEmail = 'PASSWORD_CONTAINS_EMAIL',
  AssetDoesNotExist = 'ASSET_NOT_EXIST',
  AssetAlreadyExist = 'ASSET_ALREADY_EXIST',
  SessionDoesNotExist = 'SESSION_NOT_EXIST',
  SessionAlreadyExist = 'SESSION_ALREADY_EXIST',
  UploadError = 'UPLOAD_ERROR',
  UploadPartialError = 'UPLOAD_PARTIAL_ERROR',
  UserNotExist = 'USER_NOT_EXIST',
  InvalidGrant = 'INVALID_GRANT',
}
