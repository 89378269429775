import * as Api from 'types/api'
import { picklists } from 'services/query/picklist'
import { PicklistMachine } from 'types/store/picklist'
import { MachineActivityType } from 'types/enums'

const identity = <T>(input: T): T => input

type PicklistMapFunction<T = unknown> = (input: unknown) => T

const picklistMapping: { [key in picklists]: PicklistMapFunction } = {
  machines: ({ id, machineShifts, name, typeId }: Api.PicklistMachine): PicklistMachine => ({
    id,
    machineShifts,
    name,
    typeId: typeId as MachineActivityType,
  }),

  // TODO: add mapping for other picklist entities
  customers: identity,
  orders: identity,
  workers: identity,
  languages: identity,
  cards: identity,
  iotdevices: identity,
}

export const mapPicklist = <T>(entity: picklists, response: unknown[]) =>
  response.map<T>(picklistMapping[entity] as PicklistMapFunction<T>)
