import React, { useState } from 'react'
import routes from 'config/routes'
import * as Store from 'types/store'
import { useRouter } from 'core/useRouter'
import { useTranslation } from 'react-i18next'
import { useLoadOrder, useEditOrder, useDeleteOrder } from 'services/query/orders'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import OrderDetails from 'components/orders/OrderDetails'
import OrderCreateEdit from 'components/orders/OrderCreateEdit'

import Loading from 'components/Loading'
import { Modal, notification, Typography } from 'antd'

const OrderDetailsContainer: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const update = useEditOrder()

  const { id } = useParams()
  const router = useRouter()
  const [editMode] = useState(router.query.editmode ? true : false)
  const { data: order } = useLoadOrder(parseInt(id))

  const [spin, setSpin] = useState(false)
  const onUpdate = async (model: Store.Order) => {
    model.id = parseInt(id)
    try {
      setSpin(true)
      await update(model)
      navigate(generatePath(routes.orders.url), { replace: true })
      notification.success({ message: t('messagesCrud.orderUpdated') })
    } catch (error) {
      console.error(error)
      notification.error({ message: t('messagesCrud.error') })
    } finally {
      setSpin(false)
    }
  }

  const deleteOrder = useDeleteOrder()
  const onDelete = (order: Store.Order) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      async onOk() {
        try {
          await deleteOrder(order)
          navigate(generatePath(routes.orders.url), { replace: true })
          notification.success({ message: t('messagesCrud.orderDeleted') })
        } catch (error) {
          notification.error({ message: t('messagesCrud.deleteError') })
        }
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  if (order === null) {
    return <Loading />
  }

  if (editMode) {
    return <OrderCreateEdit spin={spin} order={order} onUpdate={onUpdate} onDelete={onDelete} />
  }
  return <OrderDetails order={order} onDelete={onDelete} />
}

export default OrderDetailsContainer
