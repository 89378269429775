import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLoadActivityTypeList } from 'services/query/admin'
import ProductionCardCreateEdit from 'components/productionCards/ProductionCardCreateEdit'
import * as Store from 'types/store'

import { notification } from 'antd'
import { useCreateProductionCard } from 'services/query/productionCards'
import ImageUpload from 'components/productionCards/ProductionCardCreateEdit/ImageUpload'

const ProductionCardCreateContainer: React.FC = () => {
  const { t } = useTranslation()
  const createProductCard = useCreateProductionCard()
  const { data: activityTypes } = useLoadActivityTypeList()
  const [{ cardId, isPosted }, setUploadFinishData] = useState({ cardId: null, isPosted: false })

  const [spin, setSpin] = useState(false)

  const onCreate = async (model: Store.ProductionCard) => {
    try {
      setSpin(true)
      const { id } = await createProductCard(model)
      setUploadFinishData({ cardId: id, isPosted: true })
      notification.success({ message: t('messagesCrud.productCardCreated') })
    } catch (error) {
      console.error(error)
      setSpin(false)
      notification.error({ message: t('messagesCrud.error') })
    }
  }

  return (
    <ProductionCardCreateEdit spin={spin} card={null} onCreate={onCreate} activityTypes={activityTypes}>
      <ImageUpload cardId={cardId} isPosted={isPosted} setSpin={setSpin} />
    </ProductionCardCreateEdit>
  )
}

export default ProductionCardCreateContainer
