import * as Api from 'types/api'
import * as Store from 'types/store'
import * as queryKeys from 'config/queryKeys'

import { UseMutateAsyncFunction, useMutation, useQuery, useQueryClient } from 'react-query'

import {
  createShiftPlanning,
  deleteShiftPlanning,
  editShiftPlanning,
  getShiftPlanningCSV,
  getShiftPlanningPDF,
  listShiftPlanning,
} from 'api/shiftPlanning'
import { mapShiftPlanning, mapShiftPlanningList } from 'services/map/shiftPlanning'

export function useCreateShiftPlanning(): [
  UseMutateAsyncFunction<Store.ShiftPlanning, unknown, Api.ShiftPlanningCreateForm, unknown>,
  boolean,
] {
  const mutateFunction = async (formData: Api.ShiftPlanningCreateForm) => {
    const response = await createShiftPlanning(formData)

    return mapShiftPlanning(response)
  }
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useMutation(mutateFunction, {
    onSuccess: (shiftPlanning: Store.ShiftPlanning) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.shiftPlanningDetail(shiftPlanning.id)),
        queryClient.invalidateQueries(queryKeys.shiftPlanningList()),
      ])
    },
  })

  return [mutateAsync, isLoading]
}

export function useEditShiftPlanning(
  id: number,
): [UseMutateAsyncFunction<Store.ShiftPlanning, unknown, Api.ShiftPlanningUpdateForm, unknown>, boolean] {
  const mutateFunction = async (formData: Api.ShiftPlanningUpdateForm) => {
    const response = await editShiftPlanning(id, formData)

    return mapShiftPlanning(response)
  }
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useMutation(mutateFunction, {
    onSuccess: (shiftPlanning: Store.ShiftPlanning) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.shiftPlanningDetail(shiftPlanning.id)),
        queryClient.invalidateQueries(queryKeys.shiftPlanningList()),
      ])
    },
  })

  return [mutateAsync, isLoading]
}

export function useDeleteShiftPlanning(): [
  UseMutateAsyncFunction<number, unknown, number, unknown>,
  boolean,
] {
  const mutateFunction = async (id: number) => {
    await deleteShiftPlanning(id)
    return id
  }
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useMutation(mutateFunction, {
    onSuccess: (id: number) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.shiftPlanningDetail(id)),
        queryClient.invalidateQueries(queryKeys.shiftPlanningList()),
      ])
    },
  })

  return [mutateAsync, isLoading]
}

export function useListShiftPlanning(startDate: string) {
  const queryFunction = async () => {
    const response = await listShiftPlanning(startDate)
    return mapShiftPlanningList(response)
  }

  return useQuery(queryKeys.shiftPlanningListFilter(startDate), queryFunction, {
    placeholderData: [],
  })
}

export function useGetShiftPlanningCSV(startDate: string) {
  const mutateFunction = async () => {
    return await getShiftPlanningCSV(startDate)
  }
  return useMutation(mutateFunction)
}

export function useGetShiftPlanningPDF(startDate: string) {
  const mutateFunction = async () => {
    return await getShiftPlanningPDF(startDate)
  }
  return useMutation(mutateFunction)
}
