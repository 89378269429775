import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserSettings } from 'store/user/selectors'
import { setUserLanguage } from 'store/user/actions'

import { initLanguage, config } from 'core'
import LanguageSelector from 'components/LanguageSelector'
import { getLanguage, setLanguage } from 'core/localStore'
import { useLoadLanguagesPicklist } from 'services/query/picklist'

const LanguageSelectorContainer: React.FC = () => {
  const dispatch = useDispatch()
  const userSettings = useSelector(selectUserSettings)
  const settedLanguage = getLanguage()
  const { data: languages, isFetching } = useLoadLanguagesPicklist()

  let initialLanguage = settedLanguage
    ? settedLanguage
    : languages.find((l) => l.iso.toUpperCase() === 'IT')?.iso

  useEffect(() => {
    if (userSettings) {
      if (userSettings.selectedLanguage) {
        initialLanguage = userSettings.selectedLanguage
      } else {
        dispatch(setUserLanguage(initialLanguage))
      }
    }
  }, [userSettings, languages])

  const onChanged = (language: string) => {
    const labelSource = process.env.REACT_APP_LABELS_SOURCE
    initLanguage(language ?? 'it', labelSource)
    if (language) {
      dispatch(setUserLanguage(language))
      setLanguage(language)
      config.setLanguage(language)
    }
  }

  if (isFetching) return null

  return <LanguageSelector languages={languages} initialLanguage={initialLanguage} onChange={onChanged} />
}

export default LanguageSelectorContainer
