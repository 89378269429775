import { Button, Modal, Space, Tooltip, Typography } from 'antd'
import Table from 'components/Layout/Table'
import routes from 'config/routes'
import { tableSorter } from 'core/helpers'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from 'react-icons/ai'
import { generatePath, Link } from 'react-router-dom'
import { useDeleteWorklog } from 'services/query/worklog'
import * as Store from 'types/store'

interface Props {
  data: Store.Worklogs
  isLoading: boolean
  setPage: (page: string) => void
}

const WorklogsTable: React.FC<Props> = ({ data, isLoading, setPage }) => {
  const { t } = useTranslation()
  const { mutateAsync: deleteReport, isLoading: isDeleting } = useDeleteWorklog()

  const handleDelete = (id: number) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      onOk() {
        deleteReport(id)
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  const columns = [
    {
      key: 'id',
      title: t('general.id'),
      dataIndex: 'id',
      width: '10%',
      fixed: true,
    },
    {
      key: 'worker',
      title: t('reports.worker'),
      dataIndex: 'worker',
      sorter: (a, b) => tableSorter(a.worker, b.worker),
    },
    {
      key: 'machine',
      title: t('reports.machine'),
      dataIndex: 'machine',
      sorter: (a, b) => tableSorter(a.machine, b.machine),
    },
    {
      key: 'article',
      title: t('general.article'),
      dataIndex: 'article',
      sorter: (a, b) => tableSorter(a.article, b.article),
    },
    {
      key: 'numPieces',
      title: t('general.numPieces'),
      dataIndex: 'numPieces',
      sorter: (a, b) => tableSorter(a.numPieces, b.numPieces),
    },
    {
      key: 'date',
      title: t('general.date'),
      dataIndex: 'date',
      sorter: (a, b) => tableSorter(a.date, b.date),
      render: function renderDate(date: moment.Moment) {
        return date.format('L HH:mm')
      },
    },
    {
      key: 'actions',
      title: t('general.actions'),
      dataIndex: 'id',
      className: 'cell-actions',
      width: 150,
      // eslint-disable-next-line react/display-name
      render: (id) => (
        <Space size={0}>
          <Tooltip key={`action-details-${id}`} title={t('tooltip.details')}>
            <Link to={generatePath(routes.workReport.url, { id: id })}>
              <Button type="link" size="small">
                <AiOutlineEye />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip key={`action-edit-${id}`} title={t('tooltip.edit')}>
            <Link to={generatePath(routes.workReport.url, { id: id }) + '?editmode=details'}>
              <Button type="link" size="small">
                <AiOutlineEdit />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip key={`action-delete-${id}`} title={t('tooltip.delete')}>
            <Button type="link" size="small" onClick={() => handleDelete(id)}>
              <AiOutlineDelete />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ]

  return (
    <Table
      total={data.totalItems}
      columns={columns}
      isLoading={isLoading || isDeleting}
      dataSource={data.result}
      setPage={(page) => setPage(page)}
    />
  )
}

export default WorklogsTable
