const colors: Json = {
  black: '#000000',
  white: '#ffffff',
  piecesCompletedToday: '#FF5252',
  piecesPlannedToday: '#FFB8B8',
  piecesCompletedWeek: '#2EDB4B',
  piecesPlannedWeek: '#FFD43A',
  piecesPlannedMachines: '#FF5252',
  piecesCompletedMachines: '#34aff7',
}

export default colors
