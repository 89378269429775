import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { config } from 'core'
import routes from 'config/routes'
import * as Store from 'types/store'
import { Link } from 'react-router-dom'
import { OrderStatus } from 'types/enums'
import { useTranslation } from 'react-i18next'
import { useLoadCardsOfCustomerPicklist, useLoadCustomersPicklist } from 'services/query/picklist'
import { filterSearchSelect } from 'core/helpers'

import PageHeader from 'components/PageHeader'
import Loading from 'components/Loading'
import { DeleteOutlined } from '@ant-design/icons'
import {
  Card,
  Row,
  Col,
  Button,
  InputNumber,
  Form,
  Select,
  DatePicker,
  Typography,
  Divider,
  Input,
} from 'antd'

interface Props {
  spin?: boolean
  order: Store.Order
  onCreate?: (order: Store.Order) => void
  onUpdate?: (order: Store.Order) => void
  onDelete?: (order: Store.Order) => void
}

const OrderCreateEdit: React.FC<Props> = ({ order, spin, onCreate, onUpdate, onDelete }) => {
  const { t } = useTranslation()

  const [form] = Form.useForm()
  const [customerId, setCustomerId] = useState(order ? order.customerId.toString() : '')
  const { data: customers } = useLoadCustomersPicklist()
  const { data: cards, refetch: refetchCards } = useLoadCardsOfCustomerPicklist(parseInt(customerId))

  const onFinish = (model) => {
    if (model.cards) {
      const cards = model?.cards?.map((card) => {
        return {
          id: card,
        }
      }) as Store.Order[]
      model.cards = cards
    }
    order ? onUpdate(model) : onCreate(model)
  }

  const getDefaultCards = () => {
    const defaultCards = []
    if (order) {
      order.cards.map((card) => defaultCards.push(card.id))
    }
    return defaultCards
  }

  useEffect(() => {
    refetchCards()
  }, [customerId])

  return (
    <>
      <PageHeader
        backRoute={routes.orders}
        subtitle={order ? order.customer : null}
        title={order ? `${t('menu.order')} #${order.id}` : t('order.new')}
      >
        {order && (
          <Button type="text" onClick={() => onDelete(order)}>
            <DeleteOutlined /> {t('btn.deleteOrder')}
          </Button>
        )}
      </PageHeader>
      <Card>
        {spin ? (
          <Loading />
        ) : (
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{
              ...order,
              cards: getDefaultCards(),
              dueDate: order ? moment(order.dueDate) : moment().add('day', 1),
            }}
          >
            <Typography.Title level={4}>{t('general.basicInfo')}</Typography.Title>
            <Divider className="mt-0" />
            <Row>
              <Col xs={24} lg={11}>
                <Form.Item
                  name="numPieces"
                  label={t('general.numPieces')}
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <InputNumber />
                </Form.Item>

                <Form.Item
                  name="orderNumber"
                  label={t('general.orderNumber')}
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="status"
                  label={t('general.status')}
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <Select>
                    {Object.values(OrderStatus).map((status) => {
                      if (status) {
                        return (
                          <Select.Option value={status} key={status}>
                            {t(`status.${status.toLowerCase()}`)}
                          </Select.Option>
                        )
                      }
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="customerId"
                  label={t('general.customer')}
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <Select
                    allowClear
                    onChange={(id) => {
                      setCustomerId(id.toString())
                      form.setFieldsValue({ cards: [] })
                    }}
                    showSearch
                    filterOption={filterSearchSelect}
                  >
                    {customers &&
                      customers.map((customer) => {
                        return (
                          <Select.Option key={customer.id} value={customer.id}>
                            {customer.name}
                          </Select.Option>
                        )
                      })}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="cards"
                  label={t('general.cards')}
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <Select
                    mode="multiple"
                    disabled={customerId === ''}
                    showSearch
                    filterOption={filterSearchSelect}
                  >
                    {cards &&
                      cards.map((card) => {
                        return (
                          <Select.Option value={card.id} key={card.id} label={card.article}>
                            {card.article}
                          </Select.Option>
                        )
                      })}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="dueDate"
                  label={t('general.dueDate')}
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    format={config.getDateFormat()}
                    disabledDate={(current) => current && moment(current).isBefore(moment())}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} lg={{ span: 12, offset: 12 }} className="mt-48">
                <Row justify="end" gutter={[12, 12]}>
                  <Col xs={12} lg={8}>
                    <Button block>
                      <Link to={routes.orders.url}>{t('btn.cancel')}</Link>
                    </Button>
                  </Col>
                  <Col xs={12} lg={8}>
                    <Button block type="primary" htmlType="submit" loading={spin}>
                      {t('btn.save')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Card>
    </>
  )
}

export default OrderCreateEdit
