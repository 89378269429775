import { config } from 'core'
import { AxiosError, AxiosResponse } from 'axios'
import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'
import * as Api from 'types/api'
import * as Store from 'types/store'

const axios = getAxios()

export async function listOrders(
  filter: string,
  page: number,
  customer: string,
  status: string,
  card: string,
): Promise<Api.OrderListResponse> {
  try {
    const response = await axios.get<Api.OrderListResponse>(
      config.api.listOrders(filter, page, customer, status, card),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getOrder(orderId: number): Promise<Api.Order> {
  try {
    const response = await axios.get<Api.Order>(config.api.order(orderId), await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function createOrder(model: Store.Order): Promise<Api.NewOrderResponse> {
  try {
    const response = await axios.post(config.api.createOrder(), model, await getAuthenticationHeader())
    return response.data as Api.NewMachineResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function updateOrder(model: Store.Order): Promise<Api.Order> {
  try {
    await axios.put<Api.Order>(config.api.order(model.id), model, await getAuthenticationHeader())
    return model
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteOrder(id: number): Promise<AxiosResponse> {
  try {
    const response = await axios.delete(config.api.order(id), await getAuthenticationHeader())
    return response as AxiosResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
