import React, { useState, useEffect } from 'react'
import debounce from 'debounce'
import routes from 'config/routes'
import * as Store from 'types/store'
import { filterSearchSelect, getMachineTypeIcon, tableSorter } from 'core/helpers'
import { useTranslation } from 'react-i18next'
import { useLoadCustomersPicklist, useLoadMachinesPicklist } from 'services/query/picklist'
import { generatePath, Link, useSearchParams } from 'react-router-dom'
import { useLoadActivityTypeList, useLoadActivitySubTypeList } from 'services/query/admin'
import { useDeleteProductionCard, useLoadProductionCardList } from 'services/query/productionCards'

import PageHeader from 'components/PageHeader'
import Table from 'components/Layout/Table'
import FilterPanel from 'components/FilterPanel'
import LoadingComponent from 'components/Loading'

import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus, AiOutlineEye } from 'react-icons/ai'
import { Button, Card, Col, Modal, notification, Select, Space, Tabs, Tag, Tooltip, Typography } from 'antd'

const ProductionCardsTable: React.FC = () => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const [filters, setFilters] = useState({
    filter: searchParams.get('filter') || '',
    page: searchParams.get('page') || '1',
    type: searchParams.get('type') || '',
    subtype: searchParams.get('subtype') || '',
    customer: searchParams.get('customer') || '',
    machine: searchParams.get('customer') || '',
  })
  const { filter, page, type, subtype, customer, machine } = filters

  const { data, refetch, isLoading, isFetched } = useLoadProductionCardList(
    filter,
    parseInt(page),
    type.toString(),
    subtype && parseInt(subtype),
    customer && customer,
    machine && parseInt(machine),
  )
  const { data: machines } = useLoadMachinesPicklist()
  const { data: customers } = useLoadCustomersPicklist()
  const { data: activityTypes } = useLoadActivityTypeList()
  const { data: activitySubTypes } = useLoadActivitySubTypeList()

  const dataLoading: boolean = isLoading || !isFetched

  const deleteCard = useDeleteProductionCard()

  const onFilter = (value: string) => {
    debounce(setFilters({ ...filters, filter: value, page: '1' }), 300)
  }

  useEffect(() => {
    const query = Object.entries({ filter, page, type, subtype, customer, machine }).reduce(
      (object, [key, value]) => {
        if (value) {
          object[key] = value
        }
        return object
      },
      {},
    )
    setSearchParams(query)
  }, [filter, page, type, subtype, customer, machine])

  const onDelete = async (card: Store.ProductionCard) => {
    try {
      await deleteCard(card)
      refetch()
      notification.success({ message: t('messagesCrud.machineDeleted') })
    } catch (error) {
      notification.error({ message: t('messagesCrud.deleteError') })
    }
  }

  const handleDelete = (card: Store.ProductionCard) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      onOk() {
        onDelete(card)
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  const columns: any = [
    {
      key: 'id',
      width: 70,
      dataIndex: 'id',
      title: t('general.id'),
    },
    {
      key: 'article',
      title: t('general.article'),
      dataIndex: 'article',
      sorter: (a, b) => tableSorter(a.article, b.article),
    },
  ]

  const columnsAll: any = [
    {
      key: 'id',
      width: 70,
      dataIndex: 'id',
      title: t('general.id'),
    },
    {
      key: 'article',
      title: t('general.article'),
      dataIndex: 'article',
      sorter: (a, b) => tableSorter(a.article, b.article),
    },
    {
      key: 'type',
      title: t('machines.activityType'),
      dataIndex: 'type',
      align: 'center',
      sorter: (a, b) => tableSorter(a.type, b.type),
      render: (type) => t(`machines.activityTypes.${type.toLowerCase()}`),
    },
  ]

  const commonColumns = [
    {
      key: 'subType',
      title: t('general.activitySubtype'),
      dataIndex: 'subType',
      sorter: (a, b) => tableSorter(a.subType, b.subType),
      render: (subType) => t(`machine.activitySubType.${subType.toLowerCase()}`),
    },
    {
      key: 'customer',
      title: t('general.customer'),
      dataIndex: 'customer',
      sorter: (a, b) => tableSorter(a.customer, b.customer),
    },
    {
      key: 'machines',
      title: t('general.machines'),
      dataIndex: 'machines',
      sorter: (a, b) => tableSorter(a.machines, b.machines),
      // eslint-disable-next-line react/display-name
      render: (machines) => {
        return (
          <Space size={0}>
            {machines.map((machine) => (
              <Tooltip key={machine.id} title={machine.nickname}>
                <Tag>{machine.number}</Tag>
              </Tooltip>
            ))}
          </Space>
        )
      },
    },
    {
      key: 'actions',
      dataIndex: 'id',
      width: 100,
      title: t('general.actions'),
      className: 'cell-actions',
      render: function actions(id, record: Store.ProductionCard) {
        return (
          <Space size={0}>
            <Tooltip key={`action-details-${id}`} title={t('tooltip.details')}>
              <Link to={generatePath(routes.manifacturingCard.url, { id })}>
                <Button type="link" size="small">
                  <AiOutlineEye />
                </Button>
              </Link>
            </Tooltip>
            <Tooltip key={`action-edit-${id}`} title={t('tooltip.edit')}>
              <Link to={generatePath(routes.manifacturingCard.url, { id }) + '?editmode=true'}>
                <Button type="link" size="small">
                  <AiOutlineEdit />
                </Button>
              </Link>
            </Tooltip>
            <Tooltip key={`action-delete-${id}`} title={t('tooltip.delete')}>
              <Button type="link" size="small">
                <AiOutlineDelete onClick={() => handleDelete(record)} />
              </Button>
            </Tooltip>
          </Space>
        )
      },
    },
  ]
  commonColumns.map((column) => {
    columns.push(column)
    columnsAll.push(column)
  })

  const getSelectOptions = (item: any) => {
    return (
      <Select.Option key={`${item.subType}-${item.id}`} value={item.id.toString()}>
        {t(`machine.activitySubType.${item.subType.toLowerCase()}`)}
      </Select.Option>
    )
  }

  const getsubTypeOptions = () => {
    const options = []
    activitySubTypes.map((subtype) => {
      if (type && parseInt(type) === subtype.id) {
        activitySubTypes
          .find((f) => f.id === parseInt(type))
          ?.subTypes.map((st) => {
            options.push(getSelectOptions(st))
          })
      } else if (!type) {
        subtype.subTypes.map((st) => {
          options.push(getSelectOptions(st))
        })
      }
    })

    return options
  }

  return (
    <>
      <PageHeader title={t('menu.manifacturingCards')}>
        <Link key="action-create" to={generatePath(routes.manifacturingCard.url, { id: 'new' })}>
          <Button type="primary" className="btn-icon" icon={<AiOutlinePlus />}>
            {t('btn.new')}
          </Button>
        </Link>
      </PageHeader>
      <Card className="production-card">
        {!data || !activityTypes ? (
          <LoadingComponent />
        ) : (
          <>
            <FilterPanel filter={filter} onFilter={onFilter} colSize={6}>
              <Col xs={24} lg={6}>
                <Select
                  allowClear
                  placeholder={t('filter.selectSubType')}
                  value={subtype ? `${subtype}` : null}
                  onClear={() => debounce(setFilters({ ...filters, subtype: '' }), 300)}
                  onSelect={(selected) => {
                    debounce(setFilters({ ...filters, page: '1', subtype: selected.toString() }), 300)
                  }}
                >
                  {getsubTypeOptions()}
                </Select>
              </Col>
              <Col xs={24} lg={6}>
                <Select
                  allowClear
                  placeholder={t('filter.selectCustomer')}
                  onClear={() => debounce(setFilters({ ...filters, customer: '' }), 300)}
                  onSelect={(customer) =>
                    debounce(setFilters({ ...filters, customer: customer.toString() }), 300)
                  }
                  showSearch
                  filterOption={filterSearchSelect}
                >
                  {customers?.map((c) => {
                    return (
                      <Select.Option value={c.id} key={c.id}>
                        {c.name}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Col>
              <Col xs={24} lg={6}>
                <Select
                  allowClear
                  placeholder={t('filter.selectMachine')}
                  onClear={() => debounce(setFilters({ ...filters, machine: '' }), 300)}
                  onSelect={(machine) =>
                    debounce(setFilters({ ...filters, machine: machine.toString() }), 300)
                  }
                  showSearch
                  filterOption={filterSearchSelect}
                >
                  {machines?.map((m) => {
                    return (
                      <Select.Option value={m.id} key={m.id}>
                        {m.name}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Col>
            </FilterPanel>

            <Tabs
              activeKey={`${type}`}
              onChange={(type) => {
                debounce(setFilters({ ...filters, page: '1', type }), 300)
              }}
            >
              <Tabs.TabPane tab={t('general.all')} key="" />
              {activityTypes.map((activity) => (
                <Tabs.TabPane
                  tab={
                    <>
                      <img width={20} height={20} src={getMachineTypeIcon(activity.id)} />
                      {t(`machines.activityTypes.${activity.type.toLowerCase()}`)}
                    </>
                  }
                  key={activity.id}
                />
              ))}
            </Tabs>
            <Table
              columns={type ? columns : columnsAll}
              total={data.totalItems}
              isLoading={dataLoading}
              dataSource={data.result}
              setPage={(page) => {
                debounce(setFilters({ ...filters, page }), 300)
              }}
            />
          </>
        )}
      </Card>
    </>
  )
}

export default ProductionCardsTable
