import React from 'react'
import routes from 'config/routes'
import { Language } from 'types/app/admin'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'

import PageHeader from 'components/PageHeader'
import EditTable from './EditTable/EditTable'

import { AiOutlinePlus } from 'react-icons/ai'
import { Button, Card } from 'antd'
import style from './styles.module.scss'

interface Props {
  languages?: Language[]
  dataLoading: boolean
  onDeleteLanguage: (language) => void
  refetch: () => void
  onSaveLanguage: (language) => void
}

const LanguagesTable: React.FC<Props> = ({
  languages,
  dataLoading,
  onDeleteLanguage,
  refetch,
  onSaveLanguage,
}) => {
  const { t } = useTranslation()

  return (
    <section className={style['languages-table']}>
      <PageHeader title={`${t('menu.languagesTitle')}`}>
        <Button type="primary" className="my-10">
          <Link
            key="action-create"
            to={generatePath(routes.languageDetails.url, { id: 'new' }) + '?editmode=details'}
          >
            <AiOutlinePlus />
            {t('btn.new')}
          </Link>
        </Button>
      </PageHeader>

      <Card className="mt-50">
        <EditTable
          dataSource={languages}
          isLoading={dataLoading}
          onDeleteLanguage={onDeleteLanguage}
          refetch={refetch}
          onSaveLanguage={onSaveLanguage}
        />
      </Card>
    </section>
  )
}

export default LanguagesTable
