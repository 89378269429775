import React, { useState } from 'react'
import routes from 'config/routes'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getCurrentRoute } from 'core/helpers'

import LogoFullWhite from 'assets/logo-full-white.svg'
import LogoFullBlack from 'assets/logo-full-black.svg'
import LogoWhite from 'assets/logo-white.svg'
import LogoBlack from 'assets/logo-black.svg'

import { HiTranslate, HiOutlineDocumentReport } from 'react-icons/hi'
import { MdLanguage } from 'react-icons/md'
import { FaPeopleCarry, FaUsersCog, FaUserFriends } from 'react-icons/fa'
import { GiGearHammer, GiSettingsKnobs } from 'react-icons/gi'
import { AiOutlineDashboard, AiOutlineMenuUnfold, AiOutlineMenuFold, AiOutlineTeam } from 'react-icons/ai'
import { Layout, Menu, Button } from 'antd'

import styles from './styles.module.scss'
import { useTheme } from 'providers/ThemeProvider'
import * as localStore from 'core/localStore'
import { UserRoles } from 'types/enums'

const SideMenu: React.FC = () => {
  const { t } = useTranslation()
  const [collapsed, setCollapsed] = useState(false)
  const [collapsedWidth, setCollapsedWidth] = useState(80)
  const { theme } = useTheme()

  const shouldRender = () => {
    try {
      const user = localStore.getUserClaims()
      if (user.userRole !== UserRoles.Worker) {
        return true
      }
    } catch (error) {
      return false
    }
  }

  if (!shouldRender()) {
    return null
  }

  const getSelectItem = () => {
    const route = getCurrentRoute(location.pathname)
    if (routes.hasOwnProperty(route.key)) {
      return routes[route.key].key
    }
    return routes.home.key
  }

  getSelectItem()

  return (
    <>
      <div className={`sider-overlay ${collapsed ? 'hide' : 'show'}`} />
      <Layout.Sider
        width={250}
        collapsible
        collapsed={collapsed}
        className={styles.sider}
        onBreakpoint={(broken) => {
          if (broken) {
            setCollapsed(true)
            setCollapsedWidth(250)
          } else {
            setCollapsed(false)
            setCollapsedWidth(80)
          }
        }}
        trigger={null}
        breakpoint="md"
        collapsedWidth={collapsedWidth}
        theme={theme}
      >
        <section className="sider-content">
          <div className="sider-header">
            <Button type="link" className="sider-trigger" onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? <AiOutlineMenuUnfold /> : <AiOutlineMenuFold />}
            </Button>
            <Link to={routes.home.url} className="logo">
              {collapsed ? (
                <img alt="logo" src={theme === 'light' ? LogoBlack : LogoWhite} />
              ) : (
                <img alt="logo" src={theme === 'light' ? LogoFullBlack : LogoFullWhite} />
              )}
            </Link>
          </div>
          <Menu mode="inline" defaultSelectedKeys={[getSelectItem()]}>
            {/* submenu Dashboards*/}
            <Menu.SubMenu
              className="m-0"
              key="sub-menu-1"
              icon={<AiOutlineDashboard />}
              title={t('menu.dashboards')}
            >
              <Menu.Item className="m-0" key={routes.home.key}>
                <Link to={routes.home.url}>{t('menu.overview')}</Link>
              </Menu.Item>

              <Menu.Item className="m-0" key={routes.iotData.key}>
                <Link to={routes.iotData.url}>{t('menu.iotData')}</Link>
              </Menu.Item>
            </Menu.SubMenu>

            {/* submenu Clients*/}
            <Menu.SubMenu
              className="m-0"
              key="sub-menu-2"
              icon={<FaPeopleCarry />}
              title={t('menu.customersOrders')}
            >
              <Menu.Item className="m-0" key={routes.customers.key}>
                <Link to={routes.customers.url}>{t('menu.customers')}</Link>
              </Menu.Item>
              <Menu.Item className="m-0" key={routes.manifacturingCards.key}>
                <Link to={routes.manifacturingCards.url}>{t('menu.manifacturingCards')}</Link>
              </Menu.Item>
              <Menu.Item className="m-0" key={routes.orders.key}>
                <Link to={routes.orders.url}>{t('menu.orders')}</Link>
              </Menu.Item>
            </Menu.SubMenu>

            {/* submenu Production*/}
            <Menu.SubMenu
              className="m-0"
              key="sub-menu-3"
              icon={<GiGearHammer />}
              title={t('menu.production')}
            >
              <Menu.Item className="m-0" key={routes.machines.key}>
                <Link to={routes.machines.url}>{t('menu.machines')}</Link>
              </Menu.Item>
              <Menu.Item className="m-0" key={routes.planning.key}>
                <Link to={routes.planning.url}>{t('menu.planning')}</Link>
              </Menu.Item>
              <Menu.Item className="m-0" key={routes.shiftPlanning.key}>
                <Link to={routes.shiftPlanning.url}>{t('menu.shiftPlanning')}</Link>
              </Menu.Item>

              <Menu.Item className="m-0" key={routes.workReports.key}>
                <Link to={routes.workReports.url}>{t('menu.workReport')}</Link>
              </Menu.Item>
            </Menu.SubMenu>

            {/* submenu Wharehouse*/}
            {/* <Menu.SubMenu className="m-0" key="sub-menu-3" icon={<MdStorage />} title={t('menu.wharehouse')}>
              <Menu.Item className="m-0" key={routes.wharehouse.key}>
                <Link to={routes.wharehouse.url}>{t('menu.wharehouse')}</Link>
              </Menu.Item>
            </Menu.SubMenu> */}

            {/* submenu reports*/}
            <Menu.SubMenu
              className="m-0"
              key="sub-menu-4"
              icon={<HiOutlineDocumentReport />}
              title={t('menu.report')}
            >
              <Menu.Item className="m-0" key={routes.reportMachines.key}>
                <Link to={routes.reportMachines.url}>{t('menu.reportByMachines')}</Link>
              </Menu.Item>

              <Menu.Item className="m-0" key={routes.reportArticles.key}>
                <Link to={routes.reportArticles.url}>{t('menu.reportByArticles')}</Link>
              </Menu.Item>
            </Menu.SubMenu>

            {/* submenu users*/}
            <Menu.SubMenu className="m-0" key="sub-menu-5" icon={<AiOutlineTeam />} title={t('menu.users')}>
              <Menu.Item className="m-0" key={routes.users.key} icon={<FaUserFriends />}>
                <Link to={routes.users.url}>{t('menu.users')}</Link>
              </Menu.Item>
              <Menu.Item className="m-0" key={routes.workers.key} icon={<FaUsersCog />}>
                <Link to={routes.workers.url}>{t('menu.workers')}</Link>
              </Menu.Item>
            </Menu.SubMenu>

            {/* submenu settings*/}
            <Menu.SubMenu
              className="m-0"
              key="sub-menu-6"
              icon={<GiSettingsKnobs />}
              title={t('menu.settings')}
            >
              <Menu.Item key={routes.translations.key} icon={<HiTranslate />}>
                <Link to={routes.translations.url}> {t('menu.translations')}</Link>
              </Menu.Item>
              <Menu.Item key={routes.languages.key} icon={<MdLanguage />}>
                <Link to={routes.languages.url}> {t('menu.languagesTitle')}</Link>
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </section>
      </Layout.Sider>
    </>
  )
}

SideMenu.displayName = 'SideMenu'

export default SideMenu
