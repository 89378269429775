import React, { useState } from 'react'
import routes from 'config/routes'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import * as Store from 'types/store'

import { notification } from 'antd'
import { useCreateCustomer } from 'services/query/customers'
import CustomerCreateEdit from 'components/customers/CustomerCreateEdit'

const CustomerCreateContainer: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { createCustomer } = useCreateCustomer()

  const [spin, setSpin] = useState(false)

  const onCreate = async (model: Store.Customer) => {
    try {
      setSpin(true)
      await createCustomer(model)
      setSpin(false)
      navigate(generatePath(routes.customers.url), { replace: true })
      notification.success({ message: t('messagesCrud.customerCreated') })
    } catch (error) {
      console.error(error)
      setSpin(false)
      notification.error({ message: t('messagesCrud.error') })
    }
  }

  return <CustomerCreateEdit spin={spin} customer={null} onCreate={onCreate} />
}

export default CustomerCreateContainer
