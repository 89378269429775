import React from 'react'
import { useParams } from 'react-router-dom'

import TranslationDetailsContainer from 'containers/translations/TranslationDetails'

const LanguageDetails: React.FC = () => {
  const { id } = useParams()
  if (id === 'new') return <TranslationDetailsContainer />
}

LanguageDetails.displayName = 'LanguageDetails'

export default LanguageDetails
