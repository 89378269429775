import React from 'react'
import routes from 'config/routes'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'

import { Card, Descriptions, Button } from 'antd'
import PageHeader from 'components/PageHeader'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { generatePath } from 'react-router-dom'
import { PlanningShifts } from 'types/enums'

interface Props {
  worklog: Store.Worklog
  onDelete: () => void
}

const WorklogDetails: React.FC<Props> = ({ worklog, onDelete }) => {
  const { t } = useTranslation()

  const shiftNameRenderer = (shift: PlanningShifts) => {
    switch (shift) {
      case PlanningShifts.ShiftOne:
        return t('planning.shiftOne')
      case PlanningShifts.ShiftTwo:
        return t('planning.shiftTwo')
      default:
        return null
    }
  }

  return (
    <>
      <PageHeader
        backRoute={routes.workReports}
        title={`${worklog.worker} - ${worklog.date.format('L')}`}
        subtitle={`ID: ${worklog.id}`}
      >
        <Button href={generatePath(routes.workReport.url, { id: worklog.id.toString() }) + '?editmode=true'}>
          <EditOutlined />
          {t('btn.edit')}
        </Button>
        <Button type="text" onClick={() => onDelete()}>
          <DeleteOutlined />
          {t('worklog.delete')}
        </Button>
      </PageHeader>
      {/* <TopPage title={t('menu.machines')} crumbs={crumbs()}></TopPage> */}
      <Card>
        <Descriptions column={1} colon={false} title={t('general.basicInfo')} className="descriptions-half">
          <>
            <Descriptions.Item label={t('reports.worker')}>{worklog.worker}</Descriptions.Item>
            <Descriptions.Item label={t('general.machine')}>{worklog.machine}</Descriptions.Item>
            <Descriptions.Item label={t('general.numPieces')}>{worklog.numPieces}</Descriptions.Item>
            <Descriptions.Item label={t('general.notes')}>{worklog.notes}</Descriptions.Item>
            <Descriptions.Item label={t('general.date')}>{worklog.date.format('L HH:mm')}</Descriptions.Item>
            <Descriptions.Item label={t('general.shift')}>
              {shiftNameRenderer(worklog.shift)}
            </Descriptions.Item>
          </>
        </Descriptions>
      </Card>
    </>
  )
}

export default WorklogDetails
