import * as Store from 'types/store'
import * as queryKeys from 'config/queryKeys'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  createProductionCard,
  deleteProductionCard,
  listProductionCard,
  updateProductionCard,
  getProductionCard,
  uploadProductionCardImage,
  updateProductionCardImages,
  downloadPDF,
} from 'api/productionCards'
import { mapProductionCard, mapProductionCardList } from 'services/map/productionCard'
import { UploadFile } from 'antd/lib/upload/interface'

export function useLoadProductionCardList(
  filter: string,
  page: number,
  type: string,
  subtype: number,
  customer: string,
  machine: number,
) {
  const queryFunction = async () => {
    const response = await listProductionCard(filter, page, type, subtype, customer, machine)
    return mapProductionCardList(response)
  }

  return useQuery(
    queryKeys.productionCardListFilter(filter, page, type, subtype, customer, machine),
    queryFunction,
    {
      placeholderData: {
        currentPage: 1,
        result: [],
        totalItems: 0,
        totalPages: 0,
      },
    },
  )
}

export function useLoadProductionCard(id: number) {
  const queryFunction = async () => {
    const response = await getProductionCard(id)
    return mapProductionCard(response)
  }

  return useQuery(queryKeys.productionCard(id), queryFunction, {
    placeholderData: null,
  })
}

export function useCreateProductionCard() {
  const mutateFunction = async (productionCard: Store.ProductionCard) => {
    const response = await createProductionCard(productionCard)
    productionCard.id = response.id
    return productionCard as Store.ProductionCard
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (productionCard: Store.ProductionCard) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.productionCard(productionCard.id)),
        queryClient.invalidateQueries(queryKeys.productionCardList()),
      ])
    },
  })
  return mutateAsync
}

export function useEditProductionCard() {
  const mutateFunction = async (productionCard: Store.ProductionCard) => {
    await updateProductionCard(productionCard)
    await updateProductionCardImages(
      productionCard.id,
      productionCard.images.map(({ id }) => id),
    )
    return productionCard
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (productionCard) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.productionCard(productionCard.id)),
        queryClient.invalidateQueries(queryKeys.productionCardList()),
      ])
    },
  })
  return mutateAsync
}

export function useUploadProductionCardImages(images: UploadFile[], cardId: number) {
  const mutateFunction = async () => {
    for (const image of images) {
      await uploadProductionCardImage(image, cardId)
    }
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: () => {
      return Promise.all([queryClient.invalidateQueries(queryKeys.productionCard(cardId))])
    },
  })
  return mutateAsync
}

export function useDeleteProductionCard() {
  const mutateFunction = async (machine: Store.ProductionCard) => {
    await deleteProductionCard(machine.id)
    return machine
  }

  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (productionCard) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.productionCard(productionCard.id)),
        queryClient.invalidateQueries(queryKeys.productionCardList()),
      ])
    },
  })

  return mutateAsync
}

export function useGetProductionCardPdf() {
  const mutateFunction = async (id: number) => {
    const blob = await downloadPDF(id)
    return blob
  }

  return useMutation(mutateFunction)
}
