import { config } from 'core'
import { AxiosError, AxiosResponse } from 'axios'
import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'
import * as Api from 'types/api'

const axios = getAxios()

export async function createShiftPlanning(model: Api.ShiftPlanningCreateForm): Promise<Api.ShiftPlanning> {
  try {
    const response = await axios.post<Api.ShiftPlanning>(
      config.api.createShiftPlanning(),
      model,
      await getAuthenticationHeader(),
    )

    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteShiftPlanning(id: number): Promise<AxiosResponse> {
  try {
    const response = await axios.delete(config.api.shiftPlanning(id), await getAuthenticationHeader())
    return response
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function editShiftPlanning(
  id: number,
  model: Api.ShiftPlanningUpdateForm,
): Promise<Api.ShiftPlanning> {
  try {
    const response = await axios.put<Api.ShiftPlanning>(
      config.api.shiftPlanning(id),
      model,
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function listShiftPlanning(startDate: string): Promise<Api.ShiftPlanning[]> {
  try {
    const response = await axios.get<Api.ShiftPlanning[]>(
      config.api.listShiftPlanning(startDate),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getShiftPlanningCSV(startDate: string) {
  try {
    const header = await getAuthenticationHeader()
    header.responseType = 'blob'
    return await axios.get<Blob>(config.api.getShiftPlanningCSV(startDate), header)
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getShiftPlanningPDF(startDate: string) {
  try {
    const header = await getAuthenticationHeader()
    header.responseType = 'blob'
    return await axios.get<Blob>(config.api.getShiftPlanningPDF(startDate), header)
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
