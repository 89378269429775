import React, { useEffect, useState } from 'react'
import { DownloadOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'
import Title from 'antd/lib/typography/Title'
import * as Store from 'types/store'
import ShiftPlanning from 'components/ShiftPlanning'
import ShiftPlanningEdit from './ShiftPlanningEdit'
import ShiftPlanningCreate from './ShiftPlanningCreate'
import { config } from 'core'
import { useTranslation } from 'react-i18next'
import { useGetShiftPlanningPDF } from 'services/query/shiftPlanning'
import FileDownload from 'js-file-download'

const WEEKS_PER_TABLE = 6

export interface ModalOptions {
  visible: boolean
  workerId?: number
  startDate?: moment.Moment
  shiftPlanning?: Store.ShiftPlanning
}

const ShiftPlanningContainer: React.FC = () => {
  const defaultModalOptions: ModalOptions = {
    visible: false,
  }
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const date = searchParams.get('startDay')

  const [modalOptions, setModalOptions] = useState<ModalOptions>(defaultModalOptions)
  const [startDay, setStartDay] = useState(date ? moment(date, config.getDateFormat()) : moment())

  const { mutateAsync: downloadPdf, isLoading: isPDFLoading } = useGetShiftPlanningPDF(startDay.toISOString())

  useEffect(() => {
    if (startDay) {
      const query = { startDay: startDay.format(config.getDateFormat()) }

      setSearchParams(query)
    }
  }, [startDay])

  const onEmptyClick = (date: moment.Moment, workerId: number) => {
    console.log('Empty')
    setModalOptions({
      visible: true,
      startDate: date,
      workerId,
    })
  }

  const onDownloadPDFClick = async () => {
    const response = await downloadPdf()
    if (response?.data) {
      const startWeek = startDay
      const endWeek = startDay.clone().add(6, 'weeks')

      FileDownload(response.data, config.getShiftPlanningPDFName(startWeek, endWeek))
    }
  }

  const onShiftPlanningClick = (shiftPlanning: Store.ShiftPlanning) => {
    setModalOptions({ visible: true, shiftPlanning })
  }

  const onClose = () => setModalOptions(defaultModalOptions)

  const header = (
    <Row className="calendar-header mb-16" align="middle" justify="space-between" gutter={[0, 16]}>
      <Col className="title-col">
        <Title className="m-0" level={2}>
          {t('menu.shiftPlanning')}
        </Title>

        <div className="back-fwd-buttons">
          <Button
            type="default"
            onClick={() => setStartDay(startDay.clone().subtract(WEEKS_PER_TABLE, 'weeks'))}
          >
            <LeftOutlined />
          </Button>

          <Button type="default" onClick={() => setStartDay(startDay.clone().add(WEEKS_PER_TABLE, 'weeks'))}>
            <RightOutlined />
          </Button>
        </div>
      </Col>

      <Col className="action-col">
        <Button
          type="primary"
          className="btn-icon"
          icon={<DownloadOutlined />}
          loading={isPDFLoading}
          onClick={() => onDownloadPDFClick()}
        >
          {t('btn.download')}
        </Button>
      </Col>
    </Row>
  )

  return (
    <>
      {header}
      <ShiftPlanningCreate onClose={onClose} modalOptions={modalOptions} />
      <ShiftPlanningEdit onClose={onClose} modalOptions={modalOptions} />
      <ShiftPlanning
        onEmptyClick={onEmptyClick}
        onShiftPlanningClick={onShiftPlanningClick}
        startDay={startDay}
      />
    </>
  )
}

export default ShiftPlanningContainer
