export const STATE_KEY = 'user'

export const LOGIN = `${STATE_KEY}/LOGIN`
export const LOGOUT = `${STATE_KEY}/LOGOUT`
export const SET_STATUS = `${STATE_KEY}/SET_STATUS`
export const FETCH_USER = `${STATE_KEY}/FETCH_USER`
export const CHANGE_PASSWORD = `${STATE_KEY}/CHANGE_PASSWORD`
export const FETCH_USER_SUCCESS = `${STATE_KEY}/FETCH_USER_SUCCESS`
export const SET_ERROR = `${STATE_KEY}/SET_ERROR`
export const SET_LOADING_STATE = `${STATE_KEY}/SET_LOADING_STATE`
export const SET_USER_LANGUAGE = `${STATE_KEY}/SET_USER_LANGUAGE`
export const SET_OPERATION_STATE = `${STATE_KEY}/SET_OPERATION_STATE`
export const SET_OPERATION_STATUS = `${STATE_KEY}/SET_OPERATION_STATUS`
export const SET_OPERATION_ERROR = `${STATE_KEY}/SET_OPERATION_ERROR`
export const FORGOT_PASSWORD = `${STATE_KEY}/FORGOT_PASSWORD`
export const RESET_PASSWORD = `${STATE_KEY}/RESET_PASSWORD`
