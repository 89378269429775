import { Modal, notification, Spin } from 'antd'
import PlanningForm from 'components/Planning/PlanningForm'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCreatePlanningEvent } from 'services/query/planning'
import * as Api from 'types/api'
import { ModalOptions } from '../Planning'

interface Props {
  onClose: () => void
  modalOptions: ModalOptions
}

const PlanningCreate: React.FC<Props> = ({ modalOptions, onClose }) => {
  const { visible, startDate, machineId, event, endDate } = modalOptions
  const [createPlanningEvent, isLoading] = useCreatePlanningEvent()
  const { t } = useTranslation()

  const onFinish = async (data: Api.PlanningForm) => {
    try {
      await createPlanningEvent(data)
      notification.success({ message: t('messagesCrud.eventCreated') })
      onClose()
    } catch {
      notification.error({ message: t('messagesCrud.errorCreate') })
    }
  }

  return (
    <Modal
      visible={visible && !event}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      className="planning-modal"
      title={t('planning.newPlanTitle')}
    >
      <Spin spinning={isLoading}>
        <PlanningForm
          modalOptions={modalOptions}
          onFinish={onFinish}
          onDelete={null}
          initialValues={{
            startDate,
            machineId,
            endDate,
          }}
        />
      </Spin>
    </Modal>
  )
}

export default PlanningCreate
