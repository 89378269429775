import React from 'react'

import TranslationsTable from 'components/translations'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { Label } from 'types/app/admin'
import { useDeleteTranslation } from 'services/query/admin'

const TranslationsTableContainer: React.FC = () => {
  const { t } = useTranslation()

  const deleteTranslation = useDeleteTranslation()

  const onDeleteTranslation = async (translation: Label) => {
    try {
      await deleteTranslation(translation)
      notification.success({ message: t('messagesCrud.translationDeleted') })
    } catch (error) {
      console.error(error)
      notification.error({ message: t('messagesCrud.deleteError') })
    }
  }
  return <TranslationsTable onDeleteTranslation={onDeleteTranslation} />
}

TranslationsTableContainer.displayName = 'TranslationsTableContainer'

export default TranslationsTableContainer
