import React from 'react'
import { useTranslation } from 'react-i18next'

import { Card, Divider, Table, Tag, Typography } from 'antd'
import { tableSorter } from 'core/helpers'
import { useLoadArticleDashboardData } from 'services/query/dashboard'

const Articles: React.FC = () => {
  const { t } = useTranslation()
  const { data } = useLoadArticleDashboardData()

  const columns: any = [
    {
      key: 'nickname',
      title: t('general.article'),
      dataIndex: 'nickname',
      sorter: (a, b) => tableSorter(a.nickname, b.nickname),
    },
    {
      key: 'planned',
      title: t('dashboard.planned'),
      dataIndex: 'planned',
      width: 130,
      align: 'center',
    },
    {
      key: 'completed',
      title: t('dashboard.completed'),
      dataIndex: 'completed',
      width: 130,
      align: 'center',
      render: function renderCompleted(completed) {
        return <Tag color="cyan">{completed}</Tag>
      },
    },
  ]

  return (
    <Card className="widget-item widget-item--articles">
      <Typography.Title level={4}>{t('dashboard.articles')}</Typography.Title>
      <Divider className="mt-0" />

      <Table size="small" dataSource={data} columns={columns} pagination={false} />
    </Card>
  )
}

export default Articles
