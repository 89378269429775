import React, { lazy } from 'react'
import Debug from 'debug'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
// import { useDispatch, useSelector } from 'react-redux'
import Seo from 'components/Seo'
import 'core/i18n'
import ErrorBoundary from 'components/errors/ErrorBoundary'
import { getCurrentRoute } from 'core/helpers'
import routes from 'config/routes'
// import PrivateRoute from 'containers/PrivateRoute'
import Header from 'components/Layout/Header'
import { Layout } from 'antd'
import AuthProvider from 'containers/AuthProvider'
import { IconContext } from 'react-icons'

/*** Most used pages; those are not lazy loaded ***/
import Home from 'pages/HomePage'
import Users from 'pages/UsersPage'
import Workers from 'pages/WorkersPage'
import UserDetails from 'pages/UserDetails'
import Customers from 'pages/CustomersPage'
import OrderPage from 'pages/OrderPage'
import OrdersPage from 'pages/OrdersPage'
import WorkerDetails from 'pages/WorkerDetails'
import Translations from 'pages/TranslationsPage'
import Sider from 'components/Layout/Sider'
import ScrollToTop from './ScrollToTop'
import Footer from 'components/Layout/Footer'
import Languages from 'pages/LanguagesPage'
import LanguageDetailes from 'pages/LanguageDetails'
import TranslationDetails from 'pages/TranslationDetails'
import MachinesPage from 'pages/MachinesPage'
import MachinePage from 'pages/MachinePage'
import ConfirmEmail from 'pages/ConfirmEmail'
import ForgotPassword from 'pages/ForgotPassword'
import ResetPassword from 'pages/ResetPassword'
import Playground from 'pages/PlaygroundPage'
import CustomerDetails from 'pages/CustomerDetails'
import ProductionCardsPage from 'pages/ProductionCardsPage'
import ProductionCardPage from 'pages/ProductionCardPage'
import PlanningPage from 'pages/PlanningPage'
import WorklogAdminPage from 'pages/WorklogAdminPage'
import WorkLogPage from 'pages/WorkLogPage'
import ReportMachines from 'pages/ReportMachines'
import ReportArticles from 'pages/ReportArticles'
import ShiftPlanningPage from 'pages/ShiftPlanningPage'
import ReportGeneratePdfPage from 'pages/ReportGeneratePdf'
import IotDataPage from 'pages/IotDataPage'
import VariableDataPage from 'pages/VariableDataPage'

/*** Lazy Pages ***/
// Error pages
const NotFoundPage = lazy(() => import('pages/NotFoundPage'))
const ErrorPage = lazy(() => import('pages/ErrorPage'))

// Login pages
const Login = lazy(() => import('pages/LoginPage'))

const debug = Debug('Frontend')

const App: React.FC = () => {
  const location = useLocation()

  const currentRoute = getCurrentRoute(location.pathname)
  const isPrivatePage = currentRoute?.private || false
  const isPublicPage = !isPrivatePage

  debug('App started')

  //All public pages, not logged users
  if (isPublicPage) {
    return (
      <ErrorBoundary>
        <Seo />
        <Layout className="app-layout">
          <Layout.Content className="p-0">
            <Routes>
              {/* Login Pages */}
              <Route path={routes.login.url} element={<Login />} />
              <Route path={routes.confirmEmail.url} element={<ConfirmEmail />} />
              <Route path={routes.forgotPassword.url} element={<ForgotPassword />} />
              <Route path={routes.resetPassword.url} element={<ResetPassword />} />
              <Route path={routes.reportPdf.url} element={<ReportGeneratePdfPage />} />

              {/* Errors and Test Pages */}
              <Route path={routes.errorPage.url} element={<ErrorPage />} />
              <Route path={routes.notFoundPage.url} element={<NotFoundPage />} />
              {/* Fallback is error page */}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Layout.Content>
        </Layout>
      </ErrorBoundary>
    )
  }

  //All private pages
  return (
    <ErrorBoundary>
      <Seo />
      <IconContext.Provider value={{ className: 'react-icon' }}>
        <Layout className="app-layout">
          <Sider />
          <Layout className="app-content-container">
            <Header />
            <Layout.Content>
              <AuthProvider>
                <ScrollToTop />
                <Routes>
                  <Route path={routes.home.url} element={<Home />} />
                  <Route path={routes.iotData.url} element={<IotDataPage />} />
                  <Route path={routes.variableData.url} element={<VariableDataPage />} />
                  <Route path={routes.users.url} element={<Users />} />
                  <Route path={routes.workers.url} element={<Workers />} />
                  <Route path={routes.order.url} element={<OrderPage />} />
                  <Route path={routes.orders.url} element={<OrdersPage />} />
                  <Route path={routes.customers.url} element={<Customers />} />
                  <Route path={routes.planning.url} element={<PlanningPage />} />
                  <Route path={routes.shiftPlanning.url} element={<ShiftPlanningPage />} />
                  <Route path={routes.customerDetails.url} element={<CustomerDetails />} />
                  <Route path={routes.manifacturingCard.url} element={<ProductionCardPage />} />
                  <Route path={routes.manifacturingCards.url} element={<ProductionCardsPage />} />
                  <Route path={routes.workReport.url} element={<WorkLogPage />} />
                  <Route path={routes.workReports.url} element={<WorklogAdminPage />} />
                  <Route path={routes.reportMachines.url} element={<ReportMachines />} />
                  <Route path={routes.reportArticles.url} element={<ReportArticles />} />
                  <Route path={routes.userDetails.url} element={<UserDetails />} />
                  <Route path={routes.workerDetails.url} element={<WorkerDetails />} />
                  <Route path={routes.translations.url} element={<Translations />} />
                  <Route path={routes.translationDetails.url} element={<TranslationDetails />} />
                  <Route path={routes.languages.url} element={<Languages />} />
                  <Route path={routes.languageDetails.url} element={<LanguageDetailes />} />
                  <Route path={routes.machine.url} element={<MachinePage />} />
                  <Route path={routes.machines.url} element={<MachinesPage />} />

                  {/* Errors and Test Pages */}
                  <Route path={routes.errorPage.url} element={<ErrorPage />} />
                  <Route path={routes.notFoundPage.url} element={<NotFoundPage />} />
                  <Route path={routes.playgroundPage.url} element={<Playground />} />
                  {/* Fallback is not found page */}
                  <Route path="*" element={<Navigate to={routes.notFoundPage.url} />} />
                </Routes>
              </AuthProvider>
            </Layout.Content>
            <Footer />
          </Layout>
        </Layout>
      </IconContext.Provider>
    </ErrorBoundary>
  )
}

export default App
