import * as Api from 'types/api'
import * as Store from 'types/store'
import Debug from 'debug'
import moment from 'moment'
import { getApplicationError } from 'core'
import { ErrorCode } from 'types/enums'

const debug = Debug('services:map:planning')

export const mapPlanningEvent = ({
  cardId,
  customer,
  customerId,
  endDate,
  endShift,
  id,
  machineId,
  numPieces,
  startDate,
  startShift,
  article,
}: Api.PlanningEventResponse): Store.PlanningEvent => ({
  cardId,
  customer,
  customerId,
  endDate: moment(endDate),
  endShift,
  id,
  machineId,
  numPieces,
  startDate: moment(startDate),
  startShift,
  article,
})

export const mapPlanningEventsList = (plannings: Api.PlanningEventResponse[]): Store.PlanningEvent[] => {
  try {
    return plannings.map(mapPlanningEvent)
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}
