import React from 'react'
import { Button, Card, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'
import * as Store from 'types/store'
import routes from 'config/routes'
import { VariableValueType } from 'types/enums'
import { LATEST_ACTIVITY_DAYS } from './IotData'
import { config } from 'core'
import moment from 'moment'
import { ArrowRightOutlined } from '@ant-design/icons'

interface Props {
  data: Store.DeviceVariable
  withLink?: boolean
}

const VariableCard: React.FC<Props> = ({ data, withLink = false }) => {
  const { t } = useTranslation()
  const { id, deviceId, name, valueType, latestLog } = data

  const hasLatestLog = () => {
    if (valueType === VariableValueType.Empty) {
      return false
    }

    return true
  }

  const getLatestLogData = (value) => {
    return latestLog ? value : t('-')
  }

  return (
    <Card className="variable-box">
      <Typography.Title level={4}>{name}</Typography.Title>

      {hasLatestLog && (
        <>
          <div className="values-wrapper">
            <span className="title">{t('iot.latestValue')}</span>
            <div className="value">
              <Typography.Title level={5}>{getLatestLogData(latestLog?.value)}</Typography.Title>
            </div>
          </div>

          <div className="timestamp-wrapper">
            <span className="title">{t('general.timestamp')}</span>
            <span className="value">
              {getLatestLogData(moment(latestLog?.logTimestamp).format(config.getDateTimeFormat()))}
            </span>
          </div>

          {withLink && (
            <Link to={generatePath(routes.variableData.url, { deviceId: deviceId.toString(), id: id })}>
              <Button size="small" type="text">
                {t('iot.btnLatestActivity', { number: LATEST_ACTIVITY_DAYS })}
                <ArrowRightOutlined />
              </Button>
            </Link>
          )}
        </>
      )}
    </Card>
  )
}

export default VariableCard
