import React, { useState } from 'react'
import routes from 'config/routes'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import * as Api from 'types/api'

import { notification } from 'antd'
import WorklogForm from 'components/WorkReportAdmin/WorklogForm'
import { useCreateWorklogByAdmin } from 'services/query/worklog'

const WorklogCreateContainer: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { mutateAsync: createWorklog } = useCreateWorklogByAdmin()

  const [spin, setSpin] = useState(false)

  const onCreate = async (model: Api.WorklogAdminForm) => {
    try {
      setSpin(true)

      const data: Api.WorklogPost = {
        cardId: model.cardId,
        date: model.date.toISOString(),
        machineId: model.machineId,
        notes: model.notes,
        numPieces: model.numPieces,
        shift: model.shift,
        workerId: model.workerId,
      }

      await createWorklog(data)
      setSpin(false)
      navigate(generatePath(routes.workReports.url), { replace: true })
      notification.success({ message: t('messagesCrud.worklogCreated') })
    } catch (error) {
      console.error(error)
      setSpin(false)
      notification.error({ message: t('messagesCrud.error') })
    }
  }

  return <WorklogForm spin={spin} worklog={null} onFinish={onCreate} onDelete={null} />
}

export default WorklogCreateContainer
