import * as queryKeys from 'config/queryKeys'
import { useMutation, useQuery } from 'react-query'

import {
  generateCSVReportsData,
  generatePdfReportsData,
  getActivityTypesReportsData,
  getArticlesReportsData,
  getMachinesReportsData,
} from 'api/reports'
import { mapReportsActivityType, mapReportsArticle, mapReportsMachine } from 'services/map/reports'

export function useLoadMachinesReportsData(dateStart: string, dateEnd: string) {
  const queryFunction = async () => {
    const response = await getMachinesReportsData(dateStart, dateEnd)
    return response.map(mapReportsMachine)
  }

  return useQuery(queryKeys.reportsMachines(dateStart, dateEnd), queryFunction, {
    placeholderData: [],
  })
}

export function useLoadActivityTypesReportsData(dateStart: string, dateEnd: string) {
  const queryFunction = async () => {
    const response = await getActivityTypesReportsData(dateStart, dateEnd)
    return response.map(mapReportsActivityType)
  }

  return useQuery(queryKeys.reportsActivityTypes(dateStart, dateEnd), queryFunction, {
    placeholderData: [],
  })
}

export function useLoadArticlesReportsData(dateStart: string, dateEnd: string) {
  const queryFunction = async () => {
    const response = await getArticlesReportsData(dateStart, dateEnd)
    return response.map(mapReportsArticle)
  }

  return useQuery(queryKeys.reportsArticles(dateStart, dateEnd), queryFunction, {
    placeholderData: [],
  })
}

export function useGeneratePdfReports(dateStart: string, dateEnd: string, type: string) {
  const mutateFunction = async () => {
    return await generatePdfReportsData(dateStart, dateEnd, type)
  }

  return useMutation(mutateFunction)
}

export function useGenerateCSVReports(dateStart: string, dateEnd: string, type: string, languageIso: string) {
  const mutateFunction = async () => {
    return await generateCSVReportsData(dateStart, dateEnd, type, languageIso)
  }

  return useMutation(mutateFunction)
}
