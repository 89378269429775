import React from 'react'
import { useParams } from 'react-router-dom'

import OrderCreateContainer from 'containers/orders/OrderCreateContainer'
import OrderDetailsContainer from 'containers/orders/OrderDetailsContainer'

const OrderPage: React.FC = () => {
  const { id } = useParams()

  if (id === 'new') {
    return <OrderCreateContainer />
  }

  return <OrderDetailsContainer />
}

export default OrderPage
