import React, { useState } from 'react'
import { useTheme } from 'providers/ThemeProvider'
import { useTranslation } from 'react-i18next'

import PageHeader from 'components/PageHeader'
import { Button, Card, Space, Steps } from 'antd'
import { WorkReportStep } from 'types/api/common'
const { Step } = Steps
import * as Store from 'types/store'
import classNames from 'classnames'
import { themes } from './themes'
import styles from './styles.module.scss'

interface Props {
  steps: WorkReportStep[]
  stepSelections: Store.WorklogStepSelections
  current: number
  onNextClick: () => void
  onPrevClick: () => void
}

const WorkReport: React.FC<Props> = ({ steps, current, onNextClick, onPrevClick, stepSelections }) => {
  const { t } = useTranslation()
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const componentClassname = classNames(styles['work-report'], themeStyles['theme'])

  const [isVisible, setVisible] = useState(true)

  const checkNextStepDisabled = () => {
    switch (current) {
      case 0:
        return stepSelections.worker === null
      case 1:
        return stepSelections.activityType === null
      case 2:
        return stepSelections.machine === null
      default:
        return true
    }
  }

  return (
    <section className={componentClassname}>
      <PageHeader title={t('menu.workReport')} />

      <div className="content">
        {isVisible && (
          <div className="start-reporting-overlay">
            <Button type="primary" size="large" onClick={() => setVisible(false)}>
              {t('reporting.startReporting')}
            </Button>
          </div>
        )}

        <Card className="main-card">
          <Steps current={current}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>

          {steps[current].content}

          <div className="steps-action">
            <Space>
              <div>
                {current > 0 && (
                  <Button style={{ margin: '0 8px' }} onClick={() => onPrevClick()}>
                    {t('general.previous')}
                  </Button>
                )}
              </div>
              <div>
                {current < steps.length - 1 && (
                  <Button type="primary" onClick={() => onNextClick()} disabled={checkNextStepDisabled()}>
                    {t('general.next')}
                  </Button>
                )}
              </div>
            </Space>
          </div>
        </Card>
      </div>
    </section>
  )
}

export default WorkReport
