import * as Api from 'types/api'
import { Machine, Machines } from 'types/store'
import Debug from 'debug'
import { getApplicationError } from 'core'
import { ErrorCode } from 'types/enums'

const debug = Debug('services:map:machines')

export const mapMachine = ({
  id,
  nickname,
  typeId,
  type,
  number,
  ip,
  model,
  year,
  machineShifts,
  createdAt,
  createdBy,
  createdById,
  updatedAt,
  updatedBy,
  updatedById,
}: Api.Machine): Machine => ({
  id,
  nickname,
  typeId,
  type,
  number,
  ip,
  model,
  year,
  machineShifts,
  createdAt,
  createdBy,
  createdById,
  updatedAt,
  updatedBy,
  updatedById,
})

export const mapMachinesList = ({
  result,
  currentPage,
  totalItems,
  totalPages,
}: Api.ListMachinesResponse): Machines => {
  try {
    return {
      result: result.map(mapMachine),
      currentPage,
      totalItems,
      totalPages,
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}
