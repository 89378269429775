import React from 'react'
import { useParams } from 'react-router-dom'

import MachineCreateContainer from 'containers/machines/MachineCreate'
import MachineDetailsContainer from 'containers/machines/MachineDetails'

const MachinePage: React.FC = () => {
  const { id } = useParams()

  if (id === 'new') {
    return <MachineCreateContainer />
  }

  return <MachineDetailsContainer />
}

export default MachinePage
