import { config } from 'core'
import { AxiosError, AxiosResponse } from 'axios'
import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'
import * as Api from 'types/api/admin'

const axios = getAxios()

export async function listWorkers(
  filter: string,
  page: string,
  status: string,
): Promise<Api.ListWorkerResponse> {
  try {
    const response = await axios.get<Api.ListWorkerResponse>(
      config.api.workers(filter, page, status),
      await getAuthenticationHeader(),
    )
    return response.data as Api.ListWorkerResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function createWorker(model: Api.WorkerCreateEditRequest): Promise<Api.Worker> {
  try {
    const response = await axios.post(config.api.newWorker(), model, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function editWorker(workerId: number, model: Api.WorkerCreateEditRequest): Promise<Api.Worker> {
  try {
    const response = await axios.put(config.api.worker(workerId), model, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteWorker(workerId: number): Promise<AxiosResponse> {
  try {
    const response = await axios.delete(config.api.worker(workerId), await getAuthenticationHeader())
    return response.data as AxiosResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getWorker(workerId: number): Promise<Api.Worker> {
  try {
    const response = await axios.get<Api.Worker>(config.api.worker(workerId), await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
