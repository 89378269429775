import React from 'react'
import routes from 'config/routes'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { AiOutlineUser } from 'react-icons/ai'
import { Form, Input, Button, Row, Col, Card, Typography } from 'antd'
import styles from './styles.module.scss'

interface Props {
  onConfirm: (email: string) => void
  isDone: boolean
  isLoading: boolean
}

const ForgotPasswordForm: React.FC<Props> = ({ onConfirm, isDone, isLoading }) => {
  const { t } = useTranslation()

  const onFinish = (values: Json) => {
    const { email } = values

    onConfirm(email)
  }

  const doneView = (
    <>
      <Typography.Title level={5}>{t('auth.forgotPasswordSuccess')}</Typography.Title>
      <Button block size="large" type="primary">
        <Link to={routes.login.url} className="text-gray">
          {t('auth.backToLogin')}
        </Link>
      </Button>
    </>
  )

  const formView = (
    <>
      <Form name="normal_login" className="form" initialValues={{ remember: true }} onFinish={onFinish}>
        <Form.Item
          name="email"
          className="mb-50"
          rules={[{ required: true, type: 'email', message: t('login.errors.email') }]}
        >
          <Input
            size="large"
            autoComplete="email"
            placeholder={t('login.mailPlaceholder')}
            prefix={<AiOutlineUser />}
          />
        </Form.Item>

        <Form.Item className="mb-10">
          <Button block size="large" type="primary" htmlType="submit" loading={isLoading}>
            {t('auth.forgotPasswordSubmit')}
          </Button>
        </Form.Item>
      </Form>

      <Button type="ghost" block>
        <Link to={routes.login.url} className="text-gray">
          {t('auth.backToLogin')}
        </Link>
      </Button>
    </>
  )

  return (
    <Row align="middle" justify="center" className={styles['login-form']}>
      <Col flex="0 0 300px">
        <Card>
          <div className="logo" />
          <Typography.Title level={3}>{t('login.forgotPass')}</Typography.Title>
          {isDone ? doneView : formView}
        </Card>
      </Col>
    </Row>
  )
}

export default ForgotPasswordForm
