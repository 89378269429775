import React from 'react'
import { Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean
  dataIndex: string
  title: any
  inputType: 'text'
  record: any
  index: number
  children: React.ReactNode
}

const EditableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, title, children, ...restProps }) => {
  const { t } = useTranslation()
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: false,
              message: t('input.required', { input: title }),
            },
          ]}
        >
          <Input />
        </Form.Item>
      ) : (
        <>{children}</>
      )}
    </td>
  )
}

export default EditableCell
