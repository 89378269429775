import React from 'react'

import { useTranslation } from 'react-i18next'

import { Card, Typography } from 'antd'
import { useLoadActivityTypeList } from 'services/query/admin'
import * as Store from 'types/store'
import cn from 'classnames'
import { MachineActivityType } from 'types/enums'

const { Title } = Typography

interface Props {
  onSelect: (selections: Partial<Store.WorklogStepSelections>) => void
  selected: number
  getActivityTypeImage: (activityType: MachineActivityType) => string
}

const ChooseActivityType: React.FC<Props> = ({ onSelect, selected, getActivityTypeImage }) => {
  const { t } = useTranslation()
  const { data: activityTypes } = useLoadActivityTypeList()

  return (
    <section className="step-container">
      <Title level={3}>{t('reports.whatActivityType')}</Title>

      <div className="list-container list-container--activity-type">
        {activityTypes.map((activityType) => {
          return (
            <Card
              onClick={() => {
                onSelect({ activityType: activityType })
              }}
              className={cn({ 'selected-item': activityType.id === selected })}
              key={activityType.id}
              size="small"
              title={t(`machines.activityTypes.${activityType.type.toLowerCase()}`)}
              hoverable
              bordered={true}
              style={{ width: 162, height: 206 }}
              cover={<img alt="example" src={getActivityTypeImage(activityType.id)} />}
            ></Card>
          )
        })}
      </div>
    </section>
  )
}

export default ChooseActivityType
