import React, { useState } from 'react'
import UserDetails from 'components/UserDetails'
import { useTranslation } from 'react-i18next'
import Loading from 'components/Loading'
import {
  useCreateUser,
  useEditUser,
  useLoadUser,
  useLoadUserRoles,
  useDeleteUser,
} from 'services/query/users'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useRouter } from 'core/useRouter'
import UserEdit from 'components/UserDetails/UserEdit'
import { Operation } from 'types/enums'
import { User } from 'types/app/admin'
import routes from 'config/routes'
import { Modal, notification, Typography } from 'antd'

const UserDetailsContainer: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const router = useRouter()
  const [editMode] = useState(router.query.editmode ? true : false)
  const navigate = useNavigate()
  const editUser = useEditUser()
  const { createUser, isLoading: isCreateUserLoading } = useCreateUser()

  const { data: user, isFetched, isLoading } = useLoadUser(parseInt(id))

  const { data: roles } = useLoadUserRoles()
  const dataLoading: boolean = isLoading || !isFetched || isCreateUserLoading

  const operationType = user?.id ? Operation.Update : Operation.Create

  const onUserDetailsSaved = async (userModel: User) => {
    try {
      const role = roles.find((role) => role.id === userModel.roleId)
      if (operationType === Operation.Update) {
        await editUser({ ...user, ...userModel, role: role.name })
        notification.success({ message: t('messagesCrud.userUpdated') })
      } else {
        await createUser({ ...userModel, role: role.name })
        notification.success({ message: t('messagesCrud.userUpdated') })
      }
      navigate(generatePath(routes.users.url), { replace: true })
    } catch (error) {
      console.error(error)
      notification.error({ message: `User save error: ${error || ''}` })
    }
  }
  const deleteUser = useDeleteUser()
  const onDelete = (userId: number) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      async onOk() {
        try {
          await deleteUser(userId)
          navigate(generatePath(routes.users.url), { replace: true })
          notification.success({ message: t('messagesCrud.userDeleted') })
        } catch (error) {
          notification.error({ message: t('messagesCrud.deleteError') })
        }
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  if (user === null) {
    return <Loading />
  }

  if (editMode) {
    return (
      <UserEdit
        user={user}
        roles={roles}
        operationType={operationType}
        onUserDetailsSaved={onUserDetailsSaved}
        onDelete={onDelete}
        loading={dataLoading}
      />
    )
  }

  return <UserDetails user={user} onDelete={onDelete} />
}

export default UserDetailsContainer
