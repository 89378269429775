import moment from 'moment'

// import proper moment translation for each new language
import 'moment/locale/it'

import { apiProvider } from 'config/api'
import { getLanguage } from './localStore'

const homepage = process.env.REACT_APP_HOMEPAGE
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
const language: string = getLanguage() ?? process.env.REACT_APP_LOCALE ?? 'it'
const platform = 'WEB'

/* eslint-disable no-useless-escape */
export const VALIDATE_PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/g
export const EMPTY_DATE = '0001-01-01T00:00:00.000Z'

export const TABLE_PAGINATION_DROPDOWN_VALUES = ['10', '20', '50', '100']
export const TABLE_PAGINATION_DEFAULT_PAGE_SIZE = 10

const setMomentLocale = (lang: string) => {
  switch (lang) {
    // add more cases for this block if new languages are added
    case 'en':
      moment.locale(lang)
      break
    // italian is the default for fallback
    case 'it':
    default:
      moment.locale('it')
      break
  }
}

setMomentLocale(language)

export const config = (function config() {
  const provider = apiProvider(apiBaseUrl)

  let _language: string = language
  return {
    api: provider.api,
    homepage,
    getLanguage: () => _language,
    getDateFormat: () => 'DD/MM/YYYY',
    getDateTimeFormat: () => 'DD/MM/YYYY HH:mm',
    getShiftPlanningCSVName: (startWeek: moment.Moment, endWeek: moment.Moment) =>
      `shifts-${startWeek.week()}-${startWeek.year()}-${endWeek.week()}-${endWeek.year()}.csv`,
    getShiftPlanningPDFName: (startWeek: moment.Moment, endWeek: moment.Moment) =>
      `shifts-${startWeek.week()}-${startWeek.year()}-${endWeek.week()}-${endWeek.year()}.pdf`,
    getReportCSVName: (type: string, startDay: moment.Moment, endDay: moment.Moment, format: string) =>
      `${type}-${startDay.format(format)}-${endDay.format(format)}.csv`,
    getReportPdfName: (type: string, startDay: moment.Moment, endDay: moment.Moment, format: string) =>
      `${type}-${startDay.format(format)}-${endDay.format(format)}.pdf`,
    setLanguage: (lng: string) => {
      provider.setLanguage(lng)
      _language = lng

      setMomentLocale(language)
    },
    apiBaseUrl,
    platform,
  }
})()

export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY
