import React from 'react'
import routes from 'config/routes'
import * as Store from 'types/store'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { useDeleteProductionCard, useGetProductionCardPdf } from 'services/query/productionCards'
import FileDownload from 'js-file-download'
import FallbackImage from 'assets/fallback-image.png'

import PageHeader from 'components/PageHeader'

import { HiChevronRight, HiChevronLeft, HiOutlineEye } from 'react-icons/hi'
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  Typography,
  Carousel,
  Image,
  Space,
  Tag,
  Descriptions,
  Tooltip,
  notification,
} from 'antd'
import { DeleteOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons'
import { MachineActivityType } from 'types/enums'

interface Props {
  card: Store.ProductionCard
}

const ProductionCardDetails: React.FC<Props> = ({ card }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const deleteCard = useDeleteProductionCard()
  const { mutateAsync: pdfDownload, isLoading } = useGetProductionCardPdf()

  const onDelete = async (card: Store.ProductionCard) => {
    try {
      await deleteCard(card)
      navigate(generatePath(routes.manifacturingCards.url), { replace: true })
      notification.success({ message: t('messagesCrud.machineDeleted') })
    } catch (error) {
      notification.error({ message: t('messagesCrud.deleteError') })
    }
  }

  const handleDelete = (card: Store.ProductionCard) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      onOk() {
        onDelete(card)
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  const downloadPdf = async () => {
    try {
      const response = await pdfDownload(card.id)
      if (response?.data) {
        FileDownload(response.data, `${card.article}-${card.type}.pdf`)
      }
    } catch {
      notification.error({ message: t('messagesCrud.error') })
    }
  }

  const carouselConfig = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <>
      <PageHeader backRoute={routes.manifacturingCards} title={card.article} subtitle={card.customer}>
        <Button
          type="primary"
          className="btn-icon"
          icon={<DownloadOutlined />}
          loading={isLoading}
          onClick={downloadPdf}
        >
          {t('btn.download')}
        </Button>

        <Button
          type="default"
          href={generatePath(routes.manifacturingCard.url, { id: card.id.toString() }) + '?editmode=details'}
        >
          <EditOutlined />
          {t('btn.edit')}
        </Button>
        <Button type="text" onClick={() => handleDelete(card)}>
          <DeleteOutlined />
          {t('btn.deleteCard')}
        </Button>
      </PageHeader>
      <Card className="production-card" bodyStyle={{ padding: 0 }}>
        <Row gutter={[24, 0]}>
          <Col className="col--image" xs={24} lg={12}>
            <Carousel {...carouselConfig} nextArrow={<HiChevronRight />} prevArrow={<HiChevronLeft />}>
              {card.images.map((img) => (
                <Image
                  key={img.id}
                  src={img.path}
                  fallback={FallbackImage}
                  preview={{
                    mask: (
                      <Space>
                        <HiOutlineEye />
                        <span>{t('general.preview')}</span>
                      </Space>
                    ),
                  }}
                />
              ))}
            </Carousel>
          </Col>
          <Col xs={24} lg={12} className="p-24">
            <Descriptions
              column={1}
              colon={false}
              title={
                <Tag className={`with-icon with-icon--${card.type.toLowerCase()}`}>
                  {t(`machines.activityTypes.${card.type.toLowerCase()}`)}
                </Tag>
              }
              className="descriptions-half"
            >
              <Descriptions.Item label={t('general.activitySubtype')}>
                <div className="align-right">
                  {t(`machine.activitySubType.${card.subType.toLowerCase()}`)}
                </div>
              </Descriptions.Item>
              {card.machines.length > 0 && (
                <Descriptions.Item label={t('general.machines')}>
                  <div className="align-right">
                    {card.machines.map((machine) => (
                      <Tooltip key={machine.id} title={machine.nickname}>
                        <Tag>{machine.number}</Tag>
                      </Tooltip>
                    ))}
                  </div>
                </Descriptions.Item>
              )}
              <Descriptions.Item label={t('productionCard.productionTime')}>
                <div className="align-right">{card.productionTime}</div>
              </Descriptions.Item>

              {card.typeId == MachineActivityType.SandBlasting && (
                <>
                  <Descriptions.Item label={t('productionCard.carpetSpeed')}>
                    <div className="align-right">{card?.carpetSpeed} </div>
                  </Descriptions.Item>
                  <Descriptions.Item label={t('productionCard.sandTime')}>
                    <div className="align-right">{card?.sandTime} </div>
                  </Descriptions.Item>
                  <Descriptions.Item label={t('productionCard.doorOpeningTime')}>
                    <div className="align-right">{card?.doorOpeningTime} </div>
                  </Descriptions.Item>
                  <Descriptions.Item label={t('productionCard.downloadType')}>
                    <div className="align-right">{card?.downloadType} </div>
                  </Descriptions.Item>
                  <Descriptions.Item label={t('productionCard.downloadTotalTime')}>
                    <div className="align-right">{card?.downloadTotalTime} </div>
                  </Descriptions.Item>
                  <Descriptions.Item label={t('productionCard.intermittentWorkTime')}>
                    <div className="align-right">{card?.intermittentWorkTime} </div>
                  </Descriptions.Item>
                  <Descriptions.Item label={t('productionCard.intermittentPauseTime')}>
                    <div className="align-right">{card?.intermittentPauseTime} </div>
                  </Descriptions.Item>
                </>
              )}
              <Descriptions.Item label={t('general.notes')}>
                <div className="align-right">{card.notes}</div>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default ProductionCardDetails
