import React from 'react'
import { useTranslation } from 'react-i18next'

import { Layout, Typography } from 'antd'
const Footer: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Layout.Footer>
      <Typography.Paragraph className="align-center m-0">{t('general.footer')}</Typography.Paragraph>
    </Layout.Footer>
  )
}

export default Footer
