import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { config } from 'core'
import debounce from 'debounce'
import routes from 'config/routes'
import * as Store from 'types/store'
import { OrderStatus } from 'types/enums'
import { filterSearchSelect, tableSorter } from 'core/helpers'
import { useTranslation } from 'react-i18next'
import { generatePath, Link, useSearchParams } from 'react-router-dom'
import { useLoadOrderList, useDeleteOrder } from 'services/query/orders'

import PageHeader from 'components/PageHeader'
import Table from 'components/Layout/Table'
import FilterPanel from 'components/FilterPanel'

import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus, AiOutlineEye } from 'react-icons/ai'
import {
  Button,
  Card,
  Col,
  Modal,
  notification,
  Select,
  Space,
  TableColumnsType,
  Tag,
  Tooltip,
  Typography,
} from 'antd'

import styles from './styles.module.scss'
import { useLoadCardsPicklist } from 'services/query/picklist'

const OrdersTableComponent: React.FC = () => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const [filters, setFilters] = useState({
    filter: searchParams.get('filter') || '',
    page: searchParams.get('page') || '1',
    status: searchParams.get('status') || '',
    card: searchParams.get('card') || '',
    customer: '',
  })
  const { filter, page, customer, status, card } = filters

  const {
    data: orders,
    isLoading,
    isFetched,
    refetch,
  } = useLoadOrderList(filter, parseInt(page), customer, status, card)

  const { data: cards } = useLoadCardsPicklist()

  const onFilter = (value: string) => {
    debounce(setFilters({ ...filters, filter: value, page: '1' }), 300)
  }

  useEffect(() => {
    const query = Object.entries({ filter, page, customer, status, card }).reduce((object, [key, value]) => {
      if (value) {
        object[key] = value
      }
      return object
    }, {})

    setSearchParams(query)
  }, [filter, customer, status, page, card])

  const dataLoading: boolean = isLoading || !isFetched

  const deleteOrder = useDeleteOrder()

  const onDelete = async (order: Store.Order) => {
    try {
      await deleteOrder(order)
      refetch()
      notification.success({ message: t('messagesCrud.orderDeleted') })
    } catch (error) {
      notification.error({ message: t('messagesCrud.deleteError') })
    }
  }

  const handleDelete = (order: Store.Order) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      onOk() {
        onDelete(order)
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  const getOrderStatus = (status: OrderStatus) => {
    switch (status) {
      case OrderStatus.New:
        return <Tag color="warning">{t('status.new')}</Tag>
      case OrderStatus.InProgress:
        return <Tag color="processing">{t('status.inprogress')}</Tag>
      case OrderStatus.Completed:
        return <Tag color="success">{t('status.completed')}</Tag>
      case OrderStatus.Delivered:
        return <Tag color="magenta">{t('status.delivered')}</Tag>
      case OrderStatus.Archived:
      default:
        return <Tag color="default">{t('status.archived')}</Tag>
    }
  }

  const columns: TableColumnsType<any> = [
    {
      key: 'id',
      title: t('general.id'),
      dataIndex: 'id',
      width: 100,
      sorter: (a, b) => tableSorter(a.id, b.id),
    },
    {
      key: 'customer',
      title: t('general.customer'),
      dataIndex: 'customer',
      sorter: (a, b) => tableSorter(a.customer, b.customer),
    },
    {
      key: 'numPieces',
      title: t('general.numPieces'),
      width: 200,
      dataIndex: 'numPieces',
      sorter: (a, b) => tableSorter(a.numPieces, b.numPieces),
    },
    {
      key: 'orderNumber',
      width: 200,
      title: t('general.orderNumber'),
      dataIndex: 'orderNumber',
      sorter: (a, b) => tableSorter(a.orderNumber, b.orderNumber),
    },
    {
      key: 'status',
      title: t('general.status'),
      dataIndex: 'status',
      sorter: (a, b) => tableSorter(a.status, b.status),
      render: function actions(status: OrderStatus) {
        return getOrderStatus(status)
      },
    },
    {
      key: 'cards',
      title: t('menu.manifacturingCards'),
      dataIndex: 'cards',
      // NOTE: no sorter
      render: function tags(cards: Store.OrderCard[]) {
        return (
          <Space size={0}>
            {cards.map((card) => (
              <Tooltip key={card.id} title={card.article}>
                <Tag className={styles['card-tag']}>{card.article}</Tag>
              </Tooltip>
            ))}
          </Space>
        )
      },
    },
    {
      key: 'dueDate',
      title: t('general.dueDate'),
      dataIndex: 'dueDate',
      sorter: (a, b) => tableSorter(a.dueDate, b.dueDate),
      render: function actions(dueDate) {
        return moment(dueDate).format(config.getDateFormat())
      },
    },
    {
      key: 'actions',
      title: t('general.actions'),
      dataIndex: 'id',
      width: 100,
      className: 'cell-actions',
      render: function actions(id, record: Store.Order) {
        return (
          <Space size={0}>
            <Tooltip key={`action-details-${id}`} title={t('tooltip.details')}>
              <Link to={generatePath(routes.order.url, { id: id })}>
                <Button type="link" size="small">
                  <AiOutlineEye />
                </Button>
              </Link>
            </Tooltip>
            <Tooltip key={`action-edit-${id}`} title={t('tooltip.edit')}>
              <Link to={generatePath(routes.order.url, { id: id }) + '?editmode=true'}>
                <Button type="link" size="small">
                  <AiOutlineEdit />
                </Button>
              </Link>
            </Tooltip>
            <Tooltip key={`action-delete-${id}`} title={t('tooltip.delete')}>
              <Button type="link" size="small">
                <AiOutlineDelete onClick={() => handleDelete(record)} />
              </Button>
            </Tooltip>
          </Space>
        )
      },
    },
  ]

  return (
    <section>
      <PageHeader title={t('menu.orders')}>
        <Link to={generatePath(routes.order.url, { id: 'new' })}>
          <Button type="primary" className="btn-icon" icon={<AiOutlinePlus />}>
            {t('btn.new')}
          </Button>
        </Link>
      </PageHeader>
      <Card>
        <FilterPanel filter={filter} onFilter={onFilter}>
          <Col xs={24} lg={8}>
            <Select
              allowClear
              value={OrderStatus[`${filters.status}`]}
              placeholder={t('filter.selectStatus')}
              onClear={() => debounce(setFilters({ ...filters, status: '' }), 300)}
              onSelect={(status) =>
                debounce(setFilters({ ...filters, status: OrderStatus[`${status}`] }), 300)
              }
            >
              {Object.keys(OrderStatus).map((status) => {
                return (
                  <Select.Option key={OrderStatus[`${status}`]} value={OrderStatus[`${status}`]}>
                    {t(`status.${status.toLowerCase()}`)}
                  </Select.Option>
                )
              })}
            </Select>
          </Col>
          <Col xs={24} lg={8}>
            <Select
              allowClear
              placeholder={t('filter.productionCard')}
              value={card ? card : null}
              onClear={() => setFilters({ ...filters, card: '' })}
              onSelect={(card) => setFilters({ ...filters, card })}
              showSearch
              filterOption={filterSearchSelect}
            >
              {cards.map((card) => {
                return (
                  <Select.Option key={card.id} value={card.id}>
                    {card.article}
                  </Select.Option>
                )
              })}
            </Select>
          </Col>
        </FilterPanel>
        <Table
          total={orders.totalItems}
          columns={columns}
          isLoading={dataLoading}
          dataSource={orders.result}
          setPage={(page) => setFilters((filters) => ({ ...filters, page }))}
        />
      </Card>
    </section>
  )
}

export default OrdersTableComponent
