import React from 'react'
import { Table, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import ArticleBarchart from 'components/reports/ReportArticles/ByArticle/ArticleBarchart'
import { useLoadArticlesReportsData } from 'services/query/reports'

import styles from '../styles.module.scss'

interface Props {
  dateStart: string
  dateEnd: string
}

const ReportByArticle: React.FC<Props> = ({ dateStart, dateEnd }) => {
  const { t } = useTranslation()

  const columns: any = [
    {
      key: 'date',
      title: t('general.date'),
      dataIndex: 'date',
    },
    {
      key: 'piecesCompleted',
      title: t('dashboard.piecesCompleted'),
      dataIndex: 'piecesCompleted',
      align: 'center',
    },
    {
      key: 'piecesPlanned',
      title: t('dashboard.piecesPlanned'),
      dataIndex: 'piecesPlanned',
      align: 'center',
    },
  ]

  const { data, isFetching } = useLoadArticlesReportsData(dateStart, dateEnd)

  if (isFetching) {
    return null
  }

  return (
    <section className={styles['report-pdf']}>
      {data.map((block) => {
        return (
          <div className="block" key={block.articleId}>
            <div className="block-head-wrapper">
              <div className="block-header">
                <Typography.Title level={4}>{block.article}</Typography.Title>
                <Typography.Text>{block.total}</Typography.Text>
              </div>

              <ArticleBarchart data={block} />
            </div>

            <Table dataSource={block.data} columns={columns} pagination={false} />
          </div>
        )
      })}
    </section>
  )
}

export default ReportByArticle
