import * as Api from 'types/api'
import * as Store from 'types/store'
import * as queryKeys from 'config/queryKeys'

import { UseMutateAsyncFunction, useMutation, useQuery, useQueryClient } from 'react-query'
import { createPlanningEvent, deletePlanningEvent, editPlanningEvent, listPlanningEvents } from 'api/planning'
import { mapPlanningEvent, mapPlanningEventsList } from 'services/map/planning'

export function useCreatePlanningEvent(): [
  UseMutateAsyncFunction<Store.PlanningEvent, unknown, Api.PlanningForm, unknown>,
  boolean,
] {
  const mutateFunction = async (formData: Api.PlanningForm) => {
    const response = await createPlanningEvent(formData)

    return mapPlanningEvent(response)
  }
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useMutation(mutateFunction, {
    onSuccess: (planningEvent: Store.PlanningEvent) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.planningEventDetail(planningEvent.id)),
        queryClient.invalidateQueries(queryKeys.planningEventList()),
      ])
    },
  })

  return [mutateAsync, isLoading]
}

export function useEditPlanningEvent(
  id: number,
): [UseMutateAsyncFunction<Store.PlanningEvent, unknown, Api.PlanningForm, unknown>, boolean] {
  const mutateFunction = async (formData: Api.PlanningForm) => {
    const response = await editPlanningEvent(id, formData)

    return mapPlanningEvent(response)
  }
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useMutation(mutateFunction, {
    onSuccess: (planningEvent: Store.PlanningEvent) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.planningEventDetail(planningEvent.id)),
        queryClient.invalidateQueries(queryKeys.planningEventList()),
      ])
    },
  })

  return [mutateAsync, isLoading]
}

export function useDeletePlanningEvent(): [
  UseMutateAsyncFunction<number, unknown, number, unknown>,
  boolean,
] {
  const mutateFunction = async (id: number) => {
    await deletePlanningEvent(id)
    return id
  }
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useMutation(mutateFunction, {
    onSuccess: (id: number) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.planningEventDetail(id)),
        queryClient.invalidateQueries(queryKeys.planningEventList()),
      ])
    },
  })

  return [mutateAsync, isLoading]
}

export function useListPlanningEvents(startDate: string) {
  const queryFunction = async () => {
    const response = await listPlanningEvents(startDate)
    return mapPlanningEventsList(response)
  }

  return useQuery(queryKeys.planningEventListFilter(startDate), queryFunction, {
    placeholderData: [],
  })
}
