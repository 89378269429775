import React from 'react'
import routes from 'config/routes'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logout } from 'store/user/actions'
import { clearUser } from 'core/localStore'
import { useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'

import { AiOutlineLogout } from 'react-icons/ai'
import { Button } from 'antd'

const UserLogout: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const onLogout = () => {
    clearUser()
    queryClient.clear()
    dispatch(logout())
  }
  return (
    <Link to={routes.login.url} onClick={onLogout}>
      <Button block className="btn-icon" icon={<AiOutlineLogout size={18} />}>
        {t('btn.logout')}
      </Button>
    </Link>
  )
}

export default UserLogout
