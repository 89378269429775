import React, { useState } from 'react'
import Debug from 'debug'
import { tableSorter } from 'core/helpers'
import { Language } from 'types/app/admin'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { TABLE_PAGINATION_DEFAULT_PAGE_SIZE, TABLE_PAGINATION_DROPDOWN_VALUES } from 'core'

import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import { Button, Form, Input, Modal, Space, Table, Tooltip, Typography } from 'antd'

interface Props {
  tableRef?: any
  dataSource: any
  isLoading?: boolean
  onChange?: (pagination, filters, sorter) => void
  total?: number
  expandable?: any
  rowClassName?: any
  onDeleteLanguage: (language) => void
  refetch: () => void
  onSaveLanguage: (language) => void
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean
  dataIndex: string
  title: any
  inputType: 'text'
  record: any
  index: number
  children: React.ReactNode
}

const EditableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, title, children, ...restProps }) => {
  const { t } = useTranslation()
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: t('input.required', { input: title }),
            },
          ]}
        >
          <Input />
        </Form.Item>
      ) : (
        <>{children}</>
      )}
    </td>
  )
}

const EditTableComponent: React.FC<Props> = ({
  dataSource,
  isLoading,
  total,
  onChange,
  expandable,
  rowClassName,
  onDeleteLanguage,
  onSaveLanguage,
}) => {
  const { t } = useTranslation()

  const [form] = Form.useForm()
  const [data, setData] = useState(dataSource)
  const [editingKey, setEditingKey] = useState('')

  const handleDelete = (language: Language) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      onOk() {
        onDeleteLanguage(language)
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  React.useEffect(() => {
    setData(dataSource)
  }, [dataSource])

  const isEditing = (record: any) => record.iso === editingKey

  const edit = (record: Partial<any> & { key: React.Key }) => {
    form.setFieldsValue({ name: '', age: '', address: '', ...record })

    setEditingKey(record.iso as string)
  }

  const cancel = () => {
    setEditingKey('')
  }

  const save = async (language) => {
    try {
      const row = (await form.validateFields()) as any
      const newData = [...data]
      const index = newData.findIndex((item) => language.id === item.id)

      if (index > -1) {
        const item = newData[index]
        newData.splice(index, 1, {
          ...item,
          ...row,
        })

        const dataToSend = {
          id: language.id,
          ...row,
        }
        onSaveLanguage(dataToSend)

        setData(newData)
        setEditingKey('')
      } else {
        newData.push(row)
        setData(newData)
        setEditingKey('')
      }
    } catch (errInfo) {
      Debug(`${'Validate Failed:'} ${errInfo}`)
    }
  }
  const columns = [
    {
      key: 'id',
      title: t('general.id'),
      dataIndex: 'id',
      width: '10%',
      fixed: true,
      sorter: (a, b) => tableSorter(a.id, b.id),
    },
    {
      key: 'name',
      title: t('general.name'),
      dataIndex: 'name',
      width: '35%',
      editable: true,
      sorter: (a, b) => tableSorter(a.name, b.name),
    },
    {
      key: 'iso',
      title: t('languages.iso'),
      dataIndex: 'iso',
      width: '35%',
      editable: true,
      sorter: (a, b) => tableSorter(a.iso, b.iso),
    },
    {
      key: 'actions',
      title: t('general.actions'),
      dataIndex: 'actions',
      className: 'cell-actions',
      // eslint-disable-next-line react/display-name
      render: (_: any, record: any) => {
        const editable = isEditing(record)
        return editable ? (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <Button type="primary" onClick={() => save(record)} style={{ marginRight: 8 }}>
              {t('btn.save')}
            </Button>
            <Button onClick={() => cancel()} style={{ marginRight: 8 }}>
              {t('btn.cancel')}
            </Button>
          </span>
        ) : (
          <Space>
            <Button type="link" size="small" disabled={editingKey !== ''} onClick={() => edit(record)}>
              <AiOutlineEdit />
            </Button>
            <Button type="link" size="small">
              <Tooltip key={`action-delete-${record.id}`} title={t('tooltip.delete')}>
                <AiOutlineDelete onClick={() => handleDelete(record)} />
              </Tooltip>
            </Button>
          </Space>
        )
      },
    },
  ]

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  const [searchParams] = useSearchParams()
  const dataLength = total || dataSource?.length
  const getTablePagination = () => {
    if (dataLength >= 10) {
      return {
        current: parseInt(searchParams.get('page')) || 1,
        total: dataLength,
        defaultPageSize: TABLE_PAGINATION_DEFAULT_PAGE_SIZE,
        showSizeChanger: dataSource.length > 30,
        pageSizeOptions: TABLE_PAGINATION_DROPDOWN_VALUES,
      }
    }
    return false
  }

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        rowClassName={rowClassName}
        // ref={tableRef}
        rowKey={(record) => record.key}
        scroll={{ x: 768 }}
        pagination={getTablePagination()}
        columns={mergedColumns}
        dataSource={data}
        loading={isLoading}
        onChange={onChange}
        expandable={expandable}
      />
    </Form>
  )
}

EditTableComponent.displayName = 'EditTableComponent'

export default EditTableComponent
