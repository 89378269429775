import {
  editUser,
  getUserDetails,
  listUsers,
  deleteUser,
  getUserRoles,
  createUser,
  editUserPassword,
} from 'api/users'
import * as queryKeys from 'config/queryKeys'

import { useMutation, useQuery, useQueryClient } from 'react-query'
import { mapCreateEditFromUser, mapUser, mapUserList } from 'services/map/admin'
import { UserCreateEditRequest } from 'types/api/admin'
import { User } from 'types/app/admin'
import { UserRole } from 'types/store'

export function useLoadUserList() {
  const queryFunction = async () => {
    const response = await listUsers()
    return mapUserList(response.result)
  }
  return useQuery(queryKeys.userList(), queryFunction, {
    placeholderData: [],
  })
}

export function useLoadUserRoles() {
  const queryFunction = async () => {
    const response = await getUserRoles()
    return response as UserRole[]
  }
  return useQuery(queryKeys.userRoles(), queryFunction, {
    placeholderData: [],
  })
}

export function useLoadUser(userId) {
  const queryFunction = async () => {
    const response = await getUserDetails(userId)
    // if (userId.toUpperCase() === 'NEW') return mapUser({})

    return mapUser(response)
  }

  return useQuery(queryKeys.userDetails(userId), queryFunction, {
    placeholderData: null,
  })
}

export function useEditUser() {
  const mutateFunction = async (user: User) => {
    const mappedData: UserCreateEditRequest = mapCreateEditFromUser(user)
    await editUser(user.id, mappedData)
    return user as User
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (user) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.userDetails(user.id)),
        queryClient.invalidateQueries(queryKeys.userList()),
      ])
    },
  })
  return mutateAsync
}

export function useCreateUser() {
  const mutateFunction = async (user: User) => {
    await createUser(user)
    return user as User
  }
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useMutation(mutateFunction, {
    onSuccess: (user) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.userDetails(user.id)),
        queryClient.invalidateQueries(queryKeys.userList()),
      ])
    },
  })
  return { createUser: mutateAsync, isLoading }
}

export function useChangeUserPassword(id: number) {
  const mutateFunction = async (newPassword: string) => {
    await editUserPassword(newPassword, id)
  }
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useMutation(mutateFunction, {
    onSuccess: () => {
      return Promise.all([queryClient.invalidateQueries(queryKeys.userDetails(id))])
    },
  })
  return { changePassword: mutateAsync, isLoading }
}

export function useDeleteUser() {
  const deleteFunction = async (userId: number) => {
    const result = await deleteUser(userId)

    return result
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(deleteFunction, {
    onSuccess: () => {
      return queryClient.invalidateQueries(queryKeys.userList())
    },
  })
  return mutateAsync
}
