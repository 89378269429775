import React from 'react'
import routes from 'config/routes'
import { generatePath, useNavigate } from 'react-router-dom'
import { Language } from 'types/app/admin'
import { useCreateLanguage } from 'services/query/admin'

import { notification } from 'antd'
import { useTranslation } from 'react-i18next'

import LanguageDetailsNew from 'components/languages/LanguageDetailsNew'

const LanguageDetailsContainer: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const createLanguage = useCreateLanguage()

  const onCreateLanguage = async (model: Language) => {
    try {
      await createLanguage({ ...model })
      navigate(generatePath(routes.languages.url), { replace: true })
      notification.success({ message: t('messagesCrud.languageCreated') })
    } catch (error) {
      console.error(error)
      notification.error({ message: t('messagesCrud.saveError') })
    }
  }

  return <LanguageDetailsNew onCreateLanguage={onCreateLanguage} />
}

export default LanguageDetailsContainer
