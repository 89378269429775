import React, { useState } from 'react'
import routes from 'config/routes'
import { UserRole } from 'types/store'
import { User } from 'types/app/admin'
import { Link } from 'react-router-dom'
import { Operation } from 'types/enums'
import { useTranslation } from 'react-i18next'
import { checkSubmitButtonBeDisabled } from 'core/helpers'

import { Button, Col, Divider, Form, Input, Row, Select, Typography } from 'antd'

interface Props {
  operationType: Operation
  user: User
  roles: UserRole[]
  onFinish: (values: Json) => void
  loading: boolean
}

const ChangeAccountDetails: React.FC<Props> = ({ user, roles, loading, onFinish }) => {
  const { t } = useTranslation()

  const [form] = Form.useForm()

  const [data, setData] = useState(user)

  React.useEffect(() => {
    setData(user)
  }, [user])

  const requiredFields = ['name', 'surname', 'fiscalCode', 'role', 'email']

  return (
    <Form
      form={form}
      name="changeUserDetails"
      initialValues={{
        ...data,
      }}
      onFinish={onFinish}
    >
      <>
        <Typography.Title level={4}>{t('general.basicInfo')}</Typography.Title>
        <Divider className="mt-0" />
        <Row key={data?.id}>
          <Col span={24}>
            <Form.Item
              label={t('user.name')}
              name="name"
              rules={[
                { min: 3, message: t('validationForm.nameCharacters') },
                { required: true, message: t('validationForm.fieldRequired') },
              ]}
            >
              <Input type="text" defaultValue={data?.name} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('user.surname')}
              name="surname"
              rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
            >
              <Input type="text" defaultValue={data?.surname} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('user.role')}
              name="roleId"
              rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
            >
              <Select defaultValue={data?.roleId}>
                {roles.map((role) => (
                  <Select.Option value={role.id} key={role.id}>
                    {role.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('user.email')}
              name="email"
              rules={[
                { type: 'email', message: t('validationForm.emailNotValid') },
                { required: true, message: t('validationForm.fieldRequired') },
              ]}
            >
              <Input type="text" defaultValue={data?.email} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item shouldUpdate className="btn mt-15 align-right">
          {() => (
            <Col xs={24} lg={{ span: 12, offset: 12 }} className="mt-48">
              <Row justify="end" gutter={[12, 12]}>
                <Col xs={12} lg={8}>
                  <Button block htmlType="submit">
                    <Link to={routes.users.url}>{t('btn.cancel')}</Link>
                  </Button>
                </Col>
                <Col xs={12} lg={8}>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={checkSubmitButtonBeDisabled(form, requiredFields)}
                  >
                    {t('btn.save')}
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </Form.Item>
      </>
    </Form>
  )
}

ChangeAccountDetails.displayName = 'ChangeAccountDetails'

export default ChangeAccountDetails
