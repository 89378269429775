import React, { useState } from 'react'
import routes from 'config/routes'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import OrderCreateEdit from 'components/orders/OrderCreateEdit'
import * as Store from 'types/store'

import { notification } from 'antd'
import { useCreateOrder } from 'services/query/orders'

const OrderCreateContainer: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const createOrder = useCreateOrder()

  const [spin, setSpin] = useState(false)

  const onCreate = async (model: Store.Order) => {
    try {
      setSpin(true)
      await createOrder(model)
      setSpin(false)
      navigate(generatePath(routes.orders.url), { replace: true })
      notification.success({ message: t('messagesCrud.orderCreated') })
    } catch (error) {
      console.error(error)
      setSpin(false)
      notification.error({ message: t('messagesCrud.error') })
    }
  }

  return <OrderCreateEdit spin={spin} order={null} onCreate={onCreate} />
}

export default OrderCreateContainer
