import React, { useState } from 'react'
import routes from 'config/routes'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PageHeader from 'components/PageHeader'

import { Card, Row, Col, Button, Input, Form, Spin } from 'antd'
import style from './styles.module.scss'

interface Props {
  onCreateTranslation: (newTranslation) => void
  languages: any
  spin: boolean
}

const TranslationDetailsNew: React.FC<Props> = ({ onCreateTranslation, languages, spin }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    labelTag: '',
    defaultTranslation: '',
    translations: [],
  })
  const inputRefs = React.useRef({})

  // const crumbs = () => [
  //   { route: routes.home },
  //   { route: routes.translations },
  //   { route: routes.translationDetails, routeParam: { key: 'id', value: 'new' } },
  // ]

  const onFinish = () => {
    const translations = []
    languages.map((lan) => {
      translations.push({
        translation: inputRefs.current[lan.iso].input.value,
        languageId: lan.id,
        languageName: lan.name,
        languageIso: lan.iso,
      })
    })

    const newState = {
      ...formData,
      translations: translations,
    }

    onCreateTranslation(newState)
  }

  return (
    <section className={style['lan-details']}>
      <PageHeader backRoute={routes.translations}></PageHeader>
      {/* <TopPage title={t('translations.translationsDetails')} crumbs={crumbs()} /> */}
      <Card>
        {spin ? (
          <Row align="middle" justify="center" style={{ height: '200px' }}>
            <Spin size="large" />
          </Row>
        ) : (
          <Form onFinish={onFinish}>
            <Row className="mt-20">
              <Col span={24}>
                <Form.Item
                  label={t('general.key')}
                  name="name"
                  rules={[
                    { required: true, message: t('validationForm.fieldRequired') },
                    {
                      pattern: /^[a-zA-Z_.]+$/,
                      message: t('validationForm.validationTag'),
                    },
                  ]}
                >
                  <Input
                    value={formData.labelTag}
                    onChange={(e) => setFormData({ ...formData, labelTag: e.target.value })}
                  />
                </Form.Item>
              </Col>
              {languages.map((lan) => {
                return (
                  <Col span={24} key={lan.iso}>
                    <Form.Item key={lan.id} label={lan.iso}>
                      <Input ref={(element) => (inputRefs.current[lan.iso] = element)} />
                    </Form.Item>
                  </Col>
                )
              })}
            </Row>
            <Row className="mt-30">
              <Col xs={24} lg={{ span: 12, offset: 12 }}>
                <Row justify="end" gutter={[12, 12]}>
                  <Col xs={12} lg={8}>
                    <Button block onClick={() => navigate(routes.translations.url)}>
                      {t('btn.cancel')}
                    </Button>
                  </Col>
                  <Col xs={12} lg={8}>
                    <Button block type="primary" htmlType="submit">
                      {t('btn.save')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Card>
    </section>
  )
}
TranslationDetailsNew.displayName = 'TranslationDetailsNew'

export default TranslationDetailsNew
