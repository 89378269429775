import { Table } from 'antd'
import { ColumnType, ColumnsType } from 'antd/lib/table'
import classNames from 'classnames'
import moment from 'moment'
import React, { useState } from 'react'
import { useLoadMachinesPicklist } from 'services/query/picklist'
import { useListPlanningEvents } from 'services/query/planning'
import { PlanningShifts } from 'types/enums'
import * as Store from 'types/store'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { getMachineTypeIcon } from 'core/helpers'
import { useTheme } from 'providers/ThemeProvider'
import { themes } from './themes'
import Debug from 'debug'
import CellPopover from './CellPopover'
import {
  EVENT_HEIGHT,
  EVENT_MIN_WIDTH,
  getCellWidth,
  getColSpan,
  getDailyDuration,
  sortEvents,
} from 'core/tableHelpers'

const debug = Debug('Planning table')

interface Props {
  onEventClick: (event: Store.PlanningEvent) => void
  onEmptyClick: (date: moment.Moment, machineId: number) => void
  startDay: moment.Moment
}

const Planning: React.FC<Props> = ({ onEmptyClick, onEventClick, startDay }) => {
  const dates: moment.Moment[] = []
  const { data: planningEvents } = useListPlanningEvents(startDay.toISOString())
  const { t } = useTranslation()
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const componentClassname = classNames(styles['planning-table'], themeStyles['theme'])

  const [eventHovered, setEventHovered] = useState(0)

  const { data: machines } = useLoadMachinesPicklist()

  const rows = machines.map((machine) => {
    const row: Store.PlanningTableRow = {
      events: planningEvents?.filter((item) => item.machineId === machine.id),
      id: machine.id,
      machine: machine.name,
      shifts: machine.machineShifts,
      typeId: machine.typeId,
    }
    return row
  })

  const dayCounter = startDay.clone()
  for (let i = 0; i < 7; i++) {
    dates.push(dayCounter.clone())
    dayCounter.add(1, 'day')
  }

  const renderEvent = (event: Store.PlanningEvent, style?: React.CSSProperties) => {
    const { customer, numPieces, id, article } = event

    return (
      <CellPopover article={article} customer={customer} numPieces={numPieces}>
        <div
          onMouseEnter={() => setEventHovered(id)}
          onMouseLeave={() => setEventHovered(0)}
          className={classNames('planning-cell', 'event-cell', {
            'event-cell--hovered': id === eventHovered,
          })}
          onClick={() => onEventClick(event)}
          style={style}
        >
          <>
            <p>
              {article} - {customer}
            </p>
            <p>{numPieces}</p>
          </>
        </div>
      </CellPopover>
    )
  }

  const renderEventDay = (events: Store.PlanningEvent[], date: moment.Moment) => {
    const event = events?.find(({ startDate, endDate }) => {
      return startDate.isSameOrBefore(date, 'day') && endDate.isSameOrAfter(date, 'day')
    })

    if (event) {
      return renderEvent(event)
    }

    return null
  }

  const renderEventDayMultiple = (events: Store.PlanningEvent[], shortest: number) => {
    let left = 0
    return events?.map((event, index) => {
      const duration = getDailyDuration(event.startDate, event.endDate)
      const width = (duration / shortest) * EVENT_MIN_WIDTH
      const currentLeft = left
      left += width
      return renderEvent(event, {
        left: `${currentLeft}px`,
        top: `${EVENT_HEIGHT * index}px`,
        width: `${width}px`,
      })
    })
  }

  const columns: ColumnsType = [
    {
      title: t('planning.machineName'),
      key: 'machine',
      dataIndex: 'machine',
      width: 150,
      fixed: 'left',
      render(value, record: Store.PlanningTableRow) {
        return (
          <>
            <img width={20} height={20} src={getMachineTypeIcon(record.typeId)} />
            {value}
          </>
        )
      },
    },
    ...dates.map((date) => {
      return {
        title: date.format('ddd DD/MM'),
        dataIndex: ['shifts'],
        onCell: function Renderer({ events }) {
          return getColSpan(sortEvents(events), date, startDay)
        },
        render: function Renderer(_, { events, id }) {
          const filteredEvents = events?.filter(({ startDate, endDate }) => {
            return date.isBetween(startDate, endDate, 'day', '[]')
          })

          const sortedEvents = sortEvents(filteredEvents)
          const { shortest, width } = getCellWidth(sortedEvents)
          debug('sortedEvents', sortedEvents, width)

          return (
            <div style={{ width: `${width}px`, height: sortedEvents.length * EVENT_HEIGHT }}>
              <div
                className="planning-cell add-event"
                onClick={() => {
                  onEmptyClick(date, id)
                }}
              >
                <div className="description">
                  <p className="mb-40">{date.format('DD/MM/YYYY')}</p>
                </div>
              </div>
              {sortedEvents.length == 1
                ? renderEventDay(events, date)
                : renderEventDayMultiple(sortedEvents, shortest)}
            </div>
          )
        },
      } as ColumnType<Store.PlanningTableRow>
    }),
  ]

  return (
    <Table
      rowKey={(record: Store.PlanningTableRow) => record.id}
      columns={columns}
      dataSource={rows}
      bordered
      pagination={false}
      className={componentClassname}
    />
  )
}

export default Planning
