import React, { useState } from 'react'

import { Dropdown, Menu, Button } from 'antd'
import { Language } from 'types/app/admin'

interface Props {
  languages: Language[]
  initialLanguage: string
  onChange: (picked: string) => void
}

const LanguageSelector: React.FC<Props> = ({ languages, initialLanguage, onChange }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage)

  const onLanguageChange = (e) => {
    const selected = languages.find((i) => i.id.toString() === e.key)
    if (selected) {
      onChange(selected.iso.toLowerCase())
      setSelectedLanguage(selected.iso.toLowerCase())
    }
  }

  const menu = (
    <Menu style={{ width: '300px' }}>
      {languages.map((lang) => {
        return (
          <Menu.Item key={lang.id} onClick={onLanguageChange}>
            <img
              style={{ width: '25px', marginRight: '10px' }}
              src={`/images/flags/${lang.iso.toLowerCase()}.png`}
              alt={lang.iso}
            />
            {lang.name}
          </Menu.Item>
        )
      })}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button type="link" className="p-0">
        <img
          style={{ width: '25px' }}
          src={`/images/flags/${selectedLanguage.toLowerCase()}.png`}
          alt={initialLanguage}
        />
      </Button>
    </Dropdown>
  )
}

export default LanguageSelector
