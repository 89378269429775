import React from 'react'

import { useTranslation } from 'react-i18next'

import { Card, Tag, Typography } from 'antd'
import { useLoadMachinesPicklist } from 'services/query/picklist'
import * as Store from 'types/store'
import * as StoreApp from 'types/app/admin'
import cn from 'classnames'

const { Title } = Typography

interface Props {
  onSelect: (selections: Partial<Store.WorklogStepSelections>) => void
  selected: number
  activityType: StoreApp.ActivityType
  placeHolderImage: string
}

const ChooseMachine: React.FC<Props> = ({ onSelect, selected, activityType, placeHolderImage }) => {
  const { t } = useTranslation()
  const { data: machines } = useLoadMachinesPicklist({ t: activityType?.id })

  return (
    <section className="step-container">
      <Title level={3}>{t('reports.whichMachine')}</Title>

      <div className="list-container list-container--machine">
        {machines.map((machine) => {
          return (
            <Card
              onClick={() => {
                onSelect({ machine: machine.id })
              }}
              className={cn({ 'selected-item': machine.id === selected })}
              key={machine.id}
              size="small"
              title={machine.name}
              hoverable
              style={{ width: 162, height: 300 }}
              cover={<img alt="example" src={placeHolderImage} />}
            >
              <Tag>{t(`machines.activityTypes.${activityType.type.toLowerCase()}`)}</Tag>
            </Card>
          )
        })}
      </div>
    </section>
  )
}

export default ChooseMachine
