import * as queryKeys from 'config/queryKeys'
import { useQuery } from 'react-query'
import { getPicklist } from 'api/picklist'
import * as Api from 'types/api'
import { mapPicklist } from 'services/map/picklist'
import * as Store from 'types/store'

interface QueryOptions {
  t: number
}

export type picklists = 'customers' | 'orders' | 'machines' | 'workers' | 'languages' | 'cards' | 'iotdevices'

const getQueryString = (query: QueryOptions) => {
  if (!query) {
    return ''
  }

  console.log(query, 'QUERY')
  const queryValues = Object.entries(query)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
  return `?${queryValues}`
}

function useLoadPicklist<T>(entity: picklists, query?: QueryOptions) {
  const queryFunction = async () => {
    const entityWithParams = entity + getQueryString(query)
    const response = await getPicklist(entityWithParams)
    return mapPicklist<T>(entity, response)
  }

  return useQuery(queryKeys.picklist(entity), queryFunction, {
    placeholderData: [],
  })
}

export function useLoadCardsOfCustomerPicklist(id: number) {
  const picklist = `cards/${id}`
  const queryFunction = async () => {
    const response = await getPicklist(picklist)
    return response as Api.PicklistCard[]
  }

  return useQuery(queryKeys.picklist(picklist), queryFunction, {
    placeholderData: [],
  })
}

export function useLoadCustomersPicklist() {
  return useLoadPicklist<Api.PicklistCustomer>('customers')
}

export function useLoadCardsPicklist() {
  return useLoadPicklist<Api.PicklistCard>('cards')
}

export function useLoadLanguagesPicklist() {
  return useLoadPicklist<Api.PicklistLanguage>('languages')
}

export function useLoadMachinesPicklist(query?: QueryOptions) {
  return useLoadPicklist<Store.PicklistMachine>('machines', query)
}

export function useLoadOrdersPicklist() {
  return useLoadPicklist<Api.PicklistOrder>('orders')
}

export function useLoadWorkersPicklist() {
  return useLoadPicklist<Api.PicklistWorker>('workers')
}

export function useLoadIotDevicesPicklist() {
  return useLoadPicklist<Api.PicklistIotDevice>('iotdevices')
}
