import { config } from 'core'
import { AxiosError } from 'axios'
import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'
import * as Api from 'types/api'

const axios = getAxios()

export async function getIotDevices(): Promise<Api.IotDevice[]> {
  try {
    const response = await axios.get<Api.IotDevice[]>(
      config.api.iotDevices(),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getDeviceVariables(deviceId: number): Promise<Api.DeviceVariable[]> {
  try {
    const response = await axios.get<Api.DeviceVariable[]>(
      config.api.iotDeviceVariables(deviceId),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getVariableLogs(deviceId: number, variableId: string): Promise<Api.LogsResponse> {
  try {
    const response = await axios.get<Api.LogsResponse>(
      config.api.iotVariable(deviceId, variableId),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
