import React from 'react'

import { Spin } from 'antd'
import styles from './styles.module.scss'

const PlaygroundPage: React.FC = () => {
  return (
    <section className={styles['hplay-ground-page']}>
      <Spin size="large" />
    </section>
  )
}

export default PlaygroundPage
