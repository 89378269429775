import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import { generatePath, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'
import { ActivityType } from 'types/app/admin'
import { MachineActivityType } from 'types/enums'
import { useLoadCustomersPicklist, useLoadMachinesPicklist } from 'services/query/picklist'
import { useLoadActivitySubTypeList } from 'services/query/admin'

import PageHeader from 'components/PageHeader'
import DependsOn from 'components/DependsOn'

import { AiOutlineDelete } from 'react-icons/ai'
import {
  Card,
  Row,
  Col,
  Button,
  Input,
  Form,
  Spin,
  Select,
  Modal,
  Typography,
  Radio,
  Divider,
  notification,
} from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useDeleteProductionCard } from 'services/query/productionCards'
import { filterSearchSelect } from 'core/helpers'

const { Option } = Select

interface Props {
  spin?: boolean
  card: Store.ProductionCard
  activityTypes: ActivityType[]
  onCreate?: (card: Store.ProductionCard) => void
  onUpdate?: (card: Store.ProductionCard) => void
}

const ProductionCardCreateEdit: React.FC<Props> = ({
  spin,
  activityTypes,
  onCreate,
  onUpdate,
  card,
  children,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const deleteCard = useDeleteProductionCard()
  const [images, setImages] = useState(card?.images || [])

  const onDelete = async (card: Store.ProductionCard) => {
    try {
      await deleteCard(card)
      navigate(generatePath(routes.manifacturingCards.url), { replace: true })
      notification.success({ message: t('messagesCrud.machineDeleted') })
    } catch (error) {
      notification.error({ message: t('messagesCrud.deleteError') })
    }
  }

  useEffect(() => {
    card?.images && setImages(card?.images)
  }, [card])

  const { data: machines } = useLoadMachinesPicklist()
  const { data: customers } = useLoadCustomersPicklist()
  const { data: activitySubTypes } = useLoadActivitySubTypeList()

  const [form] = Form.useForm()

  const defaultMachines = card?.machines?.map(({ id }) => id)
  const machinesSelect = (
    <Form.Item
      name="machines"
      label={t('productionCard.machines')}
      rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
    >
      <Select
        allowClear
        mode="multiple"
        defaultValue={defaultMachines}
        showSearch
        filterOption={filterSearchSelect}
      >
        {machines?.map((d) => (
          <Option key={d.name} value={d.id}>
            {d.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )

  const onFinish = (model) => {
    if (model.machines) {
      const machines = model?.machines?.map((machineId) => {
        return {
          id: machineId,
        }
      }) as Store.ProductionCardMachine[]
      model.machines = machines
    }
    model.images = images
    card ? onUpdate(model) : onCreate(model)
  }

  const handleDelete = (imageId?: number) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      onOk() {
        if (imageId && imageId > 0) {
          const imagesFiltered = images.filter((image) => image.id != imageId)
          setImages(imagesFiltered)
        } else {
          onDelete(card)
        }
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  return (
    <>
      <PageHeader
        backRoute={routes.manifacturingCards}
        subtitle={card ? card.customer : ''}
        title={card ? card.article : t('general.createCard')}
      >
        {card && (
          <Button type="text" onClick={() => handleDelete()}>
            <DeleteOutlined />
            {t('btn.deleteCard')}
          </Button>
        )}
      </PageHeader>
      <Card className="production-card">
        <Spin size="large" spinning={spin}>
          <Form
            form={form}
            initialValues={{
              ...card,
              machines: defaultMachines,
            }}
            onFinish={onFinish}
          >
            <Typography.Title level={4}>{t('general.basicInfo')}</Typography.Title>
            <Divider className="mt-0" />
            <Row gutter={[50, 0]}>
              <Col xs={24} lg={12} className="col-dashed-divider">
                <Form.Item
                  name="article"
                  label={t('general.article')}
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <Input type="text" />
                </Form.Item>
                <Form.Item
                  style={{ flexDirection: 'column' }}
                  name="typeId"
                  label={t('machines.activityType')}
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <Radio.Group className="activity-type-radio mt-8" defaultValue={card?.typeId}>
                    {activityTypes.map((activity) => (
                      <Radio.Button
                        className={activity.type.toLowerCase()}
                        value={activity.id}
                        key={activity.id}
                      >
                        {t(`machines.activityTypes.${activity.type.toLowerCase()}`)}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>

                <DependsOn fields={['typeId', 'subTypeId']}>
                  {({ typeId, subTypeId }) => {
                    const type = activitySubTypes.find((subtype) => subtype.id === typeId)
                    if (subTypeId && type && !type.subTypes.find((subType) => subType.id === subTypeId)) {
                      form.setFieldsValue({ subTypeId: null })
                    }

                    const subTypeOptions = type?.subTypes?.map((d) => (
                      <Option key={d.id} value={d.id}>
                        {t(`machine.activitySubType.${d.subType.toLowerCase()}`)}
                      </Option>
                    ))

                    return (
                      <Form.Item
                        name="subTypeId"
                        label={t('general.activitySubtype')}
                        rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                      >
                        <Select allowClear defaultValue={card?.subTypeId}>
                          {subTypeOptions}
                        </Select>
                      </Form.Item>
                    )
                  }}
                </DependsOn>

                <Form.Item
                  name="customerId"
                  label={t('general.customer')}
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <Select
                    allowClear
                    defaultValue={card?.customerId}
                    showSearch
                    filterOption={filterSearchSelect}
                  >
                    {customers?.map((d) => (
                      <Option key={d.name} value={d.id}>
                        {d.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item label={t('general.images')} className="form-item-label-top">
                  {images.length > 0 && (
                    <Row gutter={16}>
                      {images.map((image) => (
                        <Col span={4} key={image.id}>
                          <Card
                            className="card--upload-img"
                            bordered={false}
                            cover={<img src={image.path} />}
                          >
                            <AiOutlineDelete onClick={() => handleDelete(image.id)} />
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  )}
                  {children}
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                <DependsOn fields={['typeId']}>
                  {({ typeId }) => {
                    if (typeId === MachineActivityType.Cutting) {
                      return (
                        <>
                          {machinesSelect}

                          <Form.Item label={t('productionCard.productionTime')} name="productionTime">
                            <Input type="text" defaultValue={card?.productionTime} />
                          </Form.Item>
                        </>
                      )
                    }

                    if (typeId === MachineActivityType.Grinding) {
                      return (
                        <>
                          <Form.Item label={t('productionCard.productionTime')} name="productionTime">
                            <Input type="text" defaultValue={card?.productionTime} />
                          </Form.Item>
                        </>
                      )
                    }

                    if (typeId === MachineActivityType.SandBlasting) {
                      return (
                        <>
                          {machinesSelect}

                          <Form.Item label={t('productionCard.carpetSpeed')} name="carpetSpeed">
                            <Input type="text" defaultValue={card?.carpetSpeed} />
                          </Form.Item>
                          <Form.Item label={t('productionCard.sandTime')} name="sandTime">
                            <Input type="text" defaultValue={card?.sandTime} />
                          </Form.Item>
                          <Form.Item label={t('productionCard.doorOpeningTime')} name="doorOpeningTime">
                            <Input type="text" defaultValue={card?.doorOpeningTime} />
                          </Form.Item>
                          <Form.Item label={t('productionCard.downloadType')} name="downloadType">
                            <Input type="text" defaultValue={card?.downloadType} />
                          </Form.Item>
                          <Form.Item label={t('productionCard.downloadTotalTime')} name="downloadTotalTime">
                            <Input type="text" defaultValue={card?.downloadTotalTime} />
                          </Form.Item>
                          <Form.Item
                            label={t('productionCard.intermittentWorkTime')}
                            name="intermittentWorkTime"
                          >
                            <Input type="text" defaultValue={card?.intermittentWorkTime} />
                          </Form.Item>
                          <Form.Item
                            label={t('productionCard.intermittentPauseTime')}
                            name="intermittentPauseTime"
                          >
                            <Input type="text" defaultValue={card?.intermittentPauseTime} />
                          </Form.Item>
                        </>
                      )
                    }

                    return null
                  }}
                </DependsOn>
                <Form.Item label={t('general.notes')} name="notes">
                  <Input.TextArea defaultValue={card?.notes} autoSize={{ minRows: 6, maxRows: 12 }} />
                </Form.Item>
              </Col>

              <Col xs={24} lg={{ span: 12, offset: 12 }} className="mt-48">
                <Row justify="end" gutter={[12, 12]}>
                  <Col xs={12} lg={8}>
                    <Button block onClick={() => navigate(routes.manifacturingCards.url)}>
                      {t('btn.cancel')}
                    </Button>
                  </Col>
                  <Col xs={12} lg={8}>
                    <Button block type="primary" htmlType="submit">
                      {t('btn.save')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Card>
    </>
  )
}

export default ProductionCardCreateEdit
