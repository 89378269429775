import React from 'react'
import { useParams } from 'react-router-dom'

import ProductionCardCreateContainer from 'containers/productionCards/ProductionCardCreate'
import ProductionCardDetailsContainer from 'containers/productionCards/ProductionCardDetails'

const ProductionCardPage: React.FC = () => {
  const { id } = useParams()

  if (id === 'new') {
    return <ProductionCardCreateContainer />
  }

  return <ProductionCardDetailsContainer />
}

export default ProductionCardPage
