import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { RiSearch2Line } from 'react-icons/ri'
import { Input, Row, Col } from 'antd'
interface Props {
  filter: string
  onFilter: (filter: string) => void
  colSize?: number
  children?: ReactNode
}

const FilterPanel: React.FC<Props> = ({ filter, onFilter, colSize = 8, children }) => {
  const { t } = useTranslation()

  return (
    <Row gutter={[12, 12]} className="mb-24">
      <Col xs={24} lg={colSize}>
        <Input
          allowClear
          defaultValue={filter}
          prefix={<RiSearch2Line size={18} />}
          placeholder={t('general.search')}
          onChange={(filter) => onFilter(filter.target.value)}
        />
      </Col>
      {children}
    </Row>
  )
}

FilterPanel.displayName = 'FilterPanel'

export default FilterPanel
