import { config } from 'core'
import { AxiosError, AxiosResponse } from 'axios'
import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'
import * as Api from 'types/api'
import * as Store from 'types/store'
import { RcFile } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'

const axios = getAxios()

export async function listProductionCard(
  filter: string,
  page: number,
  type: string,
  subtype: number,
  customer: string,
  machine: number,
): Promise<Api.ProductionCardListResponse> {
  try {
    const response = await axios.get<Api.ProductionCardListResponse>(
      config.api.listProductionCard(filter, page, type, subtype, customer, machine),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getProductionCard(machineId: number): Promise<Api.ProductionCard> {
  try {
    const response = await axios.get<Api.ProductionCard>(
      config.api.productionCard(machineId),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function createProductionCard(model: Store.ProductionCard): Promise<Api.NewMachineResponse> {
  try {
    const response = await axios.post(
      config.api.createProductionCard(),
      model,
      await getAuthenticationHeader(),
    )
    return response.data as Api.NewMachineResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function updateProductionCard(model: Store.ProductionCard): Promise<Api.ProductionCard> {
  try {
    await axios.put<Api.ProductionCard>(
      config.api.productionCard(model.id),
      model,
      await getAuthenticationHeader(),
    )
    return model
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function updateProductionCardImages(id, images: number[]): Promise<void> {
  const configs = {
    ...(await getAuthenticationHeader()),
    data: { ImagesIdToKeep: images },
  }
  try {
    await axios.delete<Api.ProductionCard>(config.api.productionCardImageUploadDelete(id), configs)
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function uploadProductionCardImage(image: UploadFile, cardId: number): Promise<boolean> {
  try {
    const formData = new FormData()

    formData.append('file', image as RcFile)
    const { headers } = await getAuthenticationHeader()

    await axios.post<Api.ProductionCard>(config.api.productionCardImageUploadDelete(cardId), formData, {
      headers: { ...headers, 'Content-Type': 'multipart/form-data' },
    })
    return true
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteProductionCard(id: number): Promise<AxiosResponse> {
  try {
    const response = await axios.delete(config.api.productionCard(id), await getAuthenticationHeader())
    return response as AxiosResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export const downloadPDF = async (id: number) => {
  try {
    const header = await getAuthenticationHeader()

    header.responseType = 'blob'
    return await axios.get<Blob>(config.api.productionCardPdf(id), header)
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
