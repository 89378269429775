import * as K from './constants'
import { put, takeLatest, all, call } from 'redux-saga/effects'
import Debug from 'debug'
import { LoadingState, UserOperation } from 'types/enums'
import * as Auth from 'types/api/auth'
import * as authApi from 'api/auth'
import * as Store from 'types/store'
import * as localStore from 'core/localStore'
import { AnyAction } from 'redux'
import { ResponseCode } from 'types/enums.api'

const debug = Debug('Frontend')

export const selectUser = (state) => state.root.user.user

export function* login(action: AnyAction) {
  try {
    yield put({
      type: K.SET_OPERATION_STATUS,
      payload: { operation: UserOperation.Login, status: LoadingState.Loading },
    })

    const { email, password } = action.payload
    const loginRequest: Auth.LoginRequest = {
      email,
      password,
    }

    const loginResponse = yield call(authApi.login, loginRequest)
    if ((loginResponse as ApiError).status) {
      debug('Response', loginResponse, loginResponse as ApiError)

      switch ((loginResponse as ApiError).status) {
        case 401:
          yield put({
            type: K.SET_OPERATION_ERROR,
            payload: { operation: UserOperation.Login, error: ResponseCode.Unauthorized },
          })
          break
        default:
          break
      }

      if (loginResponse.data && (loginResponse.data.message || loginResponse.data.error)) {
        throw new Error(loginResponse.data.message || loginResponse.data.error)
      }
      throw new Error('API Error: login')
    }

    yield localStore.saveUser(loginResponse)
    const claims = localStore.getUserClaims()
    const user: Store.User = {
      email: email,
      name: claims.userName,
    }
    yield put({ type: K.FETCH_USER_SUCCESS, payload: user })

    yield put({
      type: K.SET_OPERATION_STATUS,
      payload: { operation: UserOperation.Login, status: LoadingState.Loaded },
    })
  } catch (error) {
    yield put({
      type: K.SET_OPERATION_STATUS,
      payload: { operation: UserOperation.Login, status: LoadingState.LoadFailure },
    })
    debug('login Error', error)
  }
}

export function* logout() {
  debug('Saga logout')
  yield localStore.clearUser()
}

function* watchUser() {
  yield all([takeLatest(K.LOGIN, login), takeLatest(K.LOGOUT, logout)])
}

export default watchUser
