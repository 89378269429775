// const baseUrl = process.env.REACT_APP_HOMEPAGE

export const apiProvider = function provider(baseUrl: string) {
  let language: string = null

  return {
    api: {
      // vocabulary: this is used by i18n. Do not remove {{lng}} parameter
      vocabulary: () => `${baseUrl}/api/v1/labels/{{lng}}`,
      //local resources
      localVocabulary: () => `${baseUrl}/translations/{{lng}}/labels.json`,

      //user api
      login: () => `${baseUrl}/api/v1/auth/login`,
      confirmEmail: () => `${baseUrl}/api/v1/auth/confirm-email`,
      forgotPassword: () => `${baseUrl}/api/v1/auth/forgot-password`,
      resetPassword: () => `${baseUrl}/api/v1/auth/reset-password`,

      // register: () => `${baseUrl}/auth/cognito/registration`,
      refreshToken: () => `${baseUrl}/auth/refresh-token`,
      //data api
      // getBillings: () => `${baseUrl}/api/custom/my/billing/details`,
      // getBillingAddresses: () => `${baseUrl}/admin/api`,
      // getSupplies: () => `${baseUrl}/admin/api`,
      // getSuppliesAddresses: () => `${baseUrl}/admin/api`,
      // getCustomer: () => `${baseUrl}/admin/api`,

      //customers
      customers: (filter: string, page: string) => `${baseUrl}/api/v1/admin/customer?q=${filter}&n=${page}`,
      createCustomer: () => `${baseUrl}/api/v1/admin/customer`,
      customer: (id: number) => `${baseUrl}/api/v1/admin/customer/${id}`,
      newCustomer: () => `${baseUrl}/api/v1/admin/user`,

      //users
      users: () => `${baseUrl}/api/v1/admin/user`,
      user: (userId) => `${baseUrl}/api/v1/admin/user/${userId}`,
      userChangePassword: (userId) => `${baseUrl}/api/v1/admin/user/change-password/${userId}`,
      newUser: () => `${baseUrl}/api/v1/admin/user`,
      userRoles: () => `${baseUrl}/api/v1/admin/user/roles`,
      // deleteUser: (userId) => `${baseUrl}/api/users/${userId}`,

      //users
      newWorker: () => `${baseUrl}/api/v1/admin/worker`,
      worker: (workerId) => `${baseUrl}/api/v1/admin/worker/${workerId}`,
      workers: (filter: string, page: string, status: string) =>
        `${baseUrl}/api/v1/admin/worker?q=${filter}&n=${page}&st=${status}`,

      // languages
      listLanguages: () => `${baseUrl}/api/v1/admin/language`,
      language: (languageId) => `${baseUrl}/api/v1/admin/language/${languageId}`,
      newLanguage: () => `${baseUrl}/api/v1/admin/language`,

      //api mock
      languages: () => `${baseUrl}/api/languages`,
      translations: () => `${baseUrl}/api/translations`,

      //activity type
      listActivityTypes: () => `${baseUrl}/api/v1/admin/activity-types`,
      listActivitySubTypes: () => `${baseUrl}/api/v1/admin/activity-types/subtypes`,

      //machines
      listMachines: (filter: string, page: number, type: string) =>
        `${baseUrl}/api/v1/admin/machine?q=${filter}&n=${page}&type=${type}`,
      createMachine: () => `${baseUrl}/api/v1/admin/machine`,
      machine: (id) => `${baseUrl}/api/v1/admin/machine/${id}`,

      //planning
      createPlanning: () => `${baseUrl}/api/v1/admin/planning`,
      planningEvent: (id: number) => `${baseUrl}/api/v1/admin/planning/${id}`,
      listPlanningEvents: (startDate: string) => `${baseUrl}/api/v1/admin/planning?sz=100&date=${startDate}`,

      //shiftplanning
      createShiftPlanning: () => `${baseUrl}/api/v1/admin/shift-planning`,
      shiftPlanning: (id: number) => `${baseUrl}/api/v1/admin/shift-planning/${id}`,
      listShiftPlanning: (startDate: string) => `${baseUrl}/api/v1/admin/shift-planning?date=${startDate}`,
      getShiftPlanningCSV: (startDate: string) =>
        `${baseUrl}/api/v1/admin/shift-planning/csv?date=${startDate}`,
      getShiftPlanningPDF: (startDate: string) =>
        `${baseUrl}/api/v1/admin/shift-planning/pdf?date=${startDate}`,

      //reporting
      createWorklogByWorker: (forced) => `${baseUrl}/api/v1/work-report?f=${forced}`,
      createWorklogByAdmin: () => `${baseUrl}/api/v1/admin/work-report`,
      getWorklogList: (
        card: string,
        page: string,
        worker: string,
        machine: string,
        dateStart: string,
        dateEnd: string,
      ) =>
        `${baseUrl}/api/v1/admin/work-report?&n=${page}&w=${worker}&m=${machine}&c=${card}&ds=${dateStart}&de=${dateEnd}`,
      worklog: (id) => `${baseUrl}/api/v1/admin/work-report/${id}`,

      //production cards
      listProductionCard: (
        filter: string,
        page: number,
        type: string,
        subtype: number,
        customer: string,
        machine: number,
      ) =>
        `${baseUrl}/api/v1/product-cards?q=${filter}&n=${page}&type=${type}&stype=${subtype}&c=${customer}&m=${machine}`,
      createProductionCard: () => `${baseUrl}/api/v1/product-cards`,
      productionCard: (id) => `${baseUrl}/api/v1/product-cards/${id}`,
      productionCardPdf: (id) => `${baseUrl}/api/v1/product-cards/pdf/${id}`,
      productionCardImageUploadDelete: (cardId: number) => `${baseUrl}/api/v1/product-cards/image/${cardId}`,

      //orders
      listOrders: (filter: string, page: number, customer: string, status: string, card: string) =>
        `${baseUrl}/api/v1/admin/order?q=${filter}&n=${page}&customerId=${customer}&status=${status}&card=${card}`,
      order: (id) => `${baseUrl}/api/v1/admin/order/${id}`,
      createOrder: () => `${baseUrl}/api/v1/admin/order`,

      //translations
      listTranslations: (filter: string, page: number) =>
        `${baseUrl}/api/v1/admin/label?q=${filter}&n=${page}`,
      translation: (id) => `${baseUrl}/api/v1/admin/label/${id}`,
      createTranslation: () => `${baseUrl}/api/v1/admin/label`,

      //picklists
      picklist: (entity) => `${baseUrl}/api/v1/picklist/${entity}`,

      //dashboard
      dashboardDaily: () => `${baseUrl}/api/v1/dashboard/daily-data`,
      dashboardArticlesData: () => `${baseUrl}/api/v1/dashboard/articles`,
      dashboardMachinesData: () => `${baseUrl}/api/v1/dashboard/machines`,
      dashboardPeriodData: (start?: string | undefined, end?: string | undefined) =>
        `${baseUrl}/api/v1/dashboard/period-data${start && end ? `?s=${start}&e=${end}` : ''}`,

      // IoT Data
      iotDevices: () => `${baseUrl}/api/v1/iot/devices`,
      iotDeviceVariables: (id) => `${baseUrl}/api/v1/iot/devices/${id}/variables`,
      iotVariable: (deviceId: number, variableId: string) =>
        `${baseUrl}/api/v1/iot/devices/${deviceId}/variables/${variableId}`,

      //reports
      reportsMachinesData: (dateStart: string, dateEnd: string) =>
        `${baseUrl}/api/v1/report/by-machine?s=${dateStart}&e=${dateEnd}`,
      reportsActivityTypesData: (dateStart: string, dateEnd: string) =>
        `${baseUrl}/api/v1/report/by-activity?s=${dateStart}&e=${dateEnd}`,
      reportsArticlesData: (dateStart: string, dateEnd: string) =>
        `${baseUrl}/api/v1/report/by-article?s=${dateStart}&e=${dateEnd}`,
      generatePdfReportsData: (dateStart: string, dateEnd: string, type: string) =>
        `${baseUrl}/api/v1/report/${type}/pdf?s=${dateStart}&e=${dateEnd}`,
      generateCSVReportsData: (dateStart: string, dateEnd: string, type: string, languageIso: string) =>
        `${baseUrl}/api/v1/report/${type}/csv?s=${dateStart}&e=${dateEnd}&lang=${languageIso}`,
    },
    getLanguage: () => language,
    setLanguage: (lng: string): void => {
      language = lng
    },
  }
}
