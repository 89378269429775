import { Button, Col, DatePicker, Divider, Form, Input, Row, Select } from 'antd'
import DependsOn from 'components/DependsOn'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useLoadCardsOfCustomerPicklist,
  useLoadCustomersPicklist,
  useLoadMachinesPicklist,
} from 'services/query/picklist'
import style from '../styles.module.scss'
import * as Api from 'types/api'
import { ModalOptions } from 'containers/Planning/Planning'
import { DeleteOutlined } from '@ant-design/icons'
import { filterSearchSelect } from 'core/helpers'

const { Option } = Select

interface Form {
  startDate: moment.Moment
  endDate: moment.Moment
  numPieces: number
  customerId: number
  orderId: number
  cardId: number
  machineId: number
}

interface Props {
  modalOptions: ModalOptions
  onFinish: (formData: Api.PlanningForm) => void
  onDelete: () => void
  initialValues: Partial<Form>
}

const PlanningModal: React.FC<Props> = ({ modalOptions: { event }, onFinish, onDelete, initialValues }) => {
  const { t } = useTranslation()
  const [costumer, setCostumer] = useState(event?.customerId || 0)
  const { data: customers } = useLoadCustomersPicklist()
  const { data: cards } = useLoadCardsOfCustomerPicklist(costumer)
  const { data: machines } = useLoadMachinesPicklist()

  const onBeforeFinish = ({
    cardId,
    customerId,
    endDate,
    machineId,
    numPieces,

    startDate,
  }: Form) => {
    const formData: Api.PlanningForm = {
      cardId,
      customerId,
      endDate: endDate.toISOString(),
      machineId,
      numPieces,
      startDate: startDate.toISOString(),
    }

    onFinish(formData)
  }

  return (
    <Form onFinish={onBeforeFinish} initialValues={initialValues}>
      <Form.Item
        name="customerId"
        label={t('general.customer')}
        rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
      >
        <Select
          allowClear
          showSearch
          filterOption={filterSearchSelect}
          onChange={(value: number) => setCostumer(value)}
        >
          {customers?.map((d) => (
            <Option key={d.id} value={d.id}>
              {d.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <DependsOn fields={['customerId']}>
        {({ customerId }) => {
          return (
            <Form.Item
              name="cardId"
              label={t('general.card')}
              rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
            >
              <Select allowClear disabled={!customerId} showSearch filterOption={filterSearchSelect}>
                {cards?.map((d) => (
                  <Option key={d.article} value={d.id}>
                    {d.article}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )
        }}
      </DependsOn>

      <Form.Item
        name="numPieces"
        label={t('general.pieces')}
        rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
      >
        <Input />
      </Form.Item>
      <Divider />
      <Form.Item
        name="machineId"
        label={t('general.machine')}
        rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
      >
        <Select allowClear showSearch filterOption={filterSearchSelect}>
          {machines?.map((d) => (
            <Option key={d.name} value={d.id}>
              {d.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <DependsOn fields={['machineId']}>
        {({ machineId }) => {
          return (
            <Form.Item
              name="startDate"
              label={t('general.startDate')}
              rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
            >
              <DatePicker
                showTime
                format={'DD/MM/YYYY HH:mm'}
                dropdownClassName={style['planning-date-picker']}
                showNow={false}
                disabled={!machineId}
              />
            </Form.Item>
          )
        }}
      </DependsOn>
      <DependsOn fields={['startDate', 'machineId']}>
        {({ startDate, machineId }) => {
          return (
            <Form.Item
              name="endDate"
              label={t('general.endDate')}
              rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
            >
              <DatePicker
                showTime
                format={'DD/MM/YYYY HH:mm'}
                disabledDate={(date: moment.Moment) => date.isSameOrBefore(startDate)}
                dropdownClassName={style['planning-date-picker']}
                showNow={false}
                disabled={!machineId || !startDate}
              />
            </Form.Item>
          )
        }}
      </DependsOn>
      <Row align="middle" justify="space-between">
        <Col>
          {onDelete && (
            <Form.Item className="mb-0">
              <Button type="text" onClick={onDelete}>
                <DeleteOutlined /> {t('btn.delete')}
              </Button>
            </Form.Item>
          )}
        </Col>

        <Col>
          <Form.Item className="mb-0">
            <Button type="primary" htmlType="submit">
              {t('btn.save')}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default PlanningModal
