import React, { useState } from 'react'
import routes from 'config/routes'
import { useRouter } from 'core/useRouter'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import Loading from 'components/Loading'

import { Modal, notification, Typography } from 'antd'
import WorklogForm from 'components/WorkReportAdmin/WorklogForm'
import { useDeleteWorklog, useEditWorklog, useLoadWorklog } from 'services/query/worklog'
import * as Api from 'types/api'
import ReportDetails from 'components/WorkReportAdmin/WorklogDetails'

const WorklogDetailsContainer: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()
  const { mutateAsync: updateWorklog } = useEditWorklog(parseInt(id))

  const router = useRouter()
  const [editMode] = useState(router.query.editmode ? true : false)
  const { data: worklog } = useLoadWorklog(parseInt(id))

  const [spin, setSpin] = useState(false)

  const onUpdate = async (model: Api.WorklogAdminForm) => {
    try {
      setSpin(true)
      const data: Api.WorklogPost = {
        cardId: model.cardId,
        date: model.date.toISOString(),
        machineId: model.machineId,
        notes: model.notes,
        numPieces: model.numPieces,
        shift: model.shift,
        workerId: model.workerId,
      }
      await updateWorklog(data)
      navigate(generatePath(routes.workReports.url), { replace: true })
      notification.success({ message: t('messagesCrud.worklogUpdated') })
    } catch (error) {
      console.error(error)
      notification.error({ message: t('messagesCrud.worklogUpdateError') })
    } finally {
      setSpin(false)
    }
  }

  const { mutateAsync: deleteReport } = useDeleteWorklog()

  const onDelete = () => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      async onOk() {
        try {
          await deleteReport(worklog.id)
          navigate(generatePath(routes.workReports.url), { replace: true })
          notification.success({ message: t('messagesCrud.worklogDeleted') })
        } catch (error) {
          notification.error({ message: t('messagesCrud.deleteError') })
        }
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  if (worklog === null) {
    return <Loading />
  }

  if (editMode) {
    return <WorklogForm spin={spin} worklog={worklog} onFinish={onUpdate} onDelete={onDelete} />
  }
  return <ReportDetails worklog={worklog} onDelete={onDelete} />
}

export default WorklogDetailsContainer
