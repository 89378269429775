import React, { useState } from 'react'
import routes from 'config/routes'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PageHeader from 'components/PageHeader'
import * as Store from 'types/store'

import { Machine } from 'types/store'
import { ActivityType } from 'types/app/admin'
import { MachineActivityType, MachineShifts } from 'types/enums'
import { DeleteOutlined } from '@ant-design/icons'

import Loading from 'components/Loading'

import { Card, Row, Col, Button, Input, Form, InputNumber, Divider, Typography, Radio } from 'antd'
import { CheckboxOptionType } from 'antd/lib/checkbox/Group'

interface Props {
  spin?: boolean
  machine: Machine
  activityTypes: ActivityType[]
  onCreate?: (machine: Machine) => void
  onUpdate?: (machine: Machine) => void
  onDelete?: (machine: Store.Machine) => void
}

const MachineCreateEdit: React.FC<Props> = ({
  spin,
  machine,
  activityTypes,
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const options: CheckboxOptionType[] = [
    { value: MachineShifts.OneDailyShift, label: t('machines.shifts.oneDailyShift') },
    { value: MachineShifts.OneShiftPerDay, label: t('machines.shifts.oneShiftPerDay') },
    { value: MachineShifts.TwoShiftsPerDay, label: t('machines.shifts.twoShiftPerDay') },
    { value: MachineShifts.ThreeShiftsPerDay, label: t('machines.shifts.threeShiftPerDay') },
  ]

  const [form] = Form.useForm()
  const [machinType, setMachineType] = useState(machine ? machine.typeId : MachineActivityType.Cutting)

  return (
    <>
      <PageHeader
        backRoute={routes.machines}
        title={machine ? machine.nickname : t('menu.newMachine')}
        subtitle={machine ? `ID: ${machine.number}` : ''}
        classname={machine ? `with-icon with-icon--${machine.type.toLowerCase()}` : ''}
      >
        {machine && (
          <Button type="text" onClick={() => onDelete(machine)}>
            <DeleteOutlined />
            {t('machine.delete')}
          </Button>
        )}
      </PageHeader>
      <Card>
        {spin ? (
          <Loading />
        ) : (
          <Form
            form={form}
            name="changeMachineDetails"
            initialValues={machine}
            onFinish={machine ? onUpdate : onCreate}
          >
            <Typography.Title level={4}>{t('general.basicInfo')}</Typography.Title>
            <Divider className="mt-0" />
            <Row gutter={[50, 0]}>
              <Col xs={24} lg={12} className="col-dashed-divider">
                <Form.Item
                  label={t('machines.nickname')}
                  name="nickname"
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <Input type="text" />
                </Form.Item>

                <Form.Item
                  label={t('machines.number')}
                  name="number"
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <InputNumber />
                </Form.Item>

                <Form.Item
                  style={{ flexDirection: 'column' }}
                  name="typeId"
                  label={t('machines.activityType')}
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <Radio.Group
                    className="activity-type-radio mt-8"
                    defaultValue={machine?.type}
                    onChange={(value) => setMachineType(parseInt(`${value.target.value}`))}
                  >
                    {activityTypes.map((activity) => (
                      <Radio.Button
                        className={activity.type.toLowerCase()}
                        value={activity.id}
                        key={activity.id}
                      >
                        {t(`machines.activityTypes.${activity.type.toLowerCase()}`)}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
                <Form.Item label={t('machine.machineShifts')} name="machineShifts">
                  <Radio.Group options={options} />
                </Form.Item>
              </Col>

              {(machinType === MachineActivityType.Cutting ||
                machinType === MachineActivityType.SandBlasting) && (
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t('machines.ip')}
                    name="ip"
                    rules={[
                      { required: true, message: t('validationForm.fieldRequired') },
                      {
                        pattern: /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/,
                        message: t('validationForm.validationTag'),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label={t('machines.model')}
                    name="model"
                    rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="year"
                    label={t('machines.year')}
                    rules={[
                      { required: true, message: t('validationForm.fieldRequired') },
                      {
                        pattern: /^\d{4}$/,
                        message: t('validationForm.validationTag'),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              )}

              <Col xs={24} lg={{ span: 12, offset: 12 }} className="mt-48">
                <Row justify="end" gutter={[12, 12]}>
                  <Col xs={12} lg={8}>
                    <Button block onClick={() => navigate(routes.machines.url)}>
                      {t('btn.cancel')}
                    </Button>
                  </Col>
                  <Col xs={12} lg={8}>
                    <Button block type="primary" htmlType="submit" loading={spin}>
                      {t('btn.save')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Card>
    </>
  )
}

export default MachineCreateEdit
