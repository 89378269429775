import * as Store from 'types/store'
import * as queryKeys from 'config/queryKeys'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { listOrders, getOrder, createOrder, updateOrder, deleteOrder } from 'api/orders'
import { mapOrderList, mapOrder } from 'services/map/orders'

export function useLoadOrderList(
  filter: string,
  page: number,
  customer: string,
  status: string,
  card: string,
) {
  const queryFunction = async () => {
    const response = await listOrders(filter, page, customer, status, card)
    return mapOrderList(response)
  }

  return useQuery(queryKeys.orderListFilter(filter, page, customer, status, card), queryFunction, {
    placeholderData: {
      currentPage: 1,
      result: [],
      totalItems: 0,
      totalPages: 0,
    },
  })
}

export function useLoadOrder(id: number) {
  const queryFunction = async () => {
    const response = await getOrder(id)
    return mapOrder(response)
  }

  return useQuery(queryKeys.productionCard(id), queryFunction, {
    placeholderData: null,
  })
}

export function useCreateOrder() {
  const mutateFunction = async (order: Store.Order) => {
    const response = await createOrder(order)
    order.id = response.id
    return order as Store.Order
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (productionCard: Store.Order) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.order(productionCard.id)),
        queryClient.invalidateQueries(queryKeys.orderList()),
      ])
    },
  })
  return mutateAsync
}

export function useEditOrder() {
  const mutateFunction = async (order: Store.Order) => {
    await updateOrder(order)
    return order
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (order) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.order(order.id)),
        queryClient.invalidateQueries(queryKeys.orderList()),
      ])
    },
  })
  return mutateAsync
}

export function useDeleteOrder() {
  const mutateFunction = async (order: Store.Order) => {
    await deleteOrder(order.id)
    return order
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (order) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.order(order.id)),
        queryClient.invalidateQueries(queryKeys.orderList()),
      ])
    },
  })
  return mutateAsync
}
