import React from 'react'
import { getAuthToken, getUserClaims } from 'core/localStore'
import { Navigate } from 'react-router'
import routes from 'config/routes'
import { useLocation } from 'react-router-dom'
import { getCurrentRoute, isRouteAuthorizedForUser } from 'core/helpers'
import { InvalidTokenError } from 'jwt-decode'

const AuthProvider: React.FC = ({ children }) => {
  const getUser = (): [boolean, string] => {
    try {
      const token = getAuthToken()
      const isAuthenticated = token != null ? true : false
      const userClaims = getUserClaims()

      if (userClaims) {
        return [isAuthenticated, userClaims.userRole]
      }
    } catch (e) {
      if (e.name === InvalidTokenError.name || e.name === TypeError.name) {
        return [false, null]
      }
    }
    return [null, null]
  }

  const [isAuthenticated, role] = getUser()

  const location = useLocation()
  const currentRoute = getCurrentRoute(location.pathname)

  if (role && !isRouteAuthorizedForUser(role, currentRoute)) {
    return <Navigate to={routes.error.url} />
  }

  if (isAuthenticated === null) {
    return null
  }

  if (isAuthenticated === false) {
    return <Navigate to={routes.login.url} />
  }

  return <>{children}</>
}

export default AuthProvider
