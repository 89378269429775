import React from 'react'
import { useParams } from 'react-router-dom'

import LanguageDetailsContainer from 'containers/languages/LanguageDetails'

const LanguageDetails: React.FC = () => {
  const { id } = useParams()
  if (id === 'new') return <LanguageDetailsContainer />
}

LanguageDetails.displayName = 'LanguageDetails'

export default LanguageDetails
