import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams, useParams } from 'react-router-dom'
import { ReportGroups } from 'types/enums'
import { Typography } from 'antd'
import { saveUser } from 'core/localStore'
import moment from 'moment'
const { Title } = Typography
import LogoFullBlack from 'assets/logo-full-black.svg'
import ReportByArticle from 'components/ReportGeneratePdf/ReportByArticle'
import ReportByMachine from 'components/ReportGeneratePdf/ReportByMachine'
import ReportByActivityType from 'components/ReportGeneratePdf/ReportByActivityType'
import colors from 'config/colors'

type SearchParams = {
  type: string
}

const ReportGeneratePdfContainer: React.FC = () => {
  const [searchParams] = useSearchParams()
  const { type } = useParams<SearchParams>()
  const [hasTokenSaved, setIsTokenSaved] = useState(false)
  const { t } = useTranslation()

  const start = searchParams.get('start') || ''
  const end = searchParams.get('end') || ''
  const token = searchParams.get('token') || ''

  const saveToken = async () => {
    await saveUser({ refreshToken: '', token: token })
    setIsTokenSaved(true)
  }

  useEffect(() => {
    saveToken()
  }, [token])

  const render = () => {
    switch (type) {
      case ReportGroups.ByActivityType:
        return <ReportByActivityType dateStart={start} dateEnd={end} />
      case ReportGroups.ByMachine:
        return <ReportByMachine dateStart={start} dateEnd={end} />
      case ReportGroups.ByArticle:
        return <ReportByArticle dateStart={start} dateEnd={end} />
      default:
        return null
    }
  }

  const getTitle = () => {
    switch (type) {
      case ReportGroups.ByActivityType:
        return t('reports.reportbyActivity')
      case ReportGroups.ByMachine:
        return t('reports.reportbyMachines')
      case ReportGroups.ByArticle:
        return t('reports.reportbyArticles')
      default:
        return null
    }
  }

  const getDate = () => {
    const startDay = start !== '' ? moment(start) : moment().startOf('month')
    const endDay = end !== '' ? moment(end) : moment().endOf('month')

    const datePeriod = `${t('general.periodFrom')} ${startDay.format('DD/MM/YYYY')} - ${t(
      'general.periodTo',
    )} ${endDay.format('DD/MM/YYYY')}`

    return datePeriod
  }

  const renderHeader = () => {
    const date = getDate()
    const title = getTitle()
    return (
      <div
        className="mb-20"
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <div>
          <Title className="mb-8" level={2}>
            {title}
          </Title>
          <Title className="mt-0" style={{ fontWeight: 500, color: '#5e626c' }} level={4}>
            {t('general.periodDate', { date: date })}
          </Title>
        </div>

        <div className="logo">
          <img width={130} alt="logo" src={LogoFullBlack} />
        </div>
      </div>
    )
  }

  if (!hasTokenSaved) {
    return null
  }

  return (
    <section style={{ backgroundColor: colors.white }}>
      {renderHeader()}
      {render()}
    </section>
  )
}

export default ReportGeneratePdfContainer
