import React from 'react'

import { useTranslation } from 'react-i18next'

import { Avatar, Card, Typography } from 'antd'
import { useLoadWorkersPicklist } from 'services/query/picklist'
import { acronymify } from 'core/helpers'
import cn from 'classnames'
import * as Store from 'types/store'

const { Title } = Typography

interface Props {
  onSelect: (selections: Partial<Store.WorklogStepSelections>) => void
  selected: number
}

const ChooseYourself: React.FC<Props> = ({ onSelect, selected }) => {
  const { t } = useTranslation()
  const { data: workers } = useLoadWorkersPicklist()

  return (
    <section className="step-container">
      <Title level={3}>{t('reports.who')}</Title>

      <div className="list-container list-container--worker">
        {workers.map((worker) => {
          return (
            <Card
              onClick={() => {
                onSelect({ worker: worker.id })
              }}
              className={cn({ 'selected-item': worker.id === selected })}
              key={worker.id}
              size="small"
              title={worker.fullName}
              hoverable
              style={{ width: 162, height: 206 }}
              cover={
                <Avatar className="acronym" size={100}>
                  {acronymify(worker.fullName, 2).toUpperCase()}
                </Avatar>
              }
            ></Card>
          )
        })}
      </div>
    </section>
  )
}

export default ChooseYourself
