import React, { useState } from 'react'
import routes from 'config/routes'
import { Machine } from 'types/store'
import { useRouter } from 'core/useRouter'
import { useTranslation } from 'react-i18next'
import { useLoadActivityTypeList } from 'services/query/admin'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useLoadMachine, useEditMachine, useDeleteMachine } from 'services/query/machines'
import * as Store from 'types/store'

import Loading from 'components/Loading'
import MachineDetails from 'components/machines/MachineDetails'
import MachineCreateEdit from 'components/machines/MachineCreateEdit'

import { Modal, notification, Typography } from 'antd'

const MachineDetailsContainer: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const updateMachine = useEditMachine()
  const { data: activityTypes } = useLoadActivityTypeList()

  const { id } = useParams()
  const router = useRouter()
  const [editMode] = useState(router.query.editmode ? true : false)
  const { data: machine } = useLoadMachine(parseInt(id))

  const [spin, setSpin] = useState(false)

  const onUpdate = async (model: Machine) => {
    model.id = parseInt(id)
    try {
      setSpin(true)
      await updateMachine(model)
      navigate(generatePath(routes.machines.url), { replace: true })
      notification.success({ message: t('messagesCrud.machineUpdated') })
    } catch (error) {
      console.error(error)
      notification.error({ message: t('messagesCrud.machineUpdateError') })
    } finally {
      setSpin(false)
    }
  }

  const deleteMachine = useDeleteMachine()
  const onDelete = (machine: Store.Machine) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      async onOk() {
        try {
          await deleteMachine(machine)
          navigate(generatePath(routes.machines.url), { replace: true })
          notification.success({ message: t('messagesCrud.machineDeleted') })
        } catch (error) {
          notification.error({ message: t('messagesCrud.deleteError') })
        }
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  if (machine === null) {
    return <Loading />
  }

  if (editMode) {
    return (
      <MachineCreateEdit
        spin={spin}
        machine={machine}
        onUpdate={onUpdate}
        activityTypes={activityTypes}
        onDelete={onDelete}
      />
    )
  }
  return <MachineDetails machine={machine} onDelete={onDelete} />
}

export default MachineDetailsContainer
