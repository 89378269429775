import {
  createCustomer,
  deleteCustomer,
  editCustomer,
  getCustomerDetails,
  listCustomers,
} from 'api/customers'
import * as queryKeys from 'config/queryKeys'

import { useMutation, useQuery, useQueryClient } from 'react-query'
import { CustomerCreateEditRequest } from 'types/api'
import { Customers } from 'types/store'

export function useLoadCustomersList(filter: string, page: string) {
  const queryFunction = async () => {
    const response = await listCustomers(filter, page)
    return response as Customers
  }
  return useQuery(queryKeys.customerListFilter(filter, page), queryFunction, {
    placeholderData: { currentPage: 1, result: [], totalItems: 0, totalPages: 0 },
  })
}

export function useLoadCustomer(id) {
  const queryFunction = async () => {
    const response = await getCustomerDetails(id)
    // if (userId.toUpperCase() === 'NEW') return mapUser({})

    return response
  }

  return useQuery(queryKeys.customerDetails(id), queryFunction, {
    placeholderData: null,
  })
}

export function useEditCustomer(id) {
  const mutateFunction = async (user: CustomerCreateEditRequest) => {
    await editCustomer(id, user)
  }
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useMutation(mutateFunction, {
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.customerDetails(id)),
        queryClient.invalidateQueries(queryKeys.customerList()),
      ])
    },
  })
  return { editCustomer: mutateAsync, isEditing: isLoading }
}

export function useCreateCustomer() {
  const mutateFunction = async (customer: CustomerCreateEditRequest) => {
    const response = await createCustomer(customer)
    return response
  }
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useMutation(mutateFunction, {
    onSuccess: (response) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.customerDetails(response.id)),
        queryClient.invalidateQueries(queryKeys.customerList()),
      ])
    },
  })
  return { createCustomer: mutateAsync, isLoading }
}

export function useDeleteCustomer() {
  const deleteFunction = async (id: number) => {
    const result = await deleteCustomer(id)

    return result
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(deleteFunction, {
    onSuccess: () => {
      return queryClient.invalidateQueries(queryKeys.customerList())
    },
  })
  return mutateAsync
}
