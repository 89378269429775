import * as K from './constants'
import produce from 'immer'
import { LoadingState } from 'types/enums'
import { AnyAction } from 'redux'
import * as Store from 'types/store'
import { ResponseCode } from 'types/enums.api'

export const initialState: Store.UserState = {
  state: LoadingState.Unset,
  user: null,
  settings: {
    selectedLanguage: 'it',
  },
  login: {
    status: LoadingState.Unset,
    error: null,
  },
  error: null,
}

const reducer = (state = initialState, action: AnyAction) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case K.SET_OPERATION_STATE:
        draft[action.payload.operation] = action.payload.state
        break
      case K.SET_OPERATION_STATUS:
        draft[action.payload.operation] = {
          ...draft[action.payload.operation],
          status: action.payload.status,
        }
        break
      case K.SET_OPERATION_ERROR:
        draft[action.payload.operation] = {
          ...draft[action.payload.operation],
          error: action.payload.error,
        }
        break
      case K.SET_STATUS:
        draft.state = action.payload as LoadingState
        break
      case K.FETCH_USER_SUCCESS:
        draft.user = action.payload as Store.User
        break
      case K.SET_LOADING_STATE:
        const loadingState = action.payload as LoadingState
        draft.state = loadingState
        break
      case K.SET_USER_LANGUAGE:
        draft.settings = {
          ...draft.settings,
          selectedLanguage: action.payload as string,
        }
        break
      case K.SET_ERROR:
        draft.error = action.payload as ResponseCode
        break
      case K.LOGOUT:
        return {
          user: initialState.user,
          login: initialState.login,
        }
    }
  })
}

export default reducer
