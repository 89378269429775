import { Modal, notification, Spin } from 'antd'
import ShiftPlanningForm from 'components/ShiftPlanning/ShiftPlanningForm'

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCreateShiftPlanning } from 'services/query/shiftPlanning'
import * as Api from 'types/api'
import { WorkerShifts } from 'types/enums'
import { ModalOptions } from '../ShiftPlanning'

interface Props {
  onClose: () => void
  modalOptions: ModalOptions
}

const ShiftPlanningCreate: React.FC<Props> = ({ modalOptions, onClose }) => {
  const { visible, startDate, shiftPlanning, workerId } = modalOptions
  const [createShiftPlanning, isLoading] = useCreateShiftPlanning()
  const { t } = useTranslation()

  const onFinish = async (shift: WorkerShifts) => {
    try {
      const formData: Api.ShiftPlanningCreateForm = {
        shift: shift,
        year: startDate.year(),
        week: startDate.week(),
        workerId,
      }

      await createShiftPlanning(formData)
      notification.success({ message: t('messagesCrud.eventCreated') })
      onClose()
    } catch {
      notification.error({ message: t('messagesCrud.errorCreate') })
    }
  }

  return (
    <Modal
      visible={visible && !shiftPlanning}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      title={t('shiftPlanning.createTitle')}
    >
      <Spin spinning={isLoading}>
        <ShiftPlanningForm
          modalOptions={modalOptions}
          onFinish={onFinish}
          onDelete={null}
          initialValues={{}}
        />
      </Spin>
    </Modal>
  )
}

export default ShiftPlanningCreate
