import React from 'react'
import { Form } from 'antd'
import { Store } from 'antd/lib/form/interface'

interface Props {
  fields: Array<string>
  children: (values: Store) => void
}

const DependsOn: React.FC<Props> = (props) => {
  const { fields, children } = props

  const shouldUpdate = (prevValues: Store, currentValues: Store) => {
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i]
      if (prevValues[field] !== currentValues[field]) {
        return true
      }
    }

    return false
  }

  const renderer = ({ getFieldValue }) => {
    const values: Store = {}
    fields.forEach((field) => {
      values[field] = getFieldValue(field)
    })

    return children(values)
  }

  return (
    <Form.Item noStyle shouldUpdate={shouldUpdate}>
      {renderer}
    </Form.Item>
  )
}

export default DependsOn
