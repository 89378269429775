export const languageList = () => ['LANGUAGE_LIST']
export const languageDetails = (languageId: number) => ['LANGUAGE_DETAILS', languageId]

export const userList = () => ['USER_LIST']
export const userRoles = () => ['USER_ROLES']
export const userDetails = (userId: number) => ['USER_DETAILS', userId]

export const workerList = () => ['WORKER_LIST']
export const workerDetails = (workerId: number) => ['WORKER_DETAILS', workerId]
export const workerListFilter = (filter: string, page: string, status: string) => [
  'WORKER_LIST',
  filter,
  page,
  status,
]

export const machinesList = () => ['MACHINES_LIST']
export const machinesListFilter = (filter: string, page: number, type: string) => [
  'MACHINES_LIST',
  filter,
  page,
  type,
]
export const newMachine = () => ['NEW_MACHINE']
export const machineDetail = (id: number) => ['MACHINE_DETAILS', id]
export const machine = (id: number) => ['MACHINE', id]

export const translationList = () => ['TRANSLATIONS_LIST']
export const translationListFilter = (filter: string, page: number) => ['TRANSLATIONS_LIST', filter, page]
export const translationDetails = (translationId: number) => ['TRANSLATION_DETAILS', translationId]
export const translationDetailsNew = (labelTag: string) => ['TRANSLATION_DETAILS_NEW', labelTag]
export const customerList = () => ['CUSTOMER_LIST']
export const customerListFilter = (filter: string, page: string) => ['CUSTOMER_LIST', filter, page, status]
export const customerDetails = (userId: number) => ['CUSTOMER_DETAILS', userId]

export const activityTypeList = () => ['ACTIVITY_TYPE_LIST']
export const activitySubTypeList = () => ['ACTIVITY_SUB_TYPE_LIST']

export const productionCardList = () => ['PRODUCTION_CARD_LIST']
export const productionCardListFilter = (
  filter: string,
  page: number,
  type: string,
  subtype: number,
  customer: string,
  machine: number,
) => ['PRODUCTION_CARD_LIST', filter, page, type, subtype, customer, machine]
export const newProductionCard = () => ['NEW_PRODUCTION_CARD']
export const productionCardDetail = (id: number) => ['PRODUCTION_CARD_DETAILS', id]
export const productionCard = (id: number) => ['PRODUCTION_CARD', id]

export const orderList = () => ['ORDER_LIST']
export const orderListFilter = (
  filter: string,
  page: number,
  customer: string,
  status: string,
  card: string,
) => ['ORDERS_LIST', filter, page, customer, status, card]
export const order = (id: number) => ['ORDER', id]

export const picklist = (entity: string) => ['PICKLIST', entity]

export const planningEventList = () => ['PLANNING_EVENT_LIST']
export const planningEventListFilter = (startDate: string) => ['PLANNING_EVENT_LIST', startDate]
export const planningEventDetail = (id: number) => ['PLANNING_EVENT_DETAILS', id]

export const shiftPlanningList = () => ['SHIFT_PLANNING_LIST']
export const shiftPlanningListFilter = (startDate: string) => ['SHIFT_PLANNING_LIST', startDate]
export const shiftPlanningDetail = (id: number) => ['SHIFT_PLANNING_DETAILS', id]

// worklog
export const worklogDetail = (id: number) => ['WORKLOG_DETAILS', id]
export const worklogsList = () => ['WORKLOGS_LIST']

export const worklogListFilter = (
  card: string,
  page: string,
  worker: string,
  machine: string,
  dateStart: string,
  dateEnd: string,
) => ['WORKLOGS_LIST', card, page, worker, dateStart, dateEnd, machine]

export const dashboardDaily = () => ['DASHBOARD', 'TODAY']
export const dashboardArticles = () => ['DASHBOARD', 'ARTICLES']
export const dashboardMachines = () => ['DASHBOARD', 'MACHINES']
export const dashboardPeriodData = () => ['DASHBOARD', 'PERIOD_DATA']

export const iotDeviceList = () => ['IOT_DEVICE_LIST']
export const iotDeviceVariableList = (id: number) => ['IOT_DEVICE_VARIABLE_LIST', id]
export const iotVariable = (deviceId: number, variableId: string) => ['IOT_VARIABLE', deviceId, variableId]

export const reportsMachines = (dateStart: string, dateEnd: string) => [
  'REPORTS_MACHINES',
  dateStart,
  dateEnd,
]

export const reportsActivityTypes = (dateStart: string, dateEnd: string) => [
  'REPORTS_ACTIVITY_TYPES',
  dateStart,
  dateEnd,
]

export const reportsArticles = (dateStart: string, dateEnd: string) => [
  'REPORTS_ARTICLES',
  dateStart,
  dateEnd,
]
