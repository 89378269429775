import * as Api from 'types/api'
import * as Store from 'types/store'
import Debug from 'debug'
import { getApplicationError } from 'core'
import { ErrorCode } from 'types/enums'

const debug = Debug('services:map:productionCards')

export const mapProductionCard = ({
  alternateArticle,
  article,
  cardNickname,
  carpetSpeed,
  createdAt,
  createdBy,
  createdById,
  customer,
  customerId,
  doorOpeningTime,
  downloadTotalTime,
  downloadType,
  id,
  intermittentPauseTime,
  intermittentWorkTime,
  images,
  machines,
  notes,
  productionTime,
  sandTime,
  subType,
  subTypeId,
  type,
  typeId,
  updatedAt,
  updatedBy,
  updatedById,
}: Api.ProductionCard): Store.ProductionCard => ({
  alternateArticle,
  article,
  cardNickname,
  carpetSpeed,
  createdAt,
  createdBy,
  createdById,
  customer,
  customerId,
  doorOpeningTime,
  downloadTotalTime,
  downloadType,
  id,
  intermittentPauseTime,
  intermittentWorkTime,
  images: images.map(({ id, path }) => ({ id, path })),
  machines: machines.map(({ id, nickname, number }) => ({ id, nickname, number })),
  notes,
  productionTime,
  sandTime,
  subType,
  subTypeId,
  type,
  typeId,
  updatedAt,
  updatedBy,
  updatedById,
})

export const mapProductionCardList = ({
  result,
  currentPage,
  totalItems,
  totalPages,
}: Api.ProductionCardListResponse): Store.ProductionCards => {
  try {
    return {
      result: result.map(mapProductionCard),
      currentPage,
      totalItems,
      totalPages,
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}
