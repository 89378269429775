import { config } from 'core'
import { AxiosError, AxiosResponse } from 'axios'
import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'
import * as Api from 'types/api'
import { Machine } from 'types/store'

const axios = getAxios()

export async function listMachines(
  filter: string,
  page: number,
  type: string,
): Promise<Api.ListMachinesResponse> {
  try {
    const response = await axios.get<Api.ListMachinesResponse>(
      config.api.listMachines(filter, page, type),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getMachine(machineId: number): Promise<Api.Machine> {
  try {
    const response = await axios.get<Api.Machine>(
      config.api.machine(machineId),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function createMachine(model: Machine): Promise<Api.NewMachineResponse> {
  try {
    const response = await axios.post(config.api.createMachine(), model, await getAuthenticationHeader())
    return response.data as Api.NewMachineResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function updateMachine(model: Machine): Promise<Api.Machine> {
  try {
    await axios.put<Api.Machine>(config.api.machine(model.id), model, await getAuthenticationHeader())
    return model
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteMachine(id: number): Promise<AxiosResponse> {
  try {
    const response = await axios.delete(config.api.machine(id), await getAuthenticationHeader())
    return response as AxiosResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
