import { Popover, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  article: string
  customer: string
  numPieces: number
}

const CellPopover: React.FC<Props> = ({ article, customer, numPieces, children }) => {
  const { t } = useTranslation()

  return (
    <Popover
      title={null}
      content={
        <>
          <Typography.Title level={3} className="mb-5">
            {article}
          </Typography.Title>
          <Typography.Paragraph className="m-0">{customer}</Typography.Paragraph>
          <Typography.Text>
            {t('general.pieces')}: {numPieces}
          </Typography.Text>
        </>
      }
    >
      {children}
    </Popover>
  )
}

export default CellPopover
