import { config } from 'core'
import { AxiosError } from 'axios'
import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'
import * as Api from 'types/api'

const axios = getAxios()

export async function createPlanningEvent(model: Api.PlanningForm): Promise<Api.PlanningEventResponse> {
  try {
    const response = await axios.post<Api.PlanningEventResponse>(
      config.api.createPlanning(),
      model,
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deletePlanningEvent(id: number): Promise<Api.PlanningEventResponse> {
  try {
    const response = await axios.delete<Api.PlanningEventResponse>(
      config.api.planningEvent(id),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function editPlanningEvent(
  id: number,
  model: Api.PlanningForm,
): Promise<Api.PlanningEventResponse> {
  try {
    const response = await axios.put<Api.PlanningEventResponse>(
      config.api.planningEvent(id),
      model,
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function listPlanningEvents(startDate: string): Promise<Api.PlanningEventResponse[]> {
  try {
    const response = await axios.get<Api.PlanningEventResponse[]>(
      config.api.listPlanningEvents(startDate),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
