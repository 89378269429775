import React from 'react'
import { useTranslation } from 'react-i18next'
import { useChangeUserPassword } from 'services/query/users'
import { useParams } from 'react-router-dom'
import ChangePassword from 'components/UserDetails/UserEdit/ChangePassword'
import { Card, notification } from 'antd'

const UserChangePasswordContainer: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { changePassword, isLoading } = useChangeUserPassword(parseInt(id))

  const onFinish = async (values: Json) => {
    try {
      await changePassword(values.newPassword)
      notification.success({ message: t('messagesCrud.userUpdated') })
    } catch (error) {
      console.error(error)
      notification.error({ message: `User save error: ${error || ''}` })
    }
  }

  return (
    <Card className="mt-24">
      <ChangePassword onFinish={onFinish} loading={isLoading} />
    </Card>
  )
}

export default UserChangePasswordContainer
