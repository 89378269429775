import { config } from 'core'
import { AxiosError, AxiosResponse } from 'axios'
import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'
import * as Api from 'types/api'

const axios = getAxios()

export async function listCustomers(filter: string, page: string): Promise<Api.ListCustomerResponse> {
  try {
    const response = await axios.get<Api.ListCustomerResponse>(
      config.api.customers(filter, page),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getCustomerDetails(id: number): Promise<Api.Customer> {
  try {
    const response = await axios.get<Api.Customer>(config.api.customer(id), await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function createCustomer(model: Api.CustomerCreateEditRequest): Promise<Api.NewCustomerResponse> {
  try {
    const response = await axios.post<Api.NewCustomerResponse>(
      config.api.createCustomer(),
      model,
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function editCustomer(id: number, model: Api.CustomerCreateEditRequest): Promise<any> {
  try {
    const response = await axios.put(config.api.customer(id), model, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteCustomer(id: number): Promise<AxiosResponse> {
  try {
    const response = await axios.delete(config.api.customer(id), await getAuthenticationHeader())
    return response.data as AxiosResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
