import React, { useState } from 'react'
import { tableSorter } from 'core/helpers'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Language, Label, Labels } from 'types/app/admin'
import { TABLE_PAGINATION_DEFAULT_PAGE_SIZE, TABLE_PAGINATION_DROPDOWN_VALUES } from 'core'

import EditableCell from './EditableCell'

import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import { Button, Form, Modal, Space, Table, TablePaginationConfig, Tooltip, Typography } from 'antd'

interface Props {
  tableRef?: any
  languages?: Language[]
  dataSource: Labels
  onChange?: (pagination, filters, sorter) => void
  setPage: (page: string) => void
  total?: number
  expandable?: any
  rowClassName?: any
  dataLoading?: boolean
  onDeleteTranslation: (translation) => void
  onSaveTranslation: (translation: Label) => void
}

const EditTableComponent: React.FC<Props> = ({
  languages,
  dataSource,
  onChange,
  expandable,
  rowClassName,
  dataLoading,
  onDeleteTranslation,
  onSaveTranslation,
  setPage,
}) => {
  const { t } = useTranslation()

  const [form] = Form.useForm()

  const [data, setData] = useState(dataSource.labels)
  const [editingId, setEditingId] = useState<number>()

  const isEditing = (record: Label) => record.id === editingId

  const edit = (record: Label) => {
    const languageValues = languages?.reduce((obj, language) => {
      obj[language.iso] = record.translations?.find(
        (translation) => translation.languageId === language.id,
      )?.translation

      return obj
    }, {})
    form.setFieldsValue({ ...record, ...languageValues })
    setEditingId(record.id)
  }

  const cancel = () => {
    setEditingId(null)
  }

  const handleDelete = (translation: Label) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      onOk() {
        onDeleteTranslation(translation)
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  React.useEffect(() => {
    setData(dataSource.labels)
  }, [dataSource])

  const save = async (id: number) => {
    try {
      const row: Json = (await form.validateFields()) as any

      const index = data.findIndex((translation) => translation.id === id)

      if (index > -1) {
        const newTranslation: Label = {
          id: id,
          labelTag: row.labelTag,
          translations: languages.map((item) => {
            return { languageId: item.id, translation: row[item.iso] }
          }),
        }

        const newData = [...data.slice(0, index), newTranslation, ...data.slice(index + 1)]

        onSaveTranslation(newTranslation)

        setData(newData)
        setEditingId(null)
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }

  const columns: any = [
    {
      key: 'key',
      title: t('general.key'),
      dataIndex: 'labelTag',
      width: '20%',
      editable: true,
      sorter: (a, b) => tableSorter(a.labelTag, b.labelTag),
    },
  ]

  languages?.map((language) => {
    columns.push({
      dataIndex: language.iso,
      key: 'translations',
      title: (
        <>
          <img
            style={{ width: '25px', marginRight: '10px' }}
            src={`/images/flags/${language.iso.toLowerCase()}.png`}
            alt={language.iso}
          />
          {language.iso}
        </>
      ),
      width: '35%',
      editable: true,
      render: (_, translation: Label) => {
        if (translation.translations) {
          return translation.translations?.find((translation) => translation.languageId === language.id)
            ?.translation
        }
      },
    })
  })
  columns.push({
    key: 'actions',
    title: t('general.actions'),
    dataIndex: 'actions',
    className: 'cell-actions',
    // eslint-disable-next-line react/display-name
    render: (_: any, record: Label) => {
      const editable = isEditing(record)
      return editable ? (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <Button type="primary" onClick={() => save(record.id)} style={{ marginRight: 8 }}>
            {t('btn.save')}
          </Button>
          <Button onClick={() => cancel()} style={{ marginRight: 8 }}>
            {t('btn.cancel')}
          </Button>
        </span>
      ) : (
        <Space>
          <Button type="link" size="small" onClick={() => edit(record)}>
            <AiOutlineEdit />
          </Button>
          <Button type="link" size="small">
            <Tooltip key={`action-delete-${record.id}`} title={t('tooltip.delete')}>
              <AiOutlineDelete onClick={() => handleDelete(record)} />
            </Tooltip>
          </Button>
        </Space>
      )
    },
  })

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  const getTablePagination = (): TablePaginationConfig | false => {
    const [searchParams] = useSearchParams()
    if (dataSource.totalItems >= TABLE_PAGINATION_DEFAULT_PAGE_SIZE) {
      return {
        current: parseInt(searchParams.get('page')) || 1,
        total: dataSource.totalItems,
        defaultPageSize: TABLE_PAGINATION_DEFAULT_PAGE_SIZE,
        showSizeChanger: false,
        pageSizeOptions: TABLE_PAGINATION_DROPDOWN_VALUES,
        onChange: (page: number) => setPage(page.toString()),
      }
    }
    return false
  }

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        rowClassName={rowClassName}
        // ref={tableRef}
        rowKey={(record) => record.id}
        scroll={{ x: 768 }}
        pagination={getTablePagination()}
        columns={mergedColumns}
        dataSource={data}
        loading={dataLoading}
        onChange={onChange}
        expandable={expandable}
      />
    </Form>
  )
}

EditTableComponent.displayName = 'EditTableComponent'

export default EditTableComponent
