import { Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import moment from 'moment'
import React from 'react'
import classNames from 'classnames'

import * as Store from 'types/store'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { useLoadWorkersPicklist } from 'services/query/picklist'
import { useListShiftPlanning } from 'services/query/shiftPlanning'
import { WorkerShifts } from 'types/enums'

import { generateDatePeriodTitle } from 'core/helpers'
import { useTheme } from 'providers/ThemeProvider'
import { themes } from './themes'
interface Props {
  onShiftPlanningClick: (shiftPlanning: Store.ShiftPlanning) => void
  onEmptyClick: (date: moment.Moment, workerId: number) => void
  startDay: moment.Moment
}

const ShiftPlanning: React.FC<Props> = ({ onEmptyClick, onShiftPlanningClick, startDay }) => {
  const { t } = useTranslation()
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const componentClassname = classNames(styles['shift-planning-table'], themeStyles['theme'])

  const dates: moment.Moment[] = []
  const { data: workers } = useLoadWorkersPicklist()
  const { data: shiftPlannings } = useListShiftPlanning(startDay.toISOString())

  const weekCounter = startDay.clone().weekday(0)

  for (let i = 0; i < 6; i++) {
    dates.push(weekCounter.clone())
    weekCounter.add(1, 'week')
  }

  const getWeekTitle = (date: moment.Moment) => {
    return (
      <div className="week-title">
        <Typography.Title level={5}>{t('shiftPlanning.week', { week: date.week() })}</Typography.Title>
        <Typography.Text>{generateDatePeriodTitle(date, date.clone().weekday(6))}</Typography.Text>
      </div>
    )
  }

  const getShiftTitle = (shift: WorkerShifts) => {
    switch (shift) {
      case WorkerShifts.ShiftOne:
        return t('planning.shiftOne')
      case WorkerShifts.ShiftTwo:
        return t('planning.shiftTwo')
    }
  }

  const renderShift = (workerId: number, date: moment.Moment) => {
    const shiftPlanning = shiftPlannings.find((shiftPlanning) => {
      return (
        shiftPlanning.week === date.week() &&
        shiftPlanning.year === date.year() &&
        shiftPlanning.workerId === workerId
      )
    })

    const canEdit = date.isSameOrAfter(moment(), 'date')

    if (shiftPlanning?.shift) {
      return (
        <div
          className={canEdit && 'planning-cell'}
          onClick={() => canEdit && onShiftPlanningClick(shiftPlanning)}
        >
          {getShiftTitle(shiftPlanning?.shift)}
        </div>
      )
    }

    return (
      <div className={canEdit && 'empty-cell'} onClick={() => canEdit && onEmptyClick(date, workerId)}></div>
    )
  }

  const columns: ColumnsType = [
    { title: t('menu.workers'), key: 'worker', dataIndex: 'fullName' },
    ...dates.map((date) => {
      return {
        title: getWeekTitle(date),
        dataIndex: [`id`],
        render: function renderer(id: number) {
          return renderShift(id, date)
        },
      }
    }),
  ]

  return (
    <Table
      columns={columns}
      dataSource={workers}
      bordered
      pagination={false}
      className={componentClassname}
    />
  )
}

export default ShiftPlanning
