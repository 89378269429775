import React from 'react'
import routes from 'config/routes'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PageHeader from 'components/PageHeader'
import * as Api from 'types/api'

import { DeleteOutlined } from '@ant-design/icons'
import * as Store from 'types/store'

import {
  Card,
  Row,
  Col,
  Button,
  Form,
  Select,
  InputNumber,
  Divider,
  Typography,
  DatePicker,
  Radio,
  Input,
  Spin,
} from 'antd'
import {
  useLoadCardsPicklist,
  useLoadMachinesPicklist,
  useLoadWorkersPicklist,
} from 'services/query/picklist'
import { filterSearchSelect } from 'core/helpers'
import moment from 'moment'
import { config } from 'core'
import { PlanningShifts } from 'types/enums'

interface Props {
  spin: boolean
  worklog: Store.Worklog
  onDelete: () => void
  onFinish: (woklog: Api.WorklogAdminForm) => void
}

const WorklogForm: React.FC<Props> = ({ spin, worklog, onFinish, onDelete }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { data: machines } = useLoadMachinesPicklist()
  const { data: cards } = useLoadCardsPicklist()
  const { data: workers } = useLoadWorkersPicklist()

  return (
    <>
      <PageHeader
        backRoute={routes.workReports}
        title={worklog ? `${worklog.worker} - ${worklog.date.format('L')}` : t('worklog.new')}
      >
        {worklog && (
          <Button type="text" onClick={onDelete}>
            <DeleteOutlined />
            {t('worklog.delete')}
          </Button>
        )}
      </PageHeader>
      <Card>
        <Spin spinning={spin}>
          <Form initialValues={worklog} onFinish={onFinish}>
            <Typography.Title level={4}>{t('general.basicInfo')}</Typography.Title>
            <Divider className="mt-0" />
            <Row gutter={[50, 0]}>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={t('general.article')}
                  name="cardId"
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <Select showSearch filterOption={filterSearchSelect}>
                    {cards.map((c) => {
                      return (
                        <Select.Option value={c.id} key={c.id}>
                          {c.article}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={t('reports.worker')}
                  name="workerId"
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <Select showSearch filterOption={filterSearchSelect}>
                    {workers?.map((w) => {
                      return (
                        <Select.Option value={w.id} key={w.id}>
                          {w.fullName}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={t('general.machine')}
                  name="machineId"
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <Select showSearch filterOption={filterSearchSelect}>
                    {machines?.map((w) => {
                      return (
                        <Select.Option value={w.id} key={w.id}>
                          {w.name}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={t('general.pieces')}
                  name="numPieces"
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <InputNumber />
                </Form.Item>

                <Form.Item
                  label={t('general.date')}
                  name="date"
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <DatePicker
                    showTime
                    showNow
                    style={{ width: '100%' }}
                    format={config.getDateTimeFormat()}
                    disabledDate={(current) => current && moment(current).isBefore(moment(), 'date')}
                  />
                </Form.Item>

                <Form.Item
                  name="shift"
                  label={t('general.shift')}
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <Radio.Group>
                    <Radio.Button value={PlanningShifts.ShiftOne}>{t('planning.shiftOne')}</Radio.Button>
                    <Radio.Button value={PlanningShifts.ShiftTwo}>{t('planning.shiftTwo')}</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label={t('general.notes')} name="notes">
                  <Input.TextArea />
                </Form.Item>
              </Col>

              <Col xs={24} lg={{ span: 12, offset: 12 }} className="mt-48">
                <Row justify="end" gutter={[12, 12]}>
                  <Col xs={12} lg={8}>
                    <Button block onClick={() => navigate(routes.workReports.url)}>
                      {t('btn.cancel')}
                    </Button>
                  </Col>
                  <Col xs={12} lg={8}>
                    <Button block type="primary" htmlType="submit" loading={spin}>
                      {t('btn.save')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Card>
    </>
  )
}

export default WorklogForm
