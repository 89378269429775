import React from 'react'
import { useTranslation } from 'react-i18next'
import PageHeader from 'components/PageHeader'
import classNames from 'classnames'
import { useTheme } from 'providers/ThemeProvider'
import { themes } from './themes'
import styles from './styles.module.scss'
import { Col, Row, Typography } from 'antd'
import PiecesToday from './widgets/PiecesToday'
import WeekProgress from './widgets/WeekProgress'
import MachinesToday from './widgets/MachinesToday'
import Articles from './widgets/Articles'

const Dashboard: React.FC = () => {
  const { t } = useTranslation()
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const componentClassname = classNames(styles['dashboard-overview'], themeStyles['theme'])

  return (
    <section className={componentClassname}>
      <Row>
        <Col>
          <Typography.Title level={2}>{`${t('menu.dashboards')}:`}</Typography.Title>
        </Col>
        <Col>
          <PageHeader title={t('menu.overview')} />
        </Col>
      </Row>

      <div className="widgets-container">
        <Row className="mb-24" gutter={[24, 24]}>
          <Col xs={24} xl={9}>
            <PiecesToday />
          </Col>

          <Col xs={24} xl={15}>
            <WeekProgress />
          </Col>
        </Row>

        <Row gutter={[24, 24]}>
          <Col xs={24} lg={15}>
            <MachinesToday />
          </Col>

          <Col xs={24} lg={9}>
            <Articles />
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default Dashboard
