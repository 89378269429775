import * as Api from 'types/api'
import * as Store from 'types/store'
import Debug from 'debug'
import { getApplicationError } from 'core'
import { ErrorCode } from 'types/enums'

const debug = Debug('services:map:planning')

export const mapShiftPlanning = ({
  id,
  shift,
  year,
  workerId,
  worker,
  week,
}: Api.ShiftPlanning): Store.ShiftPlanning => ({
  id,
  shift,
  year,
  workerId,
  worker,
  week,
})

export const mapShiftPlanningList = (json: Api.ShiftPlanning[]): Store.ShiftPlanning[] => {
  try {
    return json.map(mapShiftPlanning)
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}
