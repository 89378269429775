import React, { useState } from 'react'
import routes from 'config/routes'
import { User } from 'types/app/admin'
import { tableSorter } from 'core/helpers'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'

import PageHeader from 'components/PageHeader'
import Table from 'components/Layout/Table'

import { AiOutlineDelete, AiOutlineLock, AiOutlineEdit, AiOutlineEye, AiOutlinePlus } from 'react-icons/ai'
import { Button, Card, Modal, notification, Space, Tooltip, Typography } from 'antd'
import style from './styles.module.scss'

interface Props {
  users?: User[]
  dataLoading: boolean
  handleDelete: (id: number) => void
}

const UsersTable: React.FC<Props> = ({ users, dataLoading, handleDelete }) => {
  const { t } = useTranslation()
  // const crumbs = () => [{ route: routes.home }, { route: routes.users }]
  const actions = ['details', 'edit', 'delete']

  const [data, setData] = useState(users)

  React.useEffect(() => {
    setData(users)
  }, [users])

  const onDelete = (userId: number) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      onOk() {
        try {
          handleDelete(userId)
          notification.success({ message: t('messagesCrud.userDeleted') })
        } catch (error) {
          notification.error({ message: t('messagesCrud.deleteError') })
        }
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  const columns = [
    {
      key: 'id',
      title: t('general.id'),
      dataIndex: 'id',
      width: '10%',
      fixed: true,
    },
    {
      key: 'name',
      title: t('general.name'),
      dataIndex: 'name',
      sorter: (a, b) => tableSorter(a.name, b.name),
    },
    {
      key: 'surname',
      title: t('general.surname'),
      dataIndex: 'surname',
      sorter: (a, b) => tableSorter(a.surname, b.surname),
    },
    {
      key: 'email',
      title: t('general.email'),
      dataIndex: 'email',
      sorter: (a, b) => tableSorter(a.email, b.email),
    },
    {
      key: 'role',
      title: t('general.role'),
      dataIndex: 'roleId',
      sorter: (a, b) => tableSorter(a.roleId, b.roleId),
    },
    {
      key: 'actions',
      title: t('general.actions'),
      dataIndex: 'id',
      className: 'cell-actions',
      width: 150,
      // eslint-disable-next-line react/display-name
      render: (id) => (
        <Space size={0}>
          {actions.map((action) => {
            switch (action) {
              case 'edit':
                return (
                  <Tooltip key={`action-edit-${id}`} title={t('tooltip.edit')}>
                    <Link to={generatePath(routes.userDetails.url, { id: id }) + '?editmode=details'}>
                      <Button type="link" size="small">
                        <AiOutlineEdit />
                      </Button>
                    </Link>
                  </Tooltip>
                )
              case 'delete':
                return (
                  <Tooltip key={`action-delete-${id}`} title={t('tooltip.delete')}>
                    <Button type="link" size="small" onClick={() => onDelete(id)}>
                      <AiOutlineDelete />
                    </Button>
                  </Tooltip>
                )
              case 'password':
                return (
                  <Tooltip key={`action-password-${id}`} title={t('tooltip.pass')}>
                    <Link
                      to={''}
                      //to={generatePath(routes.userDetails.url, { id: id }) + '?editmode=changePass'}
                    >
                      <Button type="link" size="small">
                        <AiOutlineLock />
                      </Button>
                    </Link>
                  </Tooltip>
                )
              case 'details':
                return (
                  <Tooltip key={`action-details-${id}`} title={t('tooltip.details')}>
                    <Link to={generatePath(routes.userDetails.url, { id: id })}>
                      <Button type="link" size="small">
                        <AiOutlineEye />
                      </Button>
                    </Link>
                  </Tooltip>
                )
              default:
                return
            }
          })}
        </Space>
      ),
    },
  ]

  return (
    <section className={style['users-table']}>
      <PageHeader title={`${t('menu.users')} `}>
        <Button type="primary" className="my-10">
          <Link
            key="action-create"
            to={generatePath(routes.userDetails.url, { id: 'new' }) + '?editmode=details'}
          >
            <AiOutlinePlus />
            {t('btn.new')}
          </Link>
        </Button>
      </PageHeader>
      <Card bordered>
        <Table columns={columns} dataSource={data} isLoading={dataLoading} setPage={() => null} />
      </Card>
    </section>
  )
}

export default UsersTable
