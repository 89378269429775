import { Button, Card, Col, DatePicker, Row, Select } from 'antd'
import routes from 'config/routes'
import { filterSearchSelect, generateQueryParams } from 'core/helpers'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlinePlus } from 'react-icons/ai'
import { generatePath, Link, useSearchParams } from 'react-router-dom'
import {
  useLoadCardsPicklist,
  useLoadMachinesPicklist,
  useLoadWorkersPicklist,
} from 'services/query/picklist'
import debounce from 'debounce'

import { useLoadWorklogList } from 'services/query/worklog'
import PageHeader from 'components/PageHeader'
import WorklogsTable from './Table'
import moment from 'moment'
const { RangePicker } = DatePicker

interface Filters {
  page: string
  card: string
  worker: string
  dateStart: string
  dateEnd: string
  machine: string
}

type FilterKeys = keyof Filters

const WorklogAdmin: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [filters, setFilters] = useState<Filters>({
    page: searchParams.get('page') || '1',
    card: searchParams.get('card') || '',
    worker: searchParams.get('worker') || '',
    dateStart: searchParams.get('dateStart') || '',
    dateEnd: searchParams.get('dateEnd') || '',
    machine: searchParams.get('machine') || '',
  })

  const { data: machines } = useLoadMachinesPicklist()
  const { data: cards } = useLoadCardsPicklist()
  const { data: workers } = useLoadWorkersPicklist()

  const { card, dateEnd, dateStart, machine, page, worker } = filters

  const { data: worklogs, isLoading } = useLoadWorklogList(card, page, worker, machine, dateStart, dateEnd)

  const { t } = useTranslation()

  useEffect(() => {
    const query = generateQueryParams({ card, dateEnd, dateStart, machine, page, worker })
    setSearchParams(query)
  }, [card, dateEnd, dateStart, machine, page, worker])

  const onSetFilter = (key: FilterKeys, value: string) => {
    debounce(
      setFilters((filters) => ({ ...filters, [key]: value })),
      300,
    )
  }

  return (
    <>
      <PageHeader title={`${t('menu.workReport')} `}>
        <Button type="primary" className="my-10">
          <Link key="action-create" to={generatePath(routes.workReport.url, { id: 'new' })}>
            <AiOutlinePlus /> {t('btn.new')}
          </Link>
        </Button>
      </PageHeader>
      <Card bordered>
        <Row gutter={[24, 8]}>
          <Col xs={24} lg={6}>
            <Select
              allowClear
              placeholder={t('filter.productionCard')}
              value={card ? card : null}
              onClear={() => onSetFilter('card', '')}
              onSelect={(selected) => {
                onSetFilter('card', selected.toString())
              }}
              showSearch
              filterOption={filterSearchSelect}
            >
              {cards.map((c) => {
                return (
                  <Select.Option value={c.id.toString()} key={c.article}>
                    {c.article}
                  </Select.Option>
                )
              })}
            </Select>
          </Col>
          <Col xs={24} lg={6}>
            <Select
              allowClear
              value={worker ? worker : null}
              placeholder={t('filter.selectWorker')}
              onClear={() => onSetFilter('worker', '')}
              onSelect={(worker) => onSetFilter('worker', worker.toString())}
              showSearch
              filterOption={filterSearchSelect}
            >
              {workers?.map((w) => {
                return (
                  <Select.Option value={w.id.toString()} key={w.id}>
                    {w.fullName}
                  </Select.Option>
                )
              })}
            </Select>
          </Col>
          <Col xs={24} lg={6}>
            <Select
              allowClear
              value={machine ? machine : null}
              placeholder={t('filter.selectMachine')}
              onClear={() => onSetFilter('machine', '')}
              onSelect={(machine) => onSetFilter('machine', machine.toString())}
              showSearch
              filterOption={filterSearchSelect}
            >
              {machines?.map((m) => {
                return (
                  <Select.Option value={m.id.toString()} key={m.id}>
                    {m.name}
                  </Select.Option>
                )
              })}
            </Select>
          </Col>
          <Col xs={24} lg={6}>
            <RangePicker
              placeholder={[t('general.startDate'), t('general.endDate')]}
              value={[dateStart ? moment(dateStart) : null, dateEnd ? moment(dateEnd) : null]}
              onChange={(dates) => {
                if (dates) {
                  const [dateStart, dateEnd] = dates
                  onSetFilter('dateStart', dateStart.toISOString())
                  onSetFilter('dateEnd', dateEnd.toISOString())
                } else {
                  onSetFilter('dateStart', '')
                  onSetFilter('dateEnd', '')
                }
              }}
            />
          </Col>
        </Row>
      </Card>
      <WorklogsTable
        data={worklogs}
        isLoading={isLoading}
        setPage={(page: string) => onSetFilter('page', page)}
      />
    </>
  )
}

export default WorklogAdmin
