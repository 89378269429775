import React from 'react'
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { ActivityTypesReportsData } from 'types/store'
import { useLoadActivityTypesReportsData } from 'services/query/reports'
import { tableSorter } from 'core/helpers'
import ActivityTypeBarchart from './ActivityTypeBarchart'
import styles from '../styles.module.scss'

interface Props {
  dateStart: string
  dateEnd: string
}

const TableByActivityTypes: React.FC<Props> = ({ dateStart, dateEnd }) => {
  const { t } = useTranslation()

  const column: any = [
    {
      key: 'activityTypeId',
      title: t('machines.activityType'),
      dataIndex: 'activityType',
    },
    {
      key: 'total',
      title: t('general.total'),
      dataIndex: 'total',
      sorter: (a, b) => tableSorter(a.total, b.total),
      align: 'center',
      width: 130,
    },
  ]

  const { data: activityTypes, isFetching } = useLoadActivityTypesReportsData(dateStart, dateEnd)

  const expandedRowRender = (record: ActivityTypesReportsData) => <ActivityTypeBarchart data={record} />

  if (isFetching) {
    return null
  }

  return (
    <Table
      dataSource={activityTypes}
      columns={column}
      rowKey="activityTypeId"
      pagination={false}
      className={styles['report-machines']}
      expandable={{
        expandedRowRender,
      }}
    />
  )
}

export default TableByActivityTypes
