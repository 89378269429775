import React from 'react'
import { useTranslation } from 'react-i18next'

import { Card, Divider, Typography } from 'antd'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts'
import colors from 'config/colors'
import { useLoadMachinesDashboardData } from 'services/query/dashboard'

const MachinesToday: React.FC = () => {
  const { t } = useTranslation()
  const { data } = useLoadMachinesDashboardData()

  return (
    <Card className="widget-item widget-item--machines">
      <Typography.Title level={4}>{t('dashboard.machinesToday')}</Typography.Title>
      <Divider className="mt-0" />
      <div className="legend">
        <Typography.Title className="legend-item legend-item--planned" level={5}>
          {t('dashboard.piecesPlanned')}
        </Typography.Title>
        <Typography.Title className="legend-item legend-item--completed" level={5}>
          {t('dashboard.piecesCompleted')}
        </Typography.Title>
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          layout="vertical"
          width={500}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis type="number" />
          <YAxis
            dataKey="name"
            type="category"
            label={{ position: 'insideBottom', offset: -15 }}
            width={90}
          />
          <Tooltip />
          <Bar
            name={t('dashboard.piecesPlanned')}
            dataKey="planned"
            barSize={10}
            fill={colors.piecesPlannedMachines}
          />
          <Bar
            name={t('dashboard.piecesCompleted')}
            dataKey="completed"
            barSize={10}
            fill={colors.piecesCompletedMachines}
          />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  )
}

export default MachinesToday
