import * as Api from 'types/api'
import * as Store from 'types/store'
import Debug from 'debug'
import { getApplicationError } from 'core'
import { ErrorCode } from 'types/enums'

const debug = Debug('services:map:productionCards')

export const mapOrder = ({
  id,
  status,
  customerId,
  customer,
  numPieces,
  orderNumber,
  cards,
  createdById,
  createdAt,
  createdBy,
  updatedById,
  updatedAt,
  updatedBy,
  dueDate,
}: Api.Order): Store.Order => ({
  id,
  status,
  customerId,
  customer,
  numPieces,
  cards: cards.map(({ id, article }) => ({ id, article })),
  createdById,
  createdAt,
  createdBy,
  orderNumber,
  updatedById,
  updatedAt,
  updatedBy,
  dueDate,
})

export const mapOrderList = ({
  result,
  currentPage,
  totalItems,
  totalPages,
}: Api.OrderListResponse): Store.Orders => {
  try {
    return {
      result: result.map(mapOrder),
      currentPage,
      totalItems,
      totalPages,
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}
