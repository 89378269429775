import * as Api from 'types/api'
import * as Store from 'types/store'
import { config } from 'core'
import moment from 'moment'

export const mapDashboardDaily = ({
  date,
  piecesCompleted,
  piecesPlanned,
  piecesToBeDone,
}: Api.DailyDashboardData): Store.DailyDashboardData => ({
  date: moment(date).format(config.getDateFormat()),
  piecesCompleted,
  piecesPlanned,
  piecesToBeDone,
})

export const mapDashboardMachine = ({
  name,
  completed,
  planned,
}: Api.MachineDashboardData): Store.MachineDashboardData => ({
  name,
  completed,
  planned,
})

export const mapDashboardArticle = ({
  nickname,
  completed,
  planned,
}: Api.ArticleDashboardData): Store.ArticleDashboardData => ({
  nickname,
  completed,
  planned,
})
