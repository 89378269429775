import React from 'react'
import { Table, TablePaginationConfig, TableColumnsType } from 'antd'

import { useSearchParams } from 'react-router-dom'

import { TABLE_PAGINATION_DEFAULT_PAGE_SIZE, TABLE_PAGINATION_DROPDOWN_VALUES } from 'core'

interface Props {
  tableRef?: any
  columns: TableColumnsType<any>
  dataSource: any
  setPage: (page: string) => void
  isLoading?: boolean
  onChange?: (pagination, filters, sorter) => void
  total?: number
  expandable?: any
  rowClassName?: any
}

const TableComponent: React.FC<Props> = ({
  columns,
  dataSource,
  isLoading,
  total,
  onChange,
  expandable,
  rowClassName,
  setPage,
}) => {
  const [searchParams] = useSearchParams()
  const dataLength = total || dataSource?.length
  const getTablePagination = (): TablePaginationConfig | false => {
    if (dataLength >= TABLE_PAGINATION_DEFAULT_PAGE_SIZE) {
      return {
        current: parseInt(searchParams.get('page')) || 1,
        total: dataLength,
        defaultPageSize: TABLE_PAGINATION_DEFAULT_PAGE_SIZE,
        showSizeChanger: dataSource.length > 30,
        pageSizeOptions: TABLE_PAGINATION_DROPDOWN_VALUES,
        onChange: (page: number) => setPage(page.toString()),
      }
    }
    return false
  }

  return (
    <>
      <Table
        rowClassName={rowClassName}
        // ref={tableRef}
        rowKey={(record) => record.id}
        scroll={{ x: 768 }}
        pagination={getTablePagination()}
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        onChange={onChange}
        expandable={expandable}
      />
    </>
  )
}

TableComponent.displayName = 'TableComponent'

export default TableComponent
