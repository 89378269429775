import { getApplicationError } from 'core'
import Debug from 'debug'
import * as Api from 'types/api/admin'
import {
  Label,
  Labels,
  Language,
  Translation,
  User,
  ActivityType,
  ActivitySubType,
  Worker,
} from 'types/app/admin'
import { ErrorCode } from 'types/enums'
import { Workers } from 'types/store'
import { mapBoolean, mapNumber, mapString } from './common'
const debug = Debug('services:map:admin')

export const mapUser = (data: Api.UserListData): User => {
  try {
    return {
      id: mapNumber(data.id),
      name: mapString(data.name),
      surname: mapString(data.surname),
      email: mapString(data.email),
      roleId: mapNumber(data.roleId),
      role: mapString(data.role),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapUserList = (data: Api.UserListData[]): User[] => {
  try {
    return data.map((item) => mapUser(item))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapCreateEditFromUser = (entity: User): Api.UserCreateEditRequest => {
  try {
    const data: Api.UserCreateEditRequest = {
      name: entity.name,
      surname: entity.surname,
      email: entity.email,
      roleId: entity.roleId,
    }
    return data
  } catch (error) {
    debug(error)
    throw Error('Mapping error')
  }
}

export const mapWorker = (data: Api.Worker): Worker => {
  try {
    return {
      id: mapNumber(data.id),
      name: mapString(data.name),
      surname: mapString(data.surname),
      disabled: mapBoolean(data.disabled),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapWorkerList = ({
  result,
  currentPage,
  totalItems,
  totalPages,
}: Api.ListWorkerResponse): Workers => {
  try {
    return {
      result: result.map((item) => mapWorker(item)),
      currentPage,
      totalItems,
      totalPages,
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapCreateEditFromWorker = (entity: Worker): Api.WorkerCreateEditRequest => {
  try {
    const data: Api.WorkerCreateEditRequest = {
      name: entity.name,
      surname: entity.surname,
      disabled: entity.disabled,
    }
    return data
  } catch (error) {
    debug(error)
    throw Error('Mapping error')
  }
}

export const mapLanguage = (data: Api.LanguageListData): Language => {
  try {
    return {
      id: mapNumber(data.id),
      name: mapString(data.name),
      iso: mapString(data.iso),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapLanguageList = (data: Api.LanguageListData[]): Language[] => {
  try {
    return data.map((item) => mapLanguage(item))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

// translations

export const mapTranslations = (data: Api.TranslationsData[]): Translation[] => {
  try {
    return data.map((data) => ({
      translation: mapString(data.translation),
      translationAbbr: mapString(data.translationAbbr),
      languageId: mapNumber(data.languageId),
      language: mapString(data.language),
      languageIso: mapString(data.languageIso),
      id: mapNumber(data.id),
    }))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapTranslation = (data: Api.TranslationListData): Label => {
  try {
    return {
      id: mapNumber(data.id),
      labelTag: mapString(data.labelTag),
      translations: data.translations && mapTranslations(data.translations),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapTranslationList = ({
  result,
  currentPage,
  totalItems,
  totalPages,
}: Api.TranslationResponse): Labels => {
  try {
    return {
      labels: result.map((item) => mapTranslation(item)),
      currentPage,
      totalItems,
      totalPages,
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapActivityType = (data: Api.ActivityTypeData): ActivityType => {
  try {
    return {
      id: mapNumber(data.id),
      type: mapString(data.type),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapActivitySubType = (data: Api.ActivitySubTypeData): ActivitySubType => {
  try {
    return {
      subTypes: data.subTypes.map(({ id, subType }) => {
        return { id, subType }
      }),
      id: mapNumber(data.id),
      type: mapString(data.type),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapActivityTypeList = (data: Api.ActivityTypeData[]): ActivityType[] => {
  try {
    return data.map((item) => mapActivityType(item))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapActivitySubTypeList = (data: Api.ActivitySubTypeData[]): ActivitySubType[] => {
  try {
    return data.map((item) => mapActivitySubType(item))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}
