import React from 'react'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts'
import colors from 'config/colors'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { ArticlesReportsData } from 'types/store'

interface Props {
  data: ArticlesReportsData
}

const ArticleBarchart: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="legend">
        <Typography.Title className="legend-item legend-item--planned" level={5}>
          {t('dashboard.piecesPlanned')}
        </Typography.Title>
        <Typography.Title className="legend-item legend-item--completed" level={5}>
          {t('dashboard.piecesCompleted')}
        </Typography.Title>
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          width={500}
          height={400}
          data={data.data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="date" label={{ position: 'insideBottom', offset: -15 }} />
          <YAxis label={{ angle: -90, position: 'insideLeft' }} />
          <Tooltip />
          <Bar name={t('dashboard.piecesPlanned')} dataKey="piecesPlanned" fill={colors.piecesPlannedToday} />
          <Bar
            name={t('dashboard.piecesCompleted')}
            dataKey="piecesCompleted"
            fill={colors.piecesCompletedToday}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  )
}

export default ArticleBarchart
