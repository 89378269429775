import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import * as Store from 'types/store'
import routes from 'config/routes'
import { Modal, notification, Typography } from 'antd'
import Loading from 'components/Loading'
import { useDeleteCustomer, useEditCustomer, useLoadCustomer } from 'services/query/customers'
import { useRouter } from 'core/useRouter'
import CustomerDetails from 'components/customers/CustomerDetails'
import CustomerCreateEdit from 'components/customers/CustomerCreateEdit'

const CustomerDetailsContainer: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const { editCustomer } = useEditCustomer(id)

  const router = useRouter()
  const [editMode] = useState(router.query.editmode ? true : false)
  const { data: customer } = useLoadCustomer(parseInt(id))

  const [spin, setSpin] = useState(false)
  const onUpdate = async (model: Store.Customer) => {
    model.id = parseInt(id)
    try {
      setSpin(true)
      await editCustomer(model)
      navigate(generatePath(routes.orders.url), { replace: true })
      notification.success({ message: t('messagesCrud.orderUpdated') })
    } catch (error) {
      console.error(error)
      notification.error({ message: t('messagesCrud.error') })
    } finally {
      setSpin(false)
    }
  }

  const deleteCustomer = useDeleteCustomer()
  const onDelete = (customer: Store.Customer) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      async onOk() {
        try {
          await deleteCustomer(customer.id)
          navigate(generatePath(routes.customers.url), { replace: true })
          notification.success({ message: t('messagesCrud.customerDeleted') })
        } catch (error) {
          notification.error({ message: t('messagesCrud.deleteError') })
        }
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  if (customer === null) {
    return <Loading />
  }

  if (editMode) {
    return <CustomerCreateEdit spin={spin} customer={customer} onUpdate={onUpdate} onDelete={onDelete} />
  }
  return <CustomerDetails customer={customer} onDelete={onDelete} />
}

export default CustomerDetailsContainer
