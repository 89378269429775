import React from 'react'
import routes from 'config/routes'
import { useTranslation } from 'react-i18next'
import { Machine } from 'types/store'
import { MachineActivityType } from 'types/enums'
import * as Store from 'types/store'

import { Card, Descriptions, Button } from 'antd'
import PageHeader from 'components/PageHeader'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { generatePath } from 'react-router-dom'

interface Props {
  machine: Machine
  onDelete?: (machine: Store.Machine) => void
}

const MachineDetails: React.FC<Props> = ({ machine, onDelete }) => {
  const { t } = useTranslation()
  // const crumbs = () => [{ route: routes.home }, { route: routes.machines }]

  return (
    <>
      <PageHeader
        classname={`with-icon with-icon--${machine.type.toLowerCase()}`}
        backRoute={routes.machines}
        title={machine.nickname}
        subtitle={`ID: ${machine.number}`}
      >
        <Button href={generatePath(routes.machine.url, { id: machine.id.toString() }) + '?editmode=true'}>
          <EditOutlined />
          {t('btn.edit')}
        </Button>
        <Button type="text" onClick={() => onDelete(machine)}>
          <DeleteOutlined />
          {t('machine.delete')}
        </Button>
      </PageHeader>
      {/* <TopPage title={t('menu.machines')} crumbs={crumbs()}></TopPage> */}
      <Card>
        <Descriptions column={1} colon={false} title={t('general.basicInfo')} className="descriptions-half">
          {machine.typeId !== MachineActivityType.Grinding && (
            <>
              <Descriptions.Item label={t('machines.ip')}>{machine.ip}</Descriptions.Item>
              <Descriptions.Item label={t('machines.model')}>{machine.model}</Descriptions.Item>
              <Descriptions.Item label={t('machines.year')}>{machine.year}</Descriptions.Item>
            </>
          )}
        </Descriptions>
      </Card>
    </>
  )
}

export default MachineDetails
