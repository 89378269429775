import React, { useEffect, useState } from 'react'
import ResetPasswordForm from 'components/ResetPasswordForm'
import { notification } from 'antd'
import { t } from 'i18next'
import { useResetPassword } from 'services/query/auth'

const ResetPasswordFormContainer: React.FC = () => {
  const [isDone, setIsDone] = useState(false)
  const { resetPassword, isLoading, error } = useResetPassword()

  useEffect(() => {
    if (error) {
      notification.error({ message: t('auth.errorResetPassword') })
    }
  }, [error])

  const onConfirm = async (email: string, newPassword: string, code: string) => {
    const isConfirmed = await resetPassword({ code, email, newPassword })
    if (isConfirmed) {
      setIsDone(true)
    } else {
      notification.error({ message: t('auth.errorResetPassword') })
    }
  }

  return <ResetPasswordForm onConfirm={onConfirm} isDone={isDone} isLoading={isLoading} />
}

export default ResetPasswordFormContainer
